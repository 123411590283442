<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template><div class="k-my-content-view-list-outer" @click="last_viewed_activity_or_lesson_id=''">
	<v-expand-transition><div v-show="show_older_items">
		<div v-for="(item, index) in items" :key="item.id" v-if="item.is_old" :class="outer_content_item_class(item.id)">
			<div class="k-directive-view2-outer-scrim"></div>
			<v-hover v-slot:default="{hover}"><div class="k-directive-item-outer" :class="outer_content_item_class2(item.id,hover)">
				<v-checkbox v-if="checkboxes_showing" class="shrink mt-0 d-inline-block float-left" hide-details v-model="checked[item.id]" style="padding-top:5px"></v-checkbox>
				<DirectivesListItem :ref="'item'+item.id" :filtered_origin_teacher_id="filtered_origin_teacher_id" :course_code="course_code" :lp_unit_id="lp_unit_id" :item="item" :hover="hover" @duplicate_item="$emit('duplicate_item', $event)" @edit_item_saved="$emit('edit_item_saved', $event)" @archive_items="$emit('archive_items', $event)" />
			</div></v-hover>
		</div>
	</div></v-expand-transition>

	<div v-for="(item, index) in items" :key="item.id" v-if="!item.is_old&&item.has_date" :class="outer_content_item_class(item.id)">
		<div class="k-directive-view2-outer-scrim"></div>
		<v-hover v-slot:default="{hover}"><div class="k-directive-item-outer" :class="outer_content_item_class2(item.id,hover)">
			<v-checkbox v-if="checkboxes_showing" class="shrink mt-0 d-inline-block float-left" hide-details v-model="checked[item.id]" style="padding-top:5px"></v-checkbox>
			<DirectivesListItem :ref="'item'+item.id" :filtered_origin_teacher_id="filtered_origin_teacher_id" :course_code="course_code" :lp_unit_id="lp_unit_id" :item="item" :hover="hover" @duplicate_item="$emit('duplicate_item', $event)" @edit_item_saved="$emit('edit_item_saved', $event)" @archive_items="$emit('archive_items', $event)" />
		</div></v-hover>
	</div>
	<div v-if="n_current_items==0&&n_no_date_items==0&&!show_older_items" class="text-center my-3"><i>No items currently due. Click SHOW OLDER ITEMS above to see previously-due items.</i></div>

	<div v-if="!single_item&&n_no_date_items>0" style="font-size:14px; font-weight:bold; color:#666; border-top:1px solid #666;" class="pt-3 mt-5 mb-3 pl-1"><v-icon small class="mr-2">fas fa-angle-down</v-icon> Undated Lessons & Activities <v-icon small class="ml-2">fas fa-angle-down</v-icon></div>
	<div v-for="(item, index) in items" :key="item.id" v-if="!item.has_date" :class="outer_content_item_class(item.id)">
		<div class="k-directive-view2-outer-scrim"></div>
		<v-hover v-slot:default="{hover}"><div class="k-directive-item-outer" :class="outer_content_item_class2(item.id,hover)">
			<v-checkbox v-if="checkboxes_showing" class="shrink mt-0 d-inline-block float-left" hide-details v-model="checked[item.id]" style="padding-top:5px"></v-checkbox>
			<DirectivesListItem :ref="'item'+item.id" :filtered_origin_teacher_id="filtered_origin_teacher_id" :course_code="course_code" :lp_unit_id="lp_unit_id" :item="item" :hover="hover" @duplicate_item="$emit('duplicate_item', $event)" @edit_item_saved="$emit('edit_item_saved', $event)" @archive_items="$emit('archive_items', $event)" />
		</div></v-hover>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import DirectivesListItem from './DirectivesListItem'

export default {
	components: { DirectivesListItem },
	props: {
		items: { type: Array, required: true },
		max_context: { type: Number, required: true },
		items_rendered: { type: Boolean, required: true },
		course_code: { type: String, required: false, default() { return '' } },
		lp_unit_id: { type: Number, required: false, default() { return 0 } },
		n_no_date_items: { type: Number, required: false, default() { return 0 } },
		n_current_items: { type: Number, required: false, default() { return 0 } },
		filtered_origin_teacher_id: { required: false, default() { return null } },
		checkboxes_showing: { required: false, default() { return false } },
	},
	data() { return {
		checked: {},
	}},
	computed: {
		...mapState(['single_item', 'my_lessons', 'user_info',]),
		...mapGetters([]),
		show_older_items: {
			get() { 
				// always show the item if it's a single item
				if (this.single_item) return true
				return this.$store.state.lst.show_older_items 
			},
			set(val) { this.$store.commit('lst_set', ['show_older_items', val]) }
		},
		last_viewed_activity_or_lesson_id: {
			get() { return this.$store.state.last_viewed_activity_or_lesson_id },
			set(val) { this.$store.commit('set', ['last_viewed_activity_or_lesson_id', val]) }
		},
	},
	watch: {
		items: { immediate: true, handler() {
			let checked = {}
			for (let item of this.items) {
				checked[item.id] = this.checked[item.id] ? true : false
			}
			this.checked = checked
		}},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		outer_content_item_class(item_id) {
			let s = ''
			if (!this.items_rendered) return s

			if (this.$refs['item'+item_id] && this.$refs['item'+item_id][0] && this.$refs['item'+item_id][0].lesson_expanded) s += ' k-directive-view2-outer-expanded'
			return s
		},

		outer_content_item_class2(item_id, hover) {			
			let s = ''
			if (!this.items_rendered) return s

			if (this.$refs['item'+item_id] && this.$refs['item'+item_id][0] && this.$refs['item'+item_id][0].item_being_edited) s += ' k-directive-item-outer-edited elevation-5'
			else if (this.items.find(x=>x.id==item_id)?.o.item_showing) s += ' k-directive-item-outer-showing elevation-5'
			else s += ' elevation-0'

			if (this.single_item || hover) s += ' k-directive-item-outer-hovered'

			// highlight the last-clicked item
			if (!this.single_item && this.$store.state.last_viewed_activity_or_lesson_id == item_id) {
				s += ' k-directive-item-outer-last-viewed'
				if (hover) s += '-hovered'
			}

			return s
		},

		select_all() {
			for (let item of this.items) {
				this.checked[item.id] = true
			}
		},

		select_none() {
			for (let item of this.items) {
				this.checked[item.id] = false
			}
		},

		archive_selected() {
			// count selected items
			let total_selected = 0
			for (let item of this.items) if (this.checked[item.id]) ++total_selected
			if (total_selected == 0) {
				this.$inform('No items selected.')
				return
			}

			// confirm
			this.$confirm({
				title: 'Are you sure?',
				text: `Are you sure you want to archive the ${total_selected} selected ${U.ps('item', total_selected)}?`,
				acceptText: 'Archive',
				acceptColor: 'red',
				dialogMaxWidth: 600,
			}).then(y => {
				// send up the chain to DirectivesWrapper, which will handle the mechanics
				let arr = []
				for (let item of this.items) {
					if (!this.checked[item.id]) continue
					arr.push(item.o)
				}
				this.$emit('archive_items', arr)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		copy_lesson_ids_for_selected() {
			// get lesson_ids for all selected lessons
			let arr = []
			for (let item of this.items) if (this.checked[item.id]) {
				if (item.type == 'lesson') arr.push(item.id)
			}

			if (arr.length == 0) {
				this.$alert('No lessons selected.')
				return
			}

			let msg
			if (arr.length == 1) msg = `The share code for the selected lesson is given below. You can copy this code to import the lesson into a Weekly Lesson Report.`
			else msg = `The share codes for the selected lessons are given below. You can copy these codes to bulk-import lessons into a Weekly Lesson Report.`
			msg += `<div class="mt-3 text-center" style="font-size:22px"><b>${arr.join(', ')}</b></div>`

			// confirm
			this.$confirm({
				title: 'Copy Share Codes',
				text: msg,
				cancelText: 'Copy Share Code' + ((arr.length>1)?'s':''),
				cancelIcon: 'fas fa-copy',
				acceptIcon: 'fas fa-check',
				dialogMaxWidth: 600,
			}).catch(e=>{
				U.copy_to_clipboard(arr.join(', '))
				this.$inform('Copied to clipboard')
			})
		},

		unassign_selected(assignee) {
			// only do this if we're showing a section or "All Sections" in the "Show activities for" menu
			if (!assignee.empty() && !empty(assignee.user_sourcedId)) {
				this.$inform('You cannot batch-unassign activities for individual students.')
				return
			}

			// count selected items; see if any lineitems need to be removed
			let total_selected = 0
			let deleted_lineitem_count = 0
			for (let item of this.items) {
				// this only applies to activities, not lessons
				if (item.type != 'activity' || !this.checked[item.id]) continue
				++total_selected

				// if the activity includes at least one lineItemSourcedId, we should need to "forget" about at least one lineitem; see also DirectiveActivityEditor
				if (Object.keys(item.o.lineItemSourcedId).length > 0) ++deleted_lineitem_count
			}

			if (total_selected == 0) {
				this.$inform('No activities selected.')
				return
			}
			
			// confirm
			let text = `This action will unassign the ${total_selected} selected ${U.ps('activity', total_selected, 'activities')} `
			if (assignee.empty()) text += 'for all sections/students.'
			else text += ` for section <nobr>${assignee.toString(null,true)}.</nobr>`

			if (deleted_lineitem_count > 0) {
				text += `<br><br>You are breaking the link between at least one class section and the unassigned ${U.ps('activity', total_selected, 'activities')}. <b>How would you like to handle SIS gradebook entries for the previously assigned section(s)?</b><div class="mt-2"><b>Note:</b> even if you preserve current gradebook scores, HenryConnects will “forget” about the gradebook connections, so that any subsequent student work in HenryConnects will not trigger automatic grade updates in the SIS.</div>`
				this.$prompt({
					title: `Unlink Sections from the Gradebook`,
					text: text,
					promptType: 'radio',
					radioOptions: [
						{value: 'preserve_line_items', label: `<b class="green--text text--darken-3">Preserve</b> SIS grades (but stop sending grade updates to the SIS for the unassigned section(s)`},
						{value: 'delete_line_items', label: `<b class="red--text text--darken-3">Delete</b> any SIS grades for the unassigned section(s)`},
					],
					initialValue: 'preserve_line_items',
					dialogMaxWidth: 800,
					disableForEmptyValue: true,
					acceptText: ' OK ',
				}).then(lineitem_directive => {
					this.unassign_selected_finish(assignee, total_selected, lineitem_directive)
				}).catch(n=>{console.log(n)}).finally(f=>{})

			} else {
				text += ' Are you sure you want to proceed?'
				this.$confirm({
					title: 'Are you sure?',
					text: text,
					acceptText: 'Unassign',
					acceptColor: 'red',
					dialogMaxWidth: 600,
				}).then(y => {
					this.unassign_selected_finish(assignee, total_selected)
				}).catch(n=>{console.log(n)}).finally(f=>{})
			}
		},

		unassign_selected_finish(assignee, total_selected, lineitem_directive) {
			U.loading_start()
			for (let item of this.items) {
				let payload = {
					assignees: [],
				}
				// this only applies to activities, not lessons
				if (item.type != 'activity' || !this.checked[item.id]) continue

				let edited_activity = new Activity(item.o)

				// if we're removing assignments for all sections...
				if (assignee.empty()) {
					// delete all assignments from the activity record
					edited_activity.assignments = []

					// add all assignees to be deleted
					for (let oa of edited_activity.assigned_to) {
						payload.assignees.push({
							activity_student_mapping_id: oa.activity_student_mapping_id,
							delete: 'yes',
						})
					}

					// and if we were told to delete lineitems, add all lineitems to lineitems_to_delete
					if (lineitem_directive == 'delete_line_items') {
						// note that Activity.lineItemSourcedId has the same format as lineitems_to_delete -- class_sourcedId:lineitem_id
						payload.lineitems_to_delete = edited_activity.lineItemSourcedId
					}
				
				// else we're removing assignments for a single section...
				} else {
					// delete the assignee from assigned_to, and add the assignee to be deleted
					let index = edited_activity.assigned_to.findIndex(x=>x.matches(assignee))
					if (index > -1)	{ // this should always be true
						payload.assignees.push({
							activity_student_mapping_id: edited_activity.assigned_to[index].activity_student_mapping_id,
							delete: 'yes',
						})
						edited_activity.assigned_to.splice(index, 1)
					}

					// go through all assignments
					for (let i = edited_activity.assignments.length-1; i >= 0; --i) {
						// if this assignment has no remaining assigned_to's, remove the assignment altogether
						if (!edited_activity.assigned_to.find(x=>x.assignment_id == edited_activity.assignments[i].assignment_id)) {
							edited_activity.assignments.splice(i, 1)
						}
					}

					// if we were told to delete lineitems and this section has one, add it to lineitems_to_delete
					if (lineitem_directive == 'delete_line_items' && edited_activity.lineItemSourcedId[assignee.class_sourcedId]) {
						payload.lineitems_to_delete = {}
						payload.lineitems_to_delete[assignee.class_sourcedId] = edited_activity.lineItemSourcedId[assignee.class_sourcedId]
					}
				}

				// add activity_data to payload
				payload.activity_data = edited_activity.copy_for_save()

				this.$store.dispatch('save_activity', payload).then((result)=>{
					--total_selected
					if (total_selected == 0) U.loading_stop()

					if (result.ic_status == 'gradebook-delete-fail') {
						let msg = 'Something went wrong with the communication with the Infinite Campus gradebook.'
						if (result.ic_error) msg += ` Error description from Infinite Campus:<div class="mt-2"><i>${result.ic_error}</i></div>`
						this.$inform(msg)
						console.log(result)
					}

					// replace activity in store
					this.$store.commit('add_to_my_activities', new Activity(result.activity))
				});
			}
		},
	}
}
</script>

<style lang="scss">
</style>