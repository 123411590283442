<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="1000" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #ccc">
				<b><v-icon color="blue darken-3" class="mr-2">fas fa-flag-checkered</v-icon>{{title_display}}</b>
				<v-btn v-if="initialized" icon small class="ml-3 elevation-0" color="pink darken-3" dark @click="edit_report_title"><v-icon small>fas fa-edit</v-icon></v-btn>
				<v-spacer></v-spacer>
				<b v-visible="saved_indicator_showing" class="green darken-2 white--text mr-2" style="font-size:14px; line-height:16px; padding:3px 6px; border-radius:4px"><v-icon small color="#fff" class="mr-1">fas fa-check</v-icon>Saved</b>
				<v-btn v-if="initialized" color="secondary" dark class="k-tight-btn mr-2" @click="show_report_link"><v-icon small class="mr-2">fas fa-link</v-icon>Share Link</v-btn>
				<v-btn color="primary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-check</v-icon>Done</v-btn>
			</v-card-title>
			<v-card-text v-if="!initialized" class="mt-2 text-center" style="font-size:16px">Loading lesson data...</v-card-text>
			<v-card-text v-if="initialized" class="mt-2" style="font-size:16px">
				<div class="mb-2 d-flex align-start" style="font-size:18px">
					<v-icon color="#333" class="mr-2" style="margin-top:-2px">fas fa-chalkboard</v-icon>
					<div v-html="course_display"></div>
					<v-spacer/>
					<v-icon color="#333" style="margin-top:-2px" class="mr-2">fas fa-calendar-week</v-icon>
					<div v-html="date_display"></div>
				</div>
				<v-tabs background-color="#eee" v-model="tab" class="k-lesson-report-tabs">
					<v-tab v-for="tab_item in tab_items" :key="tab_item.tab_key" active-class="k-lesson-report-active-tab" class="k-tight-btn k-nocaps-btn" v-html="tab_item.tab_text"></v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item v-for="tab_item in tab_items" :key="tab_item.tab_key" transition="false"><div class="k-lesson-report-body-outer">

						<!-- ================ REPORT ADMIN (EDIT COLLABORATORS AND LESSONS) =============== -->
						<div v-if="tab_item.type=='report_admin'">
							<div class="k-lesson-report-body-inner pt-3" style="position:relative">
								<div v-if="user_can_edit_report_admin" style="position:absolute; right:8px; top:8px;"><v-btn fab small dark color="pink darken-3" @click="edit_ra=!edit_ra"><v-icon>fas fa-edit</v-icon></v-btn></div>
								<h4 class="mb-3">Collaborators:</h4>
								<draggable v-bind="{animation:200,handle:'.k-move-handle'}" v-model="collaborators" @start="drag_started=true"><div v-for="collaborator in collaborators" :key="collaborator.user_id" class="d-flex py-1 my-1 white">
									<!-- note that only the owner can see move handle and delete btn, so only they can edit the order -->
									<v-icon v-if="edit_ra&&user_is_owner" style="font-size:18px" color="#666" class="k-move-handle ml-1">fas fa-up-down-left-right</v-icon>
									<v-btn v-if="edit_ra&&user_is_owner" v-visible="collaborator.user_id!=lr_copy.owner_user_id" class="ml-1" stylex="margin-top:-3px" small icon color="#666" @click="remove_collaborator(collaborator)"><v-icon style="font-size:22px">fas fa-circle-xmark</v-icon></v-btn>
									<v-icon style="font-size:22px" color="#333" class="ml-2 mr-2">fas fa-user</v-icon>
									<div class="mr-2" style="margin-top:2px">{{collaborator.first_name}} {{collaborator.last_name}} (<a :href="report_mailto(collaborator.email)">{{collaborator.email}}</a>)<span v-if="collaborator.user_id==lr_copy.owner_user_id" style="font-weight:900; font-size:12px; padding:2px 4px; border-radius:3px;" class="ml-2 green darken-3 white--text">REPORT OWNER</span></div>
								</div></draggable>
								<div v-if="edit_ra&&user_is_owner" class="pt-2" style="width:200px;"><v-btn small block color="secondary" @click="add_collaborator"><v-icon small class="mr-2">fas fa-plus</v-icon>Add Collaborator</v-btn></div>


								<h4 class="my-3 pt-2" style="border-top:1px solid #ccc">Reviewers:</h4>
								<div v-if="reviewers.length==0"><i>No reviewers have been specified. However, principals and assistant principals of all collaborators may review.</i></div>
								<draggable v-bind="{animation:200,handle:'.k-move-handle'}" v-model="reviewers" @start="drag_started=true"><div v-for="reviewer in reviewers" :key="reviewer.user_id" class="d-flex py-1 my-1 white">
									<!-- note that only the owner can see move handle and delete btn, so only they can edit the order -->
									<v-icon v-if="edit_ra&&user_is_owner" style="font-size:18px" color="#666" class="k-move-handle ml-1">fas fa-up-down-left-right</v-icon>
									<v-btn v-if="edit_ra&&user_is_owner" v-visible="reviewer.user_id!=lr_copy.owner_user_id" class="ml-1" stylex="margin-top:-3px" small icon color="#666" @click="remove_reviewer(reviewer)"><v-icon style="font-size:22px">fas fa-circle-xmark</v-icon></v-btn>
									<v-icon style="font-size:22px" color="#333" class="ml-2 mr-2">fas fa-user</v-icon>
									<div class="mr-2" style="margin-top:2px">{{reviewer.first_name}} {{reviewer.last_name}} (<a :href="report_mailto(reviewer.email)">{{reviewer.email}}</a>)<span v-if="reviewer.user_id==lr_copy.owner_user_id" style="font-weight:900; font-size:12px; padding:2px 4px; border-radius:3px;" class="ml-2 green darken-3 white--text">REPORT OWNER</span></div>
								</div></draggable>
								<div v-if="edit_ra&&user_is_owner" class="pt-2" style="width:200px;"><v-btn small block color="secondary" @click="add_reviewer"><v-icon small class="mr-2">fas fa-plus</v-icon>Add Reviewer</v-btn></div>


								<h4 class="my-3 pt-2" style="border-top:1px solid #ccc">Lessons:</h4>
								<div v-if="lessons.length==0"><i>No lessons have been added to the report.</i></div>
								<draggable v-bind="{animation:200,handle:'.k-move-handle'}" v-model="lessons" @start="drag_started=true"><div v-for="lesson in lessons" :key="lesson.lesson_id" class="d-flex py-1 my-1 white">
									<!-- note that only collaborators can see move handle, so only they can edit the order -->
									<v-icon v-if="edit_ra&&user_is_collaborator" style="font-size:18px" color="#666" class="k-move-handle ml-1">fas fa-up-down-left-right</v-icon>
									<!-- owner or creator of lesson can remove -->
									<v-btn v-if="edit_ra&&(user_is_owner||lesson.creator_user_id==user_info.user_id)" class="ml-1" small icon color="#666" @click="remove_lesson(lesson)"><v-icon style="font-size:22px">fas fa-circle-xmark</v-icon></v-btn>
									<v-icon style="font-size:24px" color="#333" class="ml-2 mr-2">fas fa-rectangle-list</v-icon>
									<div class="mr-2" style="margin-top:2px;" v-html="tab_items.find(x=>x.l==lesson).tab_text"></div>
									<div style="margin-top:2px;"><span v-html="lesson.lesson_title"></span> [<span v-html="lesson_editor_name(lesson)"></span>]</div>
								</div></draggable>
								<div v-if="edit_ra&&(user_is_owner||user_is_collaborator)" class="pt-2" style="width:200px;"><v-btn small block color="secondary" @click="add_lesson"><v-icon small class="mr-2">fas fa-plus</v-icon>Add Lesson(s)</v-btn></div>
							</div>
						</div>

						<!-- ================ PREWORK =============== -->
						<div v-if="tab_item.type=='prework'">
							<div class="k-lesson-report-body-inner pt-3">
								<div class="mb-3 d-flex align-center">
									<h4>Prework</h4>
									<v-spacer/>
									<div class="d-flex align-center ml-3">
										<b class="mr-2" style="font-size:14px;white-space:nowrap;">Show prework for:</b>
										<div><v-select style="width:250px" v-model="selected_collaborator" :items="prework_collaborator_select_arr" label="" outlined dense hide-details>
											<template v-slot:append-item><v-divider/><v-list-item class="mt-2"><v-list-item-content><v-list-item-title><i>* = Collaborator has entered Prework</i></v-list-item-title></v-list-item-content></v-list-item></template>
										</v-select></div>
									</div>
								</div>
								<div class="mb-3" style="font-size:14px;color:#666;font-weight:bold;"><i>Analyze the previous week’s common assessment data and Complete the Know/Show Chart for the upcoming week.</i></div>
								<div v-if="selected_collaborator" class="mb-2 pt-1" style="font-size:14px; border-top:1px solid #ccc;"><b>{{selected_collaborator_name}}</b> (<a :href="report_mailto(selected_collaborator_email)">{{selected_collaborator_email}}</a>)</div>
								<div v-if="!editing_prework">
									<div class="fr-view" v-html="prework_display"></div>
									<div v-if="user_can_edit_prework" class="text-center mt-4"><v-btn small color="pink darken-3" dark @click="editing_prework=true"><v-icon small class="mr-2">fas fa-edit</v-icon> Edit Prework</v-btn></div>
								</div>
								<div v-if="editing_prework">
									<froala-wrapper :config="editor_config()" v-model="prework_for_editing" />
									<div class="text-center mt-2"><v-btn small color="green darken-3" dark @click="save_prework"><v-icon small class="mr-2">fas fa-save</v-icon> Save and Close</v-btn></div>
								</div>
							</div>
						</div>

						<!-- ================ FEEDBACK (NEXT STEPS) =============== -->
						<div v-if="tab_item.type=='feedback'">
							<div class="k-lesson-report-body-inner pt-3">
								<div v-if="reviewer_rows.length>0" class="mb-3 pb-2" style="border-bottom:1px solid #ccc">
									<h4 class="mb-2">Reviewers:</h4>
									<div v-for="rr of reviewer_rows" :key="rr.user_record.user_id" class="mb-1 ml-2">
										<div v-if="!rr.review.approved" class="d-flex">
											<v-icon v-if="rr.user_record.user_id==user_info.user_id" color="primary" class="mr-2" @click="submit_approval">far fa-square</v-icon>
											<v-icon v-else color="#333" class="mr-2">far fa-square</v-icon>
											<div>{{rr.user_record.first_name}} {{rr.user_record.last_name}} <a :href="report_mailto(rr.user_record.email)">{{rr.user_record.email}}</a></div>
											<div v-if="rr.user_record.user_id==user_info.user_id" style="font-size:14px; cursor:pointer;" class="ml-2" @click="submit_approval"><i>(Click to approve)</i></div>
											<v-btn v-if="rr.user_record.user_id==user_info.user_id && !editing_reviewer_feedback" x-small outlined class="ml-2" color="primary" @click="">Leave Feedback</v-btn>
										</div>
										<div v-else class="d-flex">
											<v-icon color="green darken-2" class="mr-2" @click.shift="unsubmit_approval(rr.user_record.user_id)">fas fa-square-check</v-icon>
											<b class="green--text text--darken-2 mr-2" style="font-weight:900">APPROVED</b>
											<div>by {{rr.user_record.first_name}} {{rr.user_record.last_name}} <a :href="report_mailto(rr.user_record.email)">{{rr.user_record.email}}</a></div>
											<v-btn v-if="rr.user_record.user_id==user_info.user_id && !editing_reviewer_feedback" x-small outlined class="ml-2" color="primary" @click="editing_reviewer_feedback=true">{{ reviewer_feedback_for_editing ? 'Edit' : 'Leave' }} Feedback</v-btn>
										</div>
										<!-- reviewer feedback -->
										<div v-if="editing_reviewer_feedback&&rr.user_record.user_id==user_info.user_id" class="mt-1 ml-8">
											<froala-wrapper :config="editor_config(100)" v-model="reviewer_feedback_for_editing" />
											<div class="text-center mt-2"><v-btn small color="green darken-3" dark @click="save_feedback"><v-icon small class="mr-2">fas fa-save</v-icon> Save and Close</v-btn></div>
										</div>
										<div v-if="reviewer_feedback(rr.user_record.user_id)&&(!editing_reviewer_feedback||rr.user_record.user_id!=user_info.user_id)" class="mt-0 ml-7 d-flex">
											<p class="fr-view mr-2"><b>Feedback:</b></p>
											<div class="fr-view" v-html="reviewer_feedback_display(rr.user_record.user_id)"></div>
										</div>
									</div>
								</div>

								<div class="mb-3 d-flex align-center">
									<h4>Next Steps</h4>
									<v-spacer/>
									<div class="d-flex align-center ml-3">
										<b class="mr-2" style="font-size:14px;white-space:nowrap;">Show next steps for:</b>
										<div><v-select style="width:250px" v-model="selected_collaborator" :items="feedback_collaborator_select_arr" label="" outlined dense hide-details>
											<template v-slot:append-item><v-divider/><v-list-item class="mt-2"><v-list-item-content><v-list-item-title><i>* = Collaborator has entered Next Steps</i></v-list-item-title></v-list-item-content></v-list-item></template>
										</v-select></div>
									</div>
								</div>
								<div class="mb-3" style="font-size:14px;color:#666;font-weight:bold;"><i>What action steps do we need to complete as a team between now and our next collaborative meeting?</i></div>
								<div v-if="selected_collaborator" class="mb-2 pt-1" style="font-size:14px; border-top:1px solid #ccc;"><b>{{selected_collaborator_name}}</b> (<a :href="report_mailto(selected_collaborator_email)">{{selected_collaborator_email}}</a>)</div>
								<div v-if="!editing_teacher_feedback">
									<div class="fr-view" v-html="teacher_feedback_display"></div>
									<div v-if="user_can_edit_feedback" class="text-center mt-4"><v-btn small color="pink darken-3" dark @click="editing_teacher_feedback=true"><v-icon small class="mr-2">fas fa-edit</v-icon> Edit Next Steps</v-btn></div>
								</div>
								<div v-if="editing_teacher_feedback">
									<froala-wrapper :config="editor_config()" v-model="teacher_feedback_for_editing" />
									<div class="text-center mt-2"><v-btn small color="green darken-3" dark @click="save_feedback"><v-icon small class="mr-2">fas fa-save</v-icon> Save and Close</v-btn></div>
								</div>
							</div>
						</div>

						<!-- ================ STANDARDS =============== -->
						<div v-if="tab_item.type=='standards'">
							<div class="k-lesson-report-body-inner pt-3">
								<h4 class="mb-3">Henry Teaching & Learning Standards</h4>
								<div v-for="standard in all_standards" :key="standard.identifier" class="d-flex k-lesson-report-standard" v-html="rendered_standard(standard)" @click.stop="standard_clicked(standard)"></div>
							</div>
						</div>

						<!-- ================ LESSONS =============== -->
						<div v-if="tab_item.type=='lesson'">
							<div class="k-lesson-report-body-header">
								<v-icon class="mr-2" color="#fff">{{tab_item.l.icon()}}</v-icon>
								<div class="k-lesson-title" v-html="tab_item.l.lesson_title"></div>
								<v-spacer />
							</div>
							<div class="k-lesson-report-body-inner">
								<LessonView :lesson="tab_item.l" :all_components_open="true" />
							</div>
						</div>

					</div></v-tab-item>
				</v-tabs-items>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import goTo from 'vuetify/lib/services/goto'
import draggable from 'vuedraggable'
import { mapState, mapGetters } from 'vuex'
import ResourceCollectionItem from '../resources/ResourceCollectionItem'
import ResourceEditor from '../resources/ResourceEditor'
import FroalaResourcesMixin from '../../js/FroalaResourcesMixin'
import CASEItemBtn from '../standards/CASEItemBtn'
import LessonView from '../lessons/LessonView'

export default {
	components: { ResourceCollectionItem, ResourceEditor, draggable, CASEItemBtn, LessonView, },
	mixins: [FroalaResourcesMixin],
	props: {
		lesson_report_id: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		initialized: false,
		lessons_to_load: 0,
		tab: null,
		prework_user: 1001,
		editing_prework: false,
		editing_teacher_feedback: false,
		editing_reviewer_feedback: false,
		lessons: [],
		lr_copy: null,
		selected_collaborator: null,
		drag_started: false,
		edit_ra: false,
		saved_indicator_showing: false,
		saved_indicator_showing_timeout: null,
	}},
	computed: {
		...mapState(['my_lessons', 'my_resources', 'lesson_reports', 'user_records', 'user_info']),
		...mapGetters(['role', 'system_role', 'user_is_principal_or_ap']),
		lesson_report_url() {
			return `${window.location.origin}?lesson_report=${this.lesson_report.lesson_report_id}`
		},
		user_is_collaborator() {
			return this.lr_copy.collaborator_user_ids.includes(this.user_info.user_id)
		},
		user_is_reviewer() {
			if (this.lr_copy.reviewer_user_ids.includes(this.user_info.user_id)) return true
			// TODO: principals/aps should only be able to review if they are the principal/ap for one of the collaborators...
			if (this.user_is_principal_or_ap) return true
			return false
		},
		user_is_owner() {
			return this.lr_copy.owner_user_id == this.user_info.user_id
		},
		report_is_approved() {
			return U.object_has_keys(this.lr_copy.approvals)
		},
		user_can_edit_report_admin() {
			// return true if the user can edit some part of the report_admin tab
			return (this.user_is_owner || this.user_is_collaborator || this.user_is_principal_or_ap)
		},
		user_can_edit_prework() {
			// to edit prework, the current user must be selected
			if (this.selected_collaborator != this.user_info.user_id) return false
			// and the report must not have yet been approved
			if (this.report_is_approved) return false
			return true
		},
		user_can_edit_feedback() {
			// to edit feedback, the current user must be selected
			if (this.selected_collaborator != this.user_info.user_id) return false
			return true
		},
		lesson_report() {
			let lr
			if (this.lesson_report_id == '') {
				// if lesson_report_id is sent in as empty string, create a new report from scratch
				lr = new Lesson_Report({
					// lesson_report_id: 'new',
					collaborator_user_ids: [this.user_info.user_id],
					owner_user_id: this.user_info.user_id,
				})

				// in this case set edit_ra to true so the user can start editing
				this.edit_ra = true

			} else {
				lr = this.lesson_reports.find(x=>x.lesson_report_id == this.lesson_report_id)
			}
			return lr
		},
		lesson_report_description() {
			if (!this.lr_copy) return ''
			let s = this.lr_copy.report_title
			if (this.lessons.length == 0) s += ' [No Lessons Specified]'
			else {
				s += ' ['
				if (this.course_display) s += `${this.course_display}: `
				if (this.date_display) s += this.date_display
				s += ']'
			}
			return s
		},
		collaborators: {
			get() {
				let arr = []
				for (let cui of this.lr_copy.collaborator_user_ids) {
					if (cui == this.user_info.user_id) arr.push(this.user_info)
					else {
						let ur = this.user_records.find(x=>x.user_id == cui)
						if (ur) arr.push(ur)
						else arr.push({user_id: cui, first_name: '???', last_name: cui, email: `${cui}@agency.edu`})
					}
				}
				return arr
			},
			set(val) {
				let arr = []
				for (let ur of val) arr.push(ur.user_id)
				this.lr_copy.collaborator_user_ids = arr
				this.save_edits('collaborator_user_ids')
			},
		},
		reviewers: {
			get() {
				let arr = []
				for (let cui of this.lr_copy.reviewer_user_ids) {
					if (cui == this.user_info.user_id) arr.push(this.user_info)
					else {
						let ur = this.user_records.find(x=>x.user_id == cui)
						if (ur) arr.push(ur)
						else arr.push({user_id: cui, first_name: '???', last_name: cui, email: `${cui}@agency.edu`})
					}
				}
				return arr
			},
			set(val) {
				let arr = []
				for (let ur of val) arr.push(ur.user_id)
				this.lr_copy.reviewer_user_ids = arr
				this.save_edits('reviewer_user_ids')
			},
		},
		reviewer_rows() {
			let arr = []
			let found_current_user = false
			for (let r of this.reviewers) {
				let o = {}
				o.user_record = r
				o.review = this.lr_copy.reviews[r.user_id] ?? {approved: false}
				if (r.user_id == this.user_info.user_id) found_current_user = true
				arr.push(o)
			}
			// add a row for the current user if they don't already have one and they're a principal or AP; by definition if this is the case we won't have a review for this user
			if (!found_current_user && this.user_is_principal_or_ap) {
				arr.push({user_record: this.user_info, review: {approved: false}})
			}

			// sort with approved reviewers on top
			arr.sort((a,b)=>{
				if (a.review.approved && !b.review.approved) return -1
				if (b.review.approved && !a.review.approved) return 1
				return 0
			})
			return arr
		},
		collaborator_list() {
			let s = ''
			for (let o of this.collaborators) {
				if (s) s += ', '
				s += `${o.first_name} ${o.last_name}`
			}
			return s
		},
		prework_collaborator_select_arr() {
			let arr = []
			for (let user of this.collaborators) {
				let text = `${user.first_name} ${user.last_name}`
				// tag users that have entered prework
				if (this.lr_copy.prework[user.user_id]) text += '*'
				arr.push({value: user.user_id, text: text})
			}
			return arr
		},
		feedback_collaborator_select_arr() {
			let arr = []
			for (let user of this.collaborators) {
				let text = `${user.first_name} ${user.last_name}`
				// tag users that have entered feedback
				if (this.lr_copy.feedback[user.user_id]) text += '*'
				arr.push({value: user.user_id, text: text})
			}
			return arr
		},

		selected_collaborator_name() {
			let user = (this.selected_collaborator == this.user_info.user_id) ? this.user_info : this.user_records.find(x=>x.user_id == this.selected_collaborator)
			if (!user) return '???'
			return `${user.first_name} ${user.last_name}`
		},

		selected_collaborator_email() {
			let user = (this.selected_collaborator == this.user_info.user_id) ? this.user_info : this.user_records.find(x=>x.user_id == this.selected_collaborator)
			if (!user) return '???'
			return user.email
		},

		date_display() {
			let start = '9999', end = '0000'
			for (let l of this.lessons) {
				if (l.lesson_date && l.lesson_date < start) start = l.lesson_date
				if (l.lesson_date && l.lesson_date > end) end = l.lesson_date
			}
			if (start == '9999') return 'No Lesson Dates'
			start = start.replace(/^(\d+)-(.*)/, '$2-$1').replace(/^0/, '')
			end = end.replace(/^(\d+)-(.*)/, '$2-$1').replace(/^0/, '')
			if (start == end) return start
			return `${start} – ${end}`
		},
		title_display() {
			if (!this.lr_copy || this.lr_copy.report_title == 'WLP') return 'Weekly Lesson Plan'
			return `Weekly Lesson Plan: ${this.lr_copy.report_title}`
		},
		course_display() {
			if (this.lessons.length == 0) return 'No Lessons Specified'
			let course_codes = []
			for (let l of this.lessons) {
				if (l.course_code && !course_codes.includes(l.course_code)) course_codes.push(l.course_code)
			}
			if (course_codes.length == 0) return 'No Course'
			let s = ''
			for (let course_code of course_codes) {
				let lp = this.$store.state.all_courses.find(x=>x.course_code==course_code)
				if (lp) {
					if (s) s += ', '
					s += lp.title
				}
			}
			return s
		},
		prework: {
			get() { 
				let s = this.lr_copy.prework[this.selected_collaborator]
				if (empty(s)) return ''
				return s
			},
			set(val) { this.lr_copy.prework[this.selected_collaborator] = val }
		},
		prework_display() {
			if (empty(this.prework)) {
				return `<p><i class="red--text text--darken-2">This collaborator has not yet submitted Prework for this Weekly Lesson Plan.</i></p>`
			}
			return U.render_latex(this.prework)
		},
		prework_for_editing: {
			get() { return this.lr_copy.prework[this.selected_collaborator] },
			set(val) { this.$set(this.lr_copy.prework, this.selected_collaborator, val) },
		},

		feedback: {
			get() { 
				let s = this.lr_copy.feedback[this.selected_collaborator]
				if (empty(s)) return ''
				return s
			},
			set(val) { this.lr_copy.feedback[this.selected_collaborator] = val }
		},
		teacher_feedback_display() {
			if (empty(this.feedback)) {
				return `<p><i class="red--text text--darken-2">This collaborator has not yet submitted Next Steps for this Weekly Lesson Plan.</i></p>`
			}
			return U.render_latex(this.feedback)
		},
		teacher_feedback_for_editing: {
			get() { return this.lr_copy.feedback[this.selected_collaborator] },
			set(val) { this.$set(this.lr_copy.feedback, this.selected_collaborator, val) },
		},

		reviewer_feedback() { return (user_id) => {
			return this.lr_copy.feedback[user_id]
		}},
		reviewer_feedback_display() { return (user_id) => {
			let s = this.reviewer_feedback(user_id)
			if (empty(s)) return ''
			return U.render_latex(s)
		}},
		reviewer_feedback_for_editing: {
			get() { return this.reviewer_feedback(this.user_info.user_id) },
			set(val) { this.$set(this.lr_copy.feedback, this.user_info.user_id, val) },
		},

		// approved: {
		// 	get() { return this.lr_copy.approved },
		// 	set(val) { this.lr_copy.approved = val }
		// },
		tab_items() {
			if (!this.initialized) return []

			let arr = []
			arr.push({ type: 'report_admin', tab_text: '<div class="px-0 d-flex align-center"><i style="font-size:17px" class="fas fa-users"></i><i style="font-size:20px;margin-left:8px" class="fas fa-rectangle-list"></i></div>', tab_key:U.new_uuid() })	// <i style="font-size:17px;margin-left:8px;" class="fas fa-gear"></i>
			arr.push({ type: 'prework', tab_text: '<div class="px-0"><b>Prework</b></div>', tab_key:U.new_uuid() })
			arr.push({ type: 'standards', tab_text: '<div class="px-0"><b>Standards</b></div>', tab_key:U.new_uuid() })

			let lesson_count = 1
			for (let lesson of this.lessons) {
				let tab_title = lesson.date_header()
				if (empty(tab_title)) tab_title = `<div class="k-lesson-date-header k-lesson-date-header-amber">L${lesson_count}</div>`
				let o = {
					tab_key:U.new_uuid(),
					type: 'lesson',
					tab_text: `<div class="px-0">${tab_title}</div>`,
					l: lesson,	// use `l` for the lesson reference to ease coding
				}
				arr.push(o)
				++lesson_count
			}
			arr.push({ type: 'feedback', tab_text: '<div class="px-0"><b>Feedback</b></div>', tab_key:U.new_uuid() })
			return arr
		},
		all_standards() {
			let arr = []
			for (let tab_item of this.tab_items) {
				if (tab_item.type != 'lesson') continue
				for (let standard of tab_item.l.standards) {
					if (arr.find(x=>x.identifier == standard.identifier)) continue
					// console.log(standard)
					arr.push(standard)
				}
			}

			arr.sort((a,b)=>U.natural_sort(a.humanCodingScheme, b.humanCodingScheme))
			return arr
		},
	},
	watch: {
		lessons() {
			// for some reason the draggable @end event isn't working; this works instead
			if (this.drag_started) {
				this.lessons_reordered()
				this.drag_started = false
			}
		},
	},
	created() {
	},
	mounted() {
		vapp.lesson_report_component = this
		this.initialize()
	},
	methods: {
		initialize() {
			// get_all_courses if we haven't already
			if (!this.$store.state.all_courses_loaded) {
				this.$store.dispatch('get_all_courses')
			}

			// if report isn't fully-loaded, load it
			if (!this.lesson_report?.fully_loaded) {
				U.ajax('get_lesson_reports', {user_id: this.user_info.user_id, lesson_report_ids: [this.lesson_report_id]}, result=>{
					if (result.status != 'ok') {
						this.$alert('An error occurred when attempting to retrieve the Weekly Lesson Plan.')
						return
					}
					if (result.lesson_reports.length == 0) {
						this.$alert('An error occurred when attempting to retrieve the Weekly Lesson Plan (2).')
						return
					}
					let lr = new Lesson_Report(result.lesson_reports[0])
					lr.fully_loaded = true	// it must be fully_loaded now
					
					let lri = this.lesson_reports.findIndex(x=>x.lesson_report_id==lr.lesson_report_id)
					// if the lr doesn't already exist in lesson_reports, push; otherwise splice it in
					if (lri == -1) {
						this.$store.commit('set', [this.lesson_reports, 'PUSH', lr])
					} else {
						this.$store.commit('set', [this.lesson_reports, 'SPLICE', lri, lr])
					}

					// then re-initialize on next tick (after lesson_report has been able to update
					this.$nextTick(x=>this.initialize())
				})
				return
			}

			// mark report as viewed
			this.$store.commit('set', [this.lesson_report, 'viewed', true])

			// make a copy of the lesson_report, so that we can easily edit it if we wish
			this.lr_copy = new Lesson_Report(object_copy(this.lesson_report))

			// set selected_collaborator to this user (if they are a collaborator), or to the owner_user_id
			if (this.user_is_collaborator) {
				this.selected_collaborator = this.user_info.user_id
			} else {
				this.selected_collaborator = this.lr_copy.owner_user_id
			}

			// get user records for collaborators and reviewers
			let user_ids_to_get = []
			for (let cui of this.lr_copy.collaborator_user_ids) {
				if (cui != this.user_info.user_id) user_ids_to_get.push(cui)
			}
			for (let cui of this.lr_copy.reviewer_user_ids) {
				if (cui != this.user_info.user_id) user_ids_to_get.push(cui)
			}
			if (user_ids_to_get.length > 0) {
				this.$store.dispatch('get_user_records', {user_ids: user_ids_to_get})
			}

			// get the lessons for the report. start with lesson_masters if we don't already have them
			this.$store.dispatch('get_lesson_masters').then(x=>{
				for (let lesson_id of this.lr_copy.lesson_ids) {
					// check to see if we have the lesson in my_lessons
					let lesson = this.my_lessons.find(x=>x.lesson_id == lesson_id)
					// if not create a new Lesson object with the lesson_id
					if (!lesson) lesson = new Lesson({lesson_id: lesson_id})

					if (lesson.lesson_fully_loaded) {
						this.lessons.push(lesson)
					// then get the lesson data if it isn't yet fully loaded
					} else {
						++this.lessons_to_load
						this.$store.dispatch('load_lesson', lesson).then(loaded_lesson=>{
							this.lessons.push(loaded_lesson)
							--this.lessons_to_load
							if (this.lessons_to_load == 0) {
								this.$nextTick(x=>this.initialized = true)
							}
						})
					}
				}
				if (this.lessons_to_load == 0) {
					this.initialized = true
				}
			})

			// TODO: get user info for collaborators and reviewers
		},

		rendered_standard(standard) {
			let s = ''
			if (standard.humanCodingScheme) s += `<p><b class="mr-2">${standard.humanCodingScheme}</b></p>`
			s += U.marked_latex(standard.fullStatement)
			return s
		},

		standard_clicked(case_item) {
			// show the framework, highlight this item, and send in selected_items and limit_to_selected_items if there
			let data = { 
				framework_identifier: case_item.framework_identifier, 
				item_identifier: case_item.identifier, 
				no_framework_reset: true,
			}

			if (!data.framework_identifier) {
				// shouldn't happen I think...
				console.warn('standard_clicked; couldn’t get framework_identifier', case_item)
			}

			// add all standards as selected_items
			if (this.all_standards.length > 0) {
				data.selected_items = []
				for (let case_item of this.all_standards) data.selected_items.push(case_item.identifier)
				data.limit_to_selected_items = 'only'
			}

			vapp.$refs.satchel.execute('show').then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
				})
			})
		},

		editor_config(heightMin = 250) {
			let config = U.get_froala_config({
				placeholderText: '',
				// initOnClick: true,
				toolbarInline: false,
				paragraphFormat: {
					H3: 'Section Header',
					N: 'Normal',
					BLOCKQUOTE: 'Block Quote',
				    PRE: 'Code',
				},
				heightMin: heightMin,
			})

			// add the insert resource btn at the front of the moreRich buttons
			config.toolbarButtons.moreRich.buttons.unshift('insert_resource')
			config.toolbarButtons.moreRich.buttonsVisible += 1

			return config
		},

		edit_report_title() {
			// 'WLP' is the default value for report_title
			let initialValue = (this.lr_copy.report_title == 'WLP') ? '' : this.lr_copy.report_title
			this.$prompt({
				title: 'Edit Weekly Lesson Plan Title',
				text: '',
				initialValue: initialValue,
				disableForEmptyValue: false,
				acceptText: 'Save',
				acceptIcon: 'fas fa-save',
			}).then(title => {
				// convert empty string to the default value ('WLP')
				title = $.trim(title)
				if (empty(title)) title = 'WLP'

				// if title changed, save it
				if (title != this.lr_copy.report_title) {
					this.lr_copy.report_title = title
					this.save_edits('report_title')
				}
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		add_collaborator() {
			this.$prompt({
				text: 'Find the email address of the user you wish to add as a collaborator on this Weekly Lesson Report:',
				promptType: 'autocomplete',
				serviceName: 'email_search',
				initialValue: '',
				acceptText: 'Add Collaborator',
			}).then(email => {
				// return value should be e.g. 'pepper@gmail.com (Pepper Williams)'; extract the email
				email = $.trim(email).toLowerCase().replace(/^(\S+).*/, '$1')

				if (empty(email) || email.indexOf('@') == -1) {
					this.$alert('Please enter a valid user email.').then(x=>{this.add_collaborator()})
					return
				}

				// look up user record from email
				this.$store.dispatch('get_user_records', {emails: [email]}).then(added_user_records=>{
					if (added_user_records.length == 0) return	// shouldn't happen; we just found the email above
					this.lr_copy.collaborator_user_ids.push(added_user_records[0].user_id)
					this.save_edits('collaborator_user_ids')
				})
				
			}).catch(n=>{}).finally(f=>{});
		},

		remove_collaborator(collaborator) {
			let i = this.lr_copy.collaborator_user_ids.findIndex(x=>x==collaborator.user_id)
			if (i > -1) {
				this.lr_copy.collaborator_user_ids.splice(i, 1)
				this.save_edits('collaborator_user_ids')
			}
		},

		add_reviewer() {
			this.$prompt({
				text: 'Find the email address of the user you wish to add as a reviewer on this Weekly Lesson Report:',
				promptType: 'autocomplete',
				serviceName: 'email_search',
				initialValue: '',
				acceptText: 'Add Reviewer',
			}).then(email => {
				// return value should be e.g. 'pepper@gmail.com (Pepper Williams)'; extract the email
				email = $.trim(email).toLowerCase().replace(/^(\S+).*/, '$1')

				if (empty(email) || email.indexOf('@') == -1) {
					this.$alert('Please enter a valid user email.').then(x=>{this.add_reviewer()})
					return
				}

				// look up user record from email
				U.ajax('get_user_records', {user_id: this.user_info.user_id, emails: [email]}, result=>{
					if (result.status != 'ok') {
						this.$alert('An error occurred when attempting to add the reviewer.')
						return
					}
					if (result.users.length == 0) return	// shouldn't happen; we just found the email above
					this.user_records.push(new User_Info(result.users[0]))
					this.lr_copy.reviewer_user_ids.push(result.users[0].user_id)
					this.save_edits('reviewer_user_ids')
				})
				
			}).catch(n=>{}).finally(f=>{});
		},

		remove_reviewer(reviewer) {
			let i = this.lr_copy.reviewer_user_ids.findIndex(x=>x==reviewer.user_id)
			if (i > -1) {
				this.lr_copy.reviewer_user_ids.splice(i, 1)
				this.save_edits('reviewer_user_ids')
			}
		},

		save_prework() {
			// if (this.prework_for_editing != this.lesson_report.prework[this.selected_collaborator]) {
				this.save_edits('prework')
			// }
			this.editing_prework = false
		},

		save_feedback() {
			this.save_edits('feedback')
			this.editing_teacher_feedback = false
			this.editing_reviewer_feedback = false
		},

		add_lesson() {
			// L902, L909, L907, L913
			this.$prompt({
				title: 'Add Lesson(s)',
				text: 'Enter one or more lesson “share codes”:<div class="mt-2" style="font-size:14px;font-style:italic">Lesson share codes should start with an “L”.</div>',
				initialValue: '',
				disableForEmptyValue: true,
				acceptText: 'Add Lesson(s)',
				acceptIconAfter: 'fas fa-circle-arrow-right',
			}).then(s => {
				// split into share codes in case there are multiple, and check the format for each one
				let arr = $.trim(s).split(/\s*,\s*/)
				if (arr.length == 0) return

				let lesson_ids = []
				for (let entered_item_id of arr) {
					if (entered_item_id.search(/^([L])(-?)([\w-]+)$/) == -1) {
						if (arr.length == 1) this.$alert('The share code you entered is not valid. To get the share code for a lesson, click the “<i class="fas fa-bolt"></i>bolt” menu for the lesson and choose “Share Lesson Plan”.')
						else this.$alert('At least one of the share codes you entered is not valid. To get the share code for a lesson, click the “<i class="fas fa-bolt"></i>bolt” menu for the lesson and choose “Share Lesson Plan”.')
						return
					}
					lesson_ids.push(RegExp.$3)
				}

				// for each lesson_id
				this.lessons_to_load = 0
				for (let lesson_id of lesson_ids) {
					// if the lesson is already in the report, continue
					if (this.lr_copy.lesson_ids.includes(lesson_id)) continue

					// check to see if we have the lesson in my_lessons
					let lesson = this.my_lessons.find(x=>x.lesson_id == lesson_id)
					// if not create a new Lesson object with the lesson_id
					if (!lesson) lesson = new Lesson({lesson_id: lesson_id})

					if (lesson.lesson_fully_loaded) {
						this.lr_copy.lesson_ids.push(lesson_id)
						this.lessons.push(lesson)

					// get the lesson data if it isn't yet fully loaded
					} else {
						++this.lessons_to_load
						this.$store.dispatch('load_lesson', lesson).then(loaded_lesson=>{
							this.lr_copy.lesson_ids.push(lesson_id)
							this.lessons.push(loaded_lesson)

							// once we've loaded all the lessons, save_edits
							--this.lessons_to_load
							console.warn('A:' + this.lessons_to_load)
							if (this.lessons_to_load == 0) this.save_edits('lesson_ids')

						}).catch(x=>{
							// if a lesson fails to load, that lesson's code was bad
							console.warn('B:' + this.lessons_to_load)
							--this.lessons_to_load
							if (this.lessons_to_load == 0) this.save_edits('lesson_ids')
						})
					}
				}
				console.warn('C:' + this.lessons_to_load)
				// if we didn't need to load any lessons, save now
				if (this.lessons_to_load == 0) this.save_edits('lesson_ids')

			}).catch(n=>{}).finally(f=>{})
		},

		// this is for displaying the name of the editor of each lesson in the lesson editor tab
		lesson_editor_name(lesson) {
			let user = (lesson.creator_user_id == this.user_info.user_id) ? this.user_info : this.user_records.find(x=>x.user_id == lesson.creator_user_id)
			if (!user) return '???'
			return `${user.first_name} ${user.last_name}`
		},

		lessons_reordered(evt) {
			// lessons will be in new order; translate this order to lr_copy.lesson_ids
			let arr = []
			for (let l of this.lessons) arr.push(l.lesson_id)
			this.lr_copy.lesson_ids = arr
			
			this.save_edits('lesson_ids')
		},

		remove_lesson(lesson) {
			this.$confirm({
				title: 'Are you sure?',
				text: `Are you sure you want to remove this lesson (“${lesson.lesson_title}”) from the Weekly Lesson Plan?`,
				acceptText: 'Remove Lesson',
				acceptColor: 'red darken-2',
				acceptIcon: 'fas fa-trash-alt',
			}).then(y => {
				let i = this.lessons.findIndex(x=>x==lesson)
				if (i > -1) {
					this.lessons.splice(i, 1)
					this.lr_copy.lesson_ids.splice(i, 1)
					this.save_edits('lesson_ids')
				}
			}).catch(n=>{}).finally(f=>{})
		},

		report_mailto(email) {
			return `mailto:${email}?subject=${encodeURIComponent(this.lesson_report_description)}&body=${this.lesson_report_url}`
		},

		show_report_link() {
			let msg = '<div class="mb-2">The following URL will take users directly to this Weekly Lesson Plan:</div>'
			msg += `<div class="text-center" style="font-size:16px"><b>${this.lesson_report_url}</b></div>`

			this.$confirm({
				title: 'Share Weekly Lesson Plan Link',
				text: msg,
				cancelText: 'Copy Link URL',
				cancelIcon: 'fas fa-copy',
				acceptIcon: 'fas fa-check',
				dialogMaxWidth: 640,
			}).catch(e=>{
				U.copy_to_clipboard(this.lesson_report_url)
				this.$inform('Link copied to clipboard')
			})
		},

		submit_approval(evt) {
			// this is called when the currently-signed-in user is a reviewer or potential reviewer (i.e. a principal or AP), and clicks the checkbox icon
			// if the user wasn't already listed as a reviewer, list them
			if (!this.lr_copy.reviewer_user_ids.includes(this.user_info.user_id)) {
				this.lr_copy.reviewer_user_ids.push(this.user_info.user_id)
			}
			this.lr_copy.reviews[this.user_info.user_id] = {approved: true}
			this.save_edits('approval')
		},

		unsubmit_approval(user_id) {
			// hidden functionality, mostly for demo purposes: if user shift-clicks on approval checkbox, unsubmit approval
			this.$confirm({
				title: 'Are you sure?',
				text: 'Are you sure you want to cancel the approval flag for this reviewer?',
				acceptText: 'Do It',
			}).then(y => {
				this.lr_copy.reviews[user_id].approved = false
				this.save_edits('reviews')
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		save_edits(field) {
			console.warn('save_edits')
			let payload = { user_id: this.user_info.user_id, }

			if (empty(this.lr_copy.lesson_report_id)) {
				// newly-created lesson: create a lesson_report_id and save all fields
				this.lr_copy.lesson_report_id = U.new_uuid()
				field = 'all'
			}

			// only send data that has changed
			let lr_data = { lesson_report_id: this.lr_copy.lesson_report_id, }
			if (field == 'all') {
				lr_data = this.lr_copy.copy_for_save()
			} else if (field == 'approval') {
				// send both the reviewer_user_ids and the reviews in this case
				lr_data.reviewer_user_ids = this.lr_copy.reviewer_user_ids
				lr_data.reviews = this.lr_copy.reviews
			} else {
				lr_data[field] = this.lr_copy[field]
			}
			payload.lr_data = lr_data

			// always send resource description, since it can change because of a number of things
			payload.lr_data.description = this.lesson_report_description

			U.ajax('save_lesson_report', payload, result=>{
				if (result.status != 'ok') {
					this.$alert('An error occurred when attempting to save the Weekly Lesson Plan.')
					return
				}

				// re-create the lesson report in the store, and lr_copy, from the incoming new report data
				let lr = new Lesson_Report(result.updated_lesson_report)
				lr.viewed = true
				let index = this.lesson_reports.findIndex(x=>x.lesson_report_id == lr.lesson_report_id)
				if (index == -1) this.$store.commit('set', [this.lesson_reports, 'PUSH', lr])
				else this.$store.commit('set', [this.lesson_reports, 'SPLICE', index, lr])

				this.lr_copy = new Lesson_Report(result.updated_lesson_report)

				// show saved indicator
				clearTimeout(this.saved_indicator_showing_timeout)
				this.saved_indicator_showing = true
				console.warn('saved_indicator_showing: ' + this.saved_indicator_showing)
				this.saved_indicator_showing_timeout = setTimeout(x=>{
					this.saved_indicator_showing = false
				}, 2500)
			})
		},
	}
}
</script>

<style lang="scss">
.k-lesson-report-tabs {
	border-radius:8px 8px 0 0;
	// border-top:1px solid $v-blue-darken-3;
	// border-left:1px solid $v-blue-darken-3;
	// border-right:1px solid $v-blue-darken-3;
	margin-bottom:-2px;

	.v-tab {
		min-width:auto;
	}
}

.k-lesson-report-active-tab {
	border-radius:7px 7px 0 0;
	background-color:$v-blue-darken-3;
	color:#fff!important;
	// color:transparent!important;
}

.k-lesson-report-body-outer {
	border-left:1px solid $v-blue-darken-3;
	border-right:1px solid $v-blue-darken-3;
	border-bottom:1px solid $v-blue-darken-3;
	padding:0px;
	border-radius:0 0 8px 8px;
	min-height:300px;
}

.k-lesson-report-body-header {
	background-color:$v-blue-darken-3;
	color:#fff;
	padding:12px;
	display:flex;
	align-items:center;
}

.k-lesson-report-body-inner {
	border-top:3px solid $v-blue-darken-3;
	padding:0 12px 12px 12px;

	h4 {
		font-size:20px;
	}
}

.k-lesson-report-standard:hover {
	text-decoration:underline;
	cursor:pointer;
}
</style>
