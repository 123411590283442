// Note that this file contains classes for both lessons and activities

class Lesson {
	constructor(data, master_data) {
		if (empty(data)) data = {}

		// allow for different variants, which we will just label 'A', 'B', etc.
		sdp(this, data, 'lp_variant', 'A')

		sdp(this, data, 'lesson_id', 0)

		sdp(this, data, 'agency_sanctioned', false)

		sdp(this, data, 'lesson_template_id', 0)
		sdp(this, data, 'lesson_title', '')

		// lesson status is empty, 'archived', or 'deleted'
		// but note that we don't currently load archived or deleted lessons to the client, so we don't really need to store this here
		sdp(this, data, 'lesson_status', '')

		sdp(this, data, 'lesson_date', '')	// format: 2022-03-24; see date_header() below for how to convert this to a date object

		sdp(this, data, 'course_code', '')
		sdp(this, data, 'lp_unit_id', 0)
		sdp(this, data, 'checked_out_for_edit', {user_id: null, timestamp: null})

		// temporary booleans for whether the lesson is fully loaded and whether or not things are showing
		// for lesson_fully_loaded, if we already have a key_uuid, use the lesson_fully_loaded value we calculated when the lesson was first created
		if (data.key_uuid) this.lesson_fully_loaded = data.lesson_fully_loaded
		// otherwise, if data.lesson_plan is specified, the lesson has been fully loaded
		else this.lesson_fully_loaded = !empty(data.lesson_plan)
		this.lesson_showing = false
		this.item_showing = false
		this.sparkl_showing = false
		this.resources_showing = false
		this.standards_showing = false
		this.student_description_showing = false
		this.activity_type = 'lesson'	// see DirectivesListItem

		// arbitrary uuid to use as the key in components; removed before saving
		this.key_uuid = U.new_uuid()

		this.lesson_plan = []

		// note value of data.lesson_master_id in case we need to convert from variant A to B
		const original_lesson_master_id = data.lesson_master_id

		// variant 'B': supports GPT suggestions, as well as associated sparkl activities
		if (this.lp_variant == 'B') {
			// For now, at least, the master for variant B is hard coded

			this.lesson_master_id = '9'	// we'll store this as lesson_master_id 9, though lesson_master_id isn't really relevant anymore
			master_data = window.variant_b_lesson_master_data

			sdp(this, data, 'sparkl_activity_id', 0)
			
			// counters for GPT activity
			sdp(this, data, 'gpt_suggestion_requests', 0)
			sdp(this, data, 'gpt_suggestion_accepts', 0)
			sdp(this, data, 'gpt_input_tokens', 0)
			sdp(this, data, 'gpt_output_tokens', 0)

			let found = []
			// load all components in the master
			for (let i = 0; i < master_data.lesson_plan.length; ++i) {
				let mc = master_data.lesson_plan[i]
				let dci = data.lesson_plan?.findIndex(x=>x.lc_uuid == mc.lc_uuid)
				// if we have incoming data for this component, load it
				if (dci > -1) {
					this.lesson_plan.push(new Lesson_Component_B(data.lesson_plan[dci]))
					found[dci] = true
				
				// otherwise load an empty component based on the master component
				} else {
					this.lesson_plan.push(new Lesson_Component_B(mc))
				}
			}

			// ======== hack a conversion from the old lesson masters to the new LPC master
			// now, if we have incoming lp components that aren't part of this master...
			if (data.lesson_plan) for (let k = 0; k < data.lesson_plan.length; ++k) {
				if (!found[k]) {
					let old_component = data.lesson_plan[k]

					// if this is a variant A component, convert parts to lc_content
					let lc_content = old_component.lc_content ?? ''
					if (empty(lc_content) && !empty(old_component.lc_parts)) {
						for (let j = 0; j < old_component.lc_parts.length; ++j) {
							let p = old_component.lc_parts[j]

							if (p.lcp_title) lc_content += `<p><b>${p.lcp_title}</b></p>`
							else if (old_component.lc_parts.length > 1) lc_content += `<p><b>Part ${j+1}</b></p>`
		
							lc_content += p.lcp_teacher_content
						}				
					}

					// if we got some content, determine what component in the new master it should go in, and put it in place
					if (!empty(lc_content)) {
						let new_component

						// for the 'simple' old lesson master from inspire/adel, put component 0 ("Learning Plan") in 'ido', and component 1 ("Ideas for Differentiation") in 'remediation'
						if (original_lesson_master_id == 5) {
							if (k == 0) new_component = this.lesson_plan.find(x=>x.lc_uuid == 'ido')
							else if (k == 1) new_component = this.lesson_plan.find(x=>x.lc_uuid == 'remediation')
							else new_component = this.lesson_plan[k]	// shouldn't happen
						
						// TODO: else this is probably the Henry master; decide what to do here...
						} else {
							new_component = this.lesson_plan[k]
						}

						new_component.lc_content = lc_content
						new_component.lc_status = 'complete'
					}
				}
			}
			// ========

		// variant 'A' -- default (pre-ALBA)
		} else {
			sdp(this, data, 'lesson_master_id', 0)

			// if we received master_data, use that for the structure of the lesson_plan
			if (master_data && master_data.lesson_plan) {
				for (let i = 0; i < master_data.lesson_plan.length; ++i) {
					// start with a shallow copy of the master's lesson_component, which should include lc_default_content (if provided in the master)
					let c = Object.assign({}, master_data.lesson_plan[i])

					// then splice in lc_parts from data if there
					if (data.lesson_plan && data.lesson_plan[i]) {
						c.lc_parts = data.lesson_plan[i].lc_parts
						c.lc_showing = data.lesson_plan[i].lc_showing	// preserve whether or not the component is showing
					} else {
						c.lc_parts = []
						// if we have lc_default_content for the master component, add it as the first part of the component
						if (!empty(c.lc_default_content)) {
							c.lc_parts.push(new Lesson_Component_Part({lcp_teacher_content:c.lc_default_content}))
						}
					}

					// create the component from this data
					this.lesson_plan.push(new Lesson_Component(c))

					// note that when we save, we'll pull out everything except lc_parts
				}

			// else we didn't receive master_data, so just use the data in data directly
			} else {
				if (!empty(data.lesson_plan)) {
					for (let c of data.lesson_plan) {
						this.lesson_plan.push(new Lesson_Component(c))
					}
				}
			}
		}

		sdp(this, data, 'student_description', '')

		// if lesson is restricted to certain classes of users this will be set to a string that designates who's authorized
		sdp(this, data, 'restricted', '')

		this.resources = []
		if (!empty(data.resources)) {
			for (let r of data.resources) {
				this.resources.push(new Resource(r))
			}
		}

		this.lessons = []
		if (!empty(data.lessons)) {
			for (let r of data.lessons) {
				this.lessons.push(new Lesson(r))
			}
		}

		// when we first load lessons from the server, we will get a resource_ids string that specifies the order of the resources (and ditto for lessons)
		if (!empty(data.resource_ids) && typeof(data.resource_ids) == 'string') {
			let order = data.resource_ids.split(',')
			let arr = []
			for (let resource_id of order) {
				let r = this.resources.find(x=>x.resource_id == resource_id)
				if (r) arr.push(r)
			}
			this.resources = arr
			// we don't need the resource_ids field to be saved
		}
		if (!empty(data.lesson_ids) && typeof(data.lesson_ids) == 'string') {
			let order = data.lesson_ids.split(',')
			let arr = []
			for (let lesson_id of order) {
				let r = this.lessons.find(x=>x.lesson_id == lesson_id)
				if (r) arr.push(r)
			}
			this.lessons = arr
			// we don't need the lesson_ids field to be saved
		}

		this.standards = []
		if (!empty(data.standards)) {
			for (let standard of data.standards) {
				// for now at least we'll use the learning progression CASE_Item structure for standards; this is a bit simpler than the full CFItem structure
				this.standards.push(new CASE_Item(standard))
			}
		}

		// if we are converting from variant A and we have multiple standards, make all except the first one secondary
		if (original_lesson_master_id != this.lesson_master_id && this.standards.length > 1) {
			for (let i = 1; i < this.standards.length; ++i) {
				this.standards[i].is_secondary = true
			}
		}

		sdp(this, data, 'creator_user_id', 0)

		// convert dates from mysql to timestamp if necessary
		let tzo = new Date().getTimezoneOffset()*60*1000	// dates come in GMT; convert to local

		if (!isNaN(data.edited_date*1)) this.edited_date = data.edited_date*1
		else this.edited_date = (empty(data.edited_date)) ? 0 : (date.parse(data.edited_date, 'YYYY-MM-DD HH:mm:ss').getTime() - tzo) / 1000

		if (!isNaN(data.created_at*1)) this.created_at = data.created_at*1
		else this.created_at = (empty(data.created_at)) ? 0 : (date.parse(data.created_at, 'YYYY-MM-DD HH:mm:ss').getTime() - tzo) / 1000
	}

	copy_for_save(flag) {
		let o = $.extend(true, {}, this)

		delete o.key_uuid
		delete o.component
		delete o.lesson_fully_loaded
		delete o.editing
		delete o.lesson_showing
		delete o.item_showing
		delete o.sparkl_showing
		delete o.resources_showing
		delete o.standards_showing
		delete o.student_description_showing
		delete o.activity_type
		delete o.checked_out_for_edit

		// remove full resources object; attach resource_ids as a comma-delimited string; ditto for lessons
		delete o.resources
		if (this.resources.length > 0) {
			o.resource_ids = ''
			for (let r of this.resources) {
				if (o.resource_ids != '') o.resource_ids += ','
				o.resource_ids += r.resource_id
			}
		}
		// if there are no resources left, have to clear the resource_ids field
		if (empty(o.resource_ids)) o.resource_ids = '*CLEAR*'

		delete o.lessons
		if (this.lessons.length > 0) {
			o.lesson_ids = ''
			for (let r of this.lessons) {
				if (o.lesson_ids != '') o.lesson_ids += ','
				o.lesson_ids += r.lesson_id
			}
		}
		// if there are no lessons left, have to clear the lesson_ids field
		if (empty(o.lesson_ids)) o.lesson_ids = '*CLEAR*'

		o.lesson_plan = [];
		for (let c of this.lesson_plan) {
			o.lesson_plan.push(c.copy_for_save(flag))
		}

		// get savable copies for standards
		o.standards = []
		for (let standard of this.standards) {
			o.standards.push(standard.copy_for_save())
		}
		
		// we don't want to send empty strings or 0s in to the service for certain things
		if (o.edited_date == 0) delete o.edited_date
		if (o.created_at == 0) delete o.created_at
		if (empty(o.resource_ids)) delete o.resource_ids
		if (empty(o.lesson_ids)) delete o.lesson_ids
		return o
	}

	course_unit_header() {
		if (!this.course_code) return ''

		let lp = vapp.$store.state.all_courses.find(x=>x.course_code==this.course_code)
		if (!lp) lp = vapp.$store.state.sis_classes.find(x=>x.course_code == this.course_code)
		if (!lp) return this.course_code

		let s = lp.title

		if (this.lp_unit_id && lp.units) {
			let u = lp.units.find(x=>x.lp_unit_id == this.lp_unit_id)
			if (u && u.display_number) {
				s += ': <nobr>' + u.display_number + '</nobr>'
			}
		}

		let style = U.collection_color_style(lp)
		s = `<div class="k-lesson-course-unit-header-inner" style="border-color:${style['background-color']}; color:${style['background-color']}">${s}</div>`

		return s
	}

	date_color() {
		if (!this.lesson_date) return 'grey'
		let d = new Date(this.lesson_date + 'T00:00:00')
		// green if in the future (date is > now) and it's available to students; otherwise amber
		return (this.available_to_students() && d.getTime() > vapp.$store.state.now_date_obj.getTime()) ? 'teal' : 'amber'
	}

	date_header() {
		if (!this.lesson_date) return ''
		let d = new Date(this.lesson_date + 'T00:00:00')

		let ds = date.format(d, 'ddd M/D').replace(/(\d+\/\d+)/, '<span class="k-lesson-date-header-date">$1</span>')
		return sr('<div class="k-lesson-date-header k-lesson-date-header-$1">$2</div>', this.date_color(), ds)
	}

	// lessons cannot be assigned to different dates, but define these for convenience in places where we consider lessons and activities together (e.g. DirectivesGantt)
	due_date() { return '' }
	available_date() { return '' }
	has_multiple_available_dates() { return false }
	has_multiple_due_dates() { return false }

	standalone_link() {
		return window.location.origin + '/lesson/' + this.lesson_id
	}

	icon() { return U.directive_type_icon('lesson') }	// use the directory_type fns for this
	type_label(capitalize) { 
		let s = U.directive_type_label('lesson') 
		if (capitalize) return s
		else return s.toLowerCase()
	}

	// return the html to insert into a froala-editable area (e.g. a lesson component) with a link to a lesson being used as a resource
	froala_resource_link_html() {
		let title = this.lesson_title.replace(/"/g, '\\"')
		// note nbsp's on either side, to make sure we can edit around the resource link
		return `&nbsp;<link class="k-lesson-component-resource-link fas ${this.icon()}" title="${title}" data-resource-link-id="${U.new_uuid()}" onclick="vapp.open_resource_link(\'${this.lesson_id}\',this)">&nbsp;`
	}

	available_to_students() {
		// show lessons iff they have a date and a student_description
		return (!empty(this.lesson_date) && !empty(this.student_description))
	}

	sort_date() {
		let s = this.lesson_date ? this.lesson_date : '9999-99-99'
		// lessons are sometimes sorted along with activities, which may have available_dates. Add a placeholder available_date to the lesson sort_date value, manipulating it so that for activities and lessons with the same dates, lessons will come first
		s += ' 0000-00-00'
		// s += ' 9999-99-98'
		return s
	}

	lesson_date_passed(now) {
		if (empty(now)) now = vapp.$store.state.now_date_string
		// the lesson date is passed if it has an lesson date and the lesson date is < now
		return this.lesson_date != '' && this.lesson_date < now
	}

	// this is implemented for both activities and lessons: we consider an item "old" if it has a date and its date is < 7 days prior to now
	item_is_old() {
		return this.lesson_date != '' && this.lesson_date < vapp.$store.state.old_threshold_date_string
	}

	// return true iff the lesson is visible by the given user role; if role not specified, use state role
	// note that callers of this fn should check themselves for whether or not the user is an editor for the collection, as collection editors can always see everything
	is_visible_by_user(role) {
		// if show_all_items_when_not_signed_in is 'yes', everyone gets to see everything
		if (vapp.site_config.show_all_items_when_not_signed_in == 'yes') return true

		if (empty(role)) role = vapp.$store.getters.role

		// for now at least, it's easy: staff and admins see lessons; students and parents don't
		if (role == 'student' || role == 'parent') return false
		return true
	}
}
window.Lesson = Lesson

class Lesson_Component {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'lc_uuid', U.new_uuid())
		sdp(this, data, 'lc_title', '')
		sdp(this, data, 'lc_weight', 0)
		sdp(this, data, 'lc_guidance', '')
		sdp(this, data, 'lc_default_content', '')

		// legacy: originally we coded lc_content as a string; convert that to the first lc_part
		if (data.lc_content) {
			data.lc_parts = [{lcp_teacher_content: data.lc_content}]
		}

		// the meat of the component is one or more Lesson_Component_Parts
		this.lc_parts = []
		// if lc_parts are included in data, bring them in
		if (data.lc_parts) {
			for (let lcp of data.lc_parts) {
				this.lc_parts.push(new Lesson_Component_Part(lcp))
			}
		} else {
			// otherwise add a single blank part
			this.lc_parts.push(new Lesson_Component_Part())
		}

		sdp(this, data, 'lc_showing', false)
	}

	copy_for_save(flag) {
		let o
		if (flag == 'master') {
			// for a master, we save everything except lc_parts and lc_showing
			o = $.extend(true, {}, this)
			delete o.lc_showing

		} else {
			// otherwise all we save is lc_parts
			o = {}
		}

		o.lc_parts = [];
		for (let c of this.lc_parts) {
			o.lc_parts.push(c.copy_for_save(flag))
		}

		return o
	}

	is_empty() {
		// if there are no parts to the component, empty
		if (this.lc_parts.length == 0) return true
		for (let part of this.lc_parts) {
			// if at least one part has teacher_content, not empty
			if (!empty(part.lcp_teacher_content)) return false
		}
		// if we get here, empty
		return true
	}
}
window.Lesson_Component = Lesson_Component

class Lesson_Component_Part {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'lcp_title', '')
		sdp(this, data, 'lcp_teacher_content', '')

		sdp(this, data, 'lcp_showing', false)
		sdp(this, data, 'lcp_being_edited', false)
	}

	copy_for_save(flag) {
		let o = {}
		o.lcp_teacher_content = this.lcp_teacher_content
		o.lcp_title = this.lcp_title

		return o
	}

	has_content() {
		return !empty(this.lcp_teacher_content) || !empty(this.lcp_title)
	}
}
window.Lesson_Component_Part = Lesson_Component_Part

// Lesson components for type B
class Lesson_Component_B {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'lc_uuid', '')
		sdp(this, data, 'lc_content', '')
		sdp(this, data, 'lc_status', '', ['', 'skipped', 'complete'])
		sdp(this, data, 'lc_suggestions', [])	// suggestions will *not* be removed on save
		sdp(this, data, 'lc_model_suggestions', [])	// suggestions will *not* be removed on save
		sdp(this, data, 'lc_include_in_sparkl', false)
		sdp(this, data, 'lc_sparkl_model_response', '')
		sdp(this, data, 'lc_sparkl_exercise_id', '')
		sdp(this, data, 'lc_sparkl_query_ids', [])

		// to be removed on save
		sdp(this, data, 'lc_showing', false)
		sdp(this, data, 'lc_open_for_editing', false)
	}

	copy_for_save(flag) {
		let o
		if (flag == 'master') {
			// for a master, we (currently) save everything
			o = $.extend(true, {}, this)
		} else {
			o = {
				lc_uuid: this.lc_uuid,
				lc_content: this.lc_content,
				lc_status: this.lc_status,
				lc_suggestions: this.lc_suggestions,
				lc_model_suggestions: this.lc_model_suggestions,
				lc_include_in_sparkl: this.lc_include_in_sparkl,
				lc_sparkl_model_response: this.lc_sparkl_model_response,
				lc_sparkl_exercise_id: this.lc_sparkl_exercise_id,
				lc_sparkl_query_ids: this.lc_sparkl_query_ids,
			}
			// copy through variant 'A' properties too if we have them
			if (this.lc_parts?.length > 0) {
				o.lc_title = this.lc_title
				o.lc_weight = this.lc_weight
				o.lc_guidance = this.lc_guidance
				o.lc_default_content = this.lc_default_content
				o.lc_parts = this.lc_parts
			}
		}
		return o
	}

	is_empty() {
		return empty(this.lc_content)
	}
}
window.Lesson_Component_B = Lesson_Component_B

// global fns for determining which lesson types should be available when

// these are the rules for whether or not to show the "toggler" between OG lessons and LPC lessons
U.show_lesson_variant_switcher = function(lesson_id, course_code) {
	let sc = vapp.site_config

	// if enable_llm_lesson_plans is 'no', we never allow toggling (though we would use the LPC if the lesson was created using the LPC)
	if (sc.enable_llm_lesson_plans == 'no' || sc.enable_llm_lesson_plans == 'always') return false

	// if sc.alba_collections is set, limit the enhanced lesson plan tool to these collections
	if (sc.alba_collections && sc.alba_collections.length > 0) {
		if (!course_code || !sc.alba_collections.includes(course_code)) return false
	}

	// if the lesson has already been saved (i.e. the lesson_id isn't 0)...
	if (lesson_id != 0) {
		// ... never show the switcher if allow_llm_lesson_conversion is no
		if (sc.allow_llm_lesson_conversion == 'no') return false
	}

	// if we get to here, we're in a context where the switcher is allowed
	return true
}

// these are the rules for which version to use for new lessons
U.use_llm_for_new_lessons = function(course_code) {
	let sc = vapp.site_config

	// if enable_llm_lesson_plans is 'always', always use the llm for new lessons
	if (sc.enable_llm_lesson_plans == 'always') return true

	// if enable_llm_lesson_plans is 'no', never use the llm for new lessons
	if (sc.enable_llm_lesson_plans == 'no') return false
	
	// if sc.alba_collections is set, limit the enhanced lesson plan tool to these collections
	if (sc.alba_collections && sc.alba_collections.length > 0) {
		if (!course_code || !sc.alba_collections.includes(course_code)) return false
	}

	// if we get to here, use the store.state.lst setting to determine which version to use
	return vapp.$store.state.lst.use_llm_for_new_lessons 
}

// use this to determine which variant to show for a particular lesson
// use_enhanced_lesson_editor() { return this.lesson.lp_variant=='B' },


// note that lesson reports are stored in the db as resources
class Lesson_Report {
	constructor(data) {
		if (empty(data)) data = {}

		if (data.lesson_report_id == 'new') this.lesson_report_id = U.new_uuid()
		else if (!empty(data.lesson_report_id)) this.lesson_report_id = data.lesson_report_id
		else {
			console.warn('Lesson report created with no lesson_report_id')
			// this.lesson_report_id = U.new_uuid()
		}

		sdp(this, data, 'lesson_ids', [])
		sdp(this, data, 'collaborator_user_ids', [])
		sdp(this, data, 'reviewer_user_ids', [])
		sdp(this, data, 'owner_user_id', 0)
		// 'WLP' is used as the 'empty' value for report_title
		sdp(this, data, 'report_title', 'WLP')
		sdp(this, data, 'description', this.report_title)	// description is "shorthand" text for the report
		sdp(this, data, 'prework', {})	// indexed by user_id; values are strings
		sdp(this, data, 'feedback', {})	// indexed by user_id; values are strings
		
		this.reviews = {}	// indexed by user_id; values are {approved: true}
		if (data.reviews) for (let user_id in data.reviews) {
			let o = {}
			sdp(o, data.reviews[user_id], 'approved', false)
			this.reviews[user_id] = o
		}

		// convert dates from mysql to timestamp if necessary
		let tzo = new Date().getTimezoneOffset()*60*1000	// dates come in GMT; convert to local

		if (!isNaN(data.created_at*1)) this.created_at = data.created_at*1
		else this.created_at = (empty(data.created_at)) ? 0 : (date.parse(data.created_at, 'YYYY-MM-DD HH:mm:ss').getTime() - tzo) / 1000
		
		// these are removed before saving
		sdp(this, data, 'fully_loaded', (!empty(data.owner_user_id)))	// if we didn't get an owner_user_id, we didn't fully load the report
		sdp(this, data, 'viewed', false)
	}

	copy_for_save(flag) {
		let o = $.extend(true, {}, this)

		delete o.fully_loaded
		delete o.viewed
		delete o.created_at

		return o
	}
}
window.Lesson_Report = Lesson_Report


window.variant_b_lesson_master_data = {
	"lesson_id": 9,	// originally 14613
	"lesson_master_id": 9,
	"lesson_template_id": 0,
	"lesson_title": "ALBA Lesson Master",
	"lp_variant": "B",
	"lesson_plan": [
		{
			"lc_uuid": "overview",
			"lc_title": "Overview",
			"lc_title_plural": "Overview",
			"lc_icon": "fas fa-image-landscape",
			"lc_color": "pink",
			"lc_guidance": "<p>Provide an overview of the lesson.</p>",
			"lc_text_format": "paragraphs",
			"lc_suggestible_criteria": [],
			"lc_offer_to_include_in_sparkl": false,

		},
		{
			"lc_uuid": "topics",
			"lc_title": "Objectives / Targets",
			"lc_title_plural": "Objectives / Targets",
			"lc_icon": "fas fa-list-check",
			"lc_color": "pink",
			"lc_guidance": "<p>Specify the objectives and/or targets you will cover in this lesson.</p>",
			"lc_text_format": "list",
			"lc_suggestible_criteria": ["standards", "topics", "opener", "closer"],
			"lc_not_suggestible_msg": "To make Objectives / Targets suggestions, you must select one or more Standards, enter one or more objectives or targets yourself, or write an Opening Question or a Closing Question for the lesson.",
			"lc_gpt_messages_start": "\nassistant: What key concepts will you cover?\nuser: $format_for_gpt_message",
			"lc_gpt_messages_prompt": "\nuser: Please create a list of up to $suggestions_to_request key concepts students should learn in the lesson.",
			"lc_gpt_messages_prompt_alt": "\nuser: I will be covering the following key concepts in the lesson: $format_for_gpt_message.\nuser: Please create a list of up to $suggestions_to_request additional key concepts students should learn in the lesson.",
			"lc_offer_to_include_in_sparkl": true,
			"lc_sparkl_include_method": "student_instructions",
			"lc_sparkl_include_help_text": "The Objectives / Targets you have entered will be included in the Student Instructions of the activity.",

		},
		{
			"lc_uuid": "opener",
			"lc_title": "Opening Question",
			"lc_title_plural": "Opening Questions",
			"lc_icon": "fas fa-circle-question",
			"lc_color": "pink",
			"lc_guidance": "<p>Frame your lesson around an opening question for your students to answer.</p>",
			"lc_text_format": "line",
			"lc_suggestible_criteria": ["standards", "topics", "closer"],
			"lc_not_suggestible_msg": "To make Opening Question suggestions, you must select one or more Standards, enter one or more Objectives / Targets, or write a Closing Question for the lesson.",
			"lc_gpt_messages_start": "\nassistant: What is your opening question for the lesson?\nuser: $format_for_gpt_message",
			"lc_gpt_messages_prompt": "\nuser: Suggest five different questions, making sure to reference real-world examples, to ask my students at the start of the lesson.",
			"lc_offer_to_include_in_sparkl": true,
			"lc_sparkl_include_method": "cr_query",
			"lc_sparkl_include_help_text": "A constructed response query with your question text will be added. If you specify a model response, students will see the model after entering their answer, and $sparkl_app_name-BOT will compare each student’s answer to the model to assist with grading.",
			"lc_gpt_model_prompt": "\nuser: Compose a model response, limited to 80 words or fewer, that a student $grade_phrase might give to the following question: $format_for_gpt_message"

		},
		{
			"lc_uuid": "review",
			"lc_title": "Prior Knowledge",
			"lc_title_plural": "Prior Knowledge",
			"lc_icon": "fas fa-clipboard-list",
			"lc_color": "pink",
			"lc_guidance": "<p>What prior knowledge will students need prior to the lesson?</p>",
			"lc_text_format": "list",
			"lc_suggestible_criteria": ["standards", "topics", "review", "opener", "closer"],
			"lc_not_suggestible_msg": "To make Prior Knowledge suggestions, you must select one or more Standards, enter one or more Lessons / Objectives, enter one or more Prior Knowledge statements yourself, or write an Opening Question or a Closing Question for the lesson.",
			"lc_gpt_messages_prompt": "\nuser: Create a list of up to $suggestions_to_request key concepts that students must already understand before starting the lesson.",
			"lc_gpt_messages_prompt_alt": "\nuser: I have determined that students must already understand the following key concepts before starting the lesson: $format_for_gpt_message.\nuser: Please create a list of up to $suggestions_to_request additional key concepts students must already understand before starting the lesson.",
			"lc_offer_to_include_in_sparkl": true,
			"lc_sparkl_include_method": "interactive_reading",
			"lc_sparkl_include_help_text": "The Prior Knowledge statements you have entered will be included as an Interactive Reading exercise.",

		},
		{
			"lc_uuid": "ido",
			"lc_title": "Teacher Instruction",
			"lc_title_plural": "Teacher Instruction Ideas",
			"lc_icon": "fas fa-person-chalkboard",
			"lc_color": "blue",
			"lc_guidance": "<p>Plan the direct instruction you will do in the lesson.</p>",
			"lc_text_format": "paragraphs",
			"lc_suggestible_criteria": ["standards", "topics", "opener", "ido", "closer"],
			"lc_not_suggestible_msg": "To make Teacher Instruction suggestions, you must select one or more Standards, enter one or more Objectives / Targets, start writing Teacher Instruction plans yourself, or write an Opening Question or a Closing Question for the lesson.",
			"lc_gpt_messages_prompt": "\nuser: Suggest up to $suggestions_to_request ideas for direct teacher instruction in the lesson.",
			"lc_gpt_messages_prompt_alt": "\nuser: I will be doing the following direct teacher instruction in the lesson: $format_for_gpt_message.\nuser: Please suggest up to $suggestions_to_request additional ideas for direct teacher instruction.",
			"lc_offer_to_include_in_sparkl": true,
			"lc_sparkl_include_method": "interactive_reading",
			"lc_sparkl_placeholder_text": "<p><i>Replace this text with any passage or excerpt that would be helpful for students to engage with before, during, or after your direct instruction about the topic(s) of this activity. Use the auto-blanks option or the toolbar above to choose specific words for students to interact with to preserve their memory trace of this new information. See the activity gallery for ideas and options <a href=\"https://docs.google.com/presentation/d/1PVtSrqmrmZqiEiIZx6585pD9340ap0Qt7IxkBr1j-70/edit#slide=id.g25af3f611c6_0_0\" target=\"_blank\">here.</i></p>",
			"lc_sparkl_include_help_text": "A placeholder Interactive Reading exercise will be added to the $sparkl_app_name activity."

		},
		{
			"lc_uuid": "wedo",
			"lc_title": "Guided Practice",
			"lc_title_plural": "Guided Practice Ideas",
			"lc_icon": "fas fa-person-walking",
			"lc_color": "blue",
			"lc_guidance": "<p>Plan the guided practice you will do with your students.</p>",
			"lc_text_format": "paragraphs",
			"lc_suggestible_criteria": ["standards", "topics", "opener", "wedo", "closer"],
			"lc_not_suggestible_msg": "To make Guided Practice suggestions, you must select one or more Standards, enter one or more Objectives / Targets, start writing Guided Practice plans yourself, or write an Opening Question or a Closing Question for the lesson.",
			"lc_gpt_messages_prompt": "\nuser: Suggest up to $suggestions_to_request ideas for guided practice (“we do”) with my students in the lesson.",
			"lc_gpt_messages_prompt_alt": "\nuser: I will be doing the following guided practice with my students in the lesson: $format_for_gpt_message.\nuser: Please suggest up to $suggestions_to_request additional ideas for guided practice (“we do”) with my students.",
			"lc_offer_to_include_in_sparkl": true,
			"lc_sparkl_include_method": "scaffolded_response",
			"lc_sparkl_placeholder_text": "<p><i>Replace this text with a prompt or question for students to think about related to the topic(s) of this activity. Use the space below the prompt to construct a model response to your prompt or question and choose specific words for students to interact with to check their understanding and reinforce the main take aways for the topic(s) of this activity. See the activity gallery for ideas and options <a href=\"https://docs.google.com/presentation/d/1PVtSrqmrmZqiEiIZx6585pD9340ap0Qt7IxkBr1j-70/edit#slide=id.g25af3f611c6_0_144\" target=\"_blank\">here.</i></p>",
			"lc_sparkl_include_help_text": "A placeholder Scaffolded Response exercise will be added to the $sparkl_app_name activity."

		},
		{
			"lc_uuid": "youdo",
			"lc_title": "Independent Practice",
			"lc_title_plural": "Independent Practice Ideas",
			"lc_icon": "fas fa-person-running",
			"lc_color": "blue",
			"lc_guidance": "<p>Plan the independent practice you will have your students do.</p>",
			"lc_text_format": "paragraphs",
			"lc_suggestible_criteria": ["standards", "topics", "opener", "youdo", "closer"],
			"lc_not_suggestible_msg": "To make Independent Practice suggestions, you must select one or more Standards, enter one or more Objectives / Targets, start writing Independent Practice plans yourself, or write an Opening Question or a Closing Question for the lesson.",
			"lc_gpt_messages_prompt": "\nuser: Suggest up to $suggestions_to_request ideas for student independent practice activities in the lesson.",
			"lc_gpt_messages_prompt_alt": "\nuser: I will be asking my students to do the following independent practice activities in the lesson: $format_for_gpt_message.\nuser: Please suggest up to $suggestions_to_request additional ideas for student independent practice activities.",
			"lc_offer_to_include_in_sparkl": true,
			"lc_sparkl_include_method": "basic_content",
			"lc_sparkl_placeholder_text": "<p><i>Replace this text with an image to label, a table to fill in, a list of steps with blanks, or any other content that can give learners the chance to demonstrate what they know about the topic(s) and get immediate feedback. See the activity gallery for ideas and options <a href=\"https://docs.google.com/presentation/d/1PVtSrqmrmZqiEiIZx6585pD9340ap0Qt7IxkBr1j-70/edit#slide=id.g25c43433b1f_0_0\" target=\"_blank\">here.</i></p>",
			"lc_sparkl_include_help_text": "A placeholder Freeform exercise will be added to the $sparkl_app_name activity.",

		},
		{
			"lc_uuid": "vocabulary",
			"lc_title": "Vocabulary Review",
			"lc_title_plural": "Vocabulary Review Terms",
			"lc_icon": "fas fa-book",
			"lc_color": "green",
			"lc_guidance": "<p>Make a list of vocabulary words for the lesson, along with definitions for each word.</p>",
			"lc_text_format": "list_plain",		// this will make it so we don't process markdown
			"lc_suggestible_criteria": ["standards", "topics", "opener"],
			"lc_not_suggestible_msg": "To make Vocabulary suggestions, you must select one or more Standards, enter one or more Objectives / Targets, or write an Opening Question for the lesson.",
			"lc_gpt_messages_prompt": "\nuser: Suggest up to 12 vocabulary words I should review with my students for the lesson, along with a definition for each word.",
			"lc_offer_to_include_in_sparkl": true,
			"lc_sparkl_include_method": "flashcards",
			"lc_sparkl_include_help_text": "A Flashcard exercise will be added with your specified Vocabulary Review words and definitions.",

		},
		{
			"lc_uuid": "closer",
			"lc_title": "Closing Question",
			"lc_title_plural": "Closing Questions",
			"lc_icon": "fas fa-circle-question",
			"lc_color": "green",
			"lc_guidance": "<p>Write a &ldquo;capstone&rdquo; Closing Question for your students to answer at the end of the lesson.</p>",
			"lc_text_format": "line",
			"lc_suggestible_criteria": ["standards", "topics", "opener"],
			"lc_not_suggestible_msg": "To make Closing Question suggestions, you must select one or more Standards, enter one or more Objectives / Targets, or write an Opening Question for the lesson.",
			"lc_gpt_messages_start": "\nassistant: What is your closing question for the lesson?\nuser: $format_for_gpt_message",
			"lc_gpt_messages_prompt": "\nuser: Suggest five different “capstone” questions to ask my students at the end of the lesson.",
			"lc_offer_to_include_in_sparkl": true,
			"lc_sparkl_include_method": "cr_query",
			"lc_sparkl_include_help_text": "A constructed response query with your question text will be added. If you specify a model response, students will see the model after entering their answer, and $sparkl_app_name-BOT will compare each student’s answer to the model to assist with grading.",
			"lc_gpt_model_prompt": "\nuser: Compose a model response, limited to 80 words or fewer, that a student $grade_phrase might give to the following question: $format_for_gpt_message"

		},
		{
			"lc_uuid": "miscon",
			"lc_title": "Common Misconceptions",
			"lc_title_plural": "Common Misconceptions",
			"lc_icon": "fas fa-triangle-exclamation",
			"lc_color": "green",
			"lc_guidance": "<p>What common misconceptions might your students have about the lesson content?</p>",
			"lc_text_format": "list",
			"lc_suggestible_criteria": ["standards", "topics", "opener", "closer"],
			"lc_not_suggestible_msg": "To make Misconception suggestions, you must select one or more Standards, enter one or more Objectives / Targets, or write an Opening Question or a Closing Question for the lesson.",
			"lc_gpt_messages_prompt": "\nuser: Create a list of up to $suggestions_to_request common misconceptions that students may have about the lesson content.",
			"lc_offer_to_include_in_sparkl": false,

		},
		{
			"lc_uuid": "remediation",
			"lc_title": "Ideas for Intervention",
			"lc_title_plural": "Ideas for Intervention",
			"lc_icon": "fas fa-toolbox",	// bandage / turtle
			"lc_color": "lime",
			"lc_guidance": "<p>How can you accommodate students who need additional intervention to help with learning?</p>",
			"lc_text_format": "list",
			"lc_suggestible_criteria": ["standards", "topics", "opener", "closer"],
			"lc_not_suggestible_msg": "To make Intervention suggestions, you must select one or more Standards, enter one or more Objectives / Targets, or write an Opening Question or a Closing Question for the lesson.",
			"lc_gpt_messages_prompt": "\nuser: Suggest up to $suggestions_to_request ideas for helping students who need additional remediation.",
			"lc_offer_to_include_in_sparkl": false,

		},
		{
			"lc_uuid": "acceleration",
			"lc_title": "Ideas for Acceleration",
			"lc_title_plural": "Ideas for Acceleration",
			"lc_icon": "fas fa-rabbit-running",
			"lc_color": "lime",
			"lc_guidance": "<p>How can you challenge students who demonstrated mastery of the lesson concepts?</p>",
			"lc_text_format": "list",
			"lc_suggestible_criteria": ["standards", "topics", "opener", "closer"],
			"lc_not_suggestible_msg": "To make Acceleration suggestions, you must select one or more Standards, enter one or more Objectives / Targets, or write an Opening Question or a Closing Question for the lesson.",
			"lc_gpt_messages_prompt": "\nuser: Suggest up to $suggestions_to_request ideas for accelerated students who would like to go beyond the planned lesson materials.",
			"lc_offer_to_include_in_sparkl": false,

		},
		{
			"lc_uuid": "assessment",
			"lc_title": "Assessment Strategies",
			"lc_title_plural": "Assessment Strategies",
			"lc_icon": "fas fa-circle-question",
			"lc_color": "lime",
			"lc_guidance": "<p>How will you assess student learning for the lesson’s content?</p>",
			"lc_text_format": "paragraphs",
			"lc_suggestible_criteria": [],
			"lc_offer_to_include_in_sparkl": false,

		},
		{
			"lc_uuid": "teacherprep",
			"lc_title": "Background / Preparation",
			"lc_title_plural": "Background / Preparation",
			"lc_icon": "fas fa-compass",
			"lc_color": "purple",
			"lc_guidance": "<p>Describe any necessary Background and Preparation teachers need before conducting this lesson.</p>",
			"lc_text_format": "paragraphs",
			"lc_suggestible_criteria": [],
			"lc_offer_to_include_in_sparkl": false,

		},
		{
			"lc_uuid": "materials",
			"lc_title": "Materials & Resources",
			"lc_title_plural": "Materials & Resources",
			"lc_icon": "fas fa-shapes",
			"lc_color": "purple",
			"lc_guidance": "<p>List necessary non-digital materials for the lesson, and link to digital materials below.</p>",
			"lc_text_format": "list",
			"lc_suggestible_criteria": [],
			"lc_offer_to_include_in_sparkl": false,

		},
	],
	// specify things here we want to be able to overwrite in instances for standards; but we don't want it to be part of the lesson plan
	"standards_component": {
		"lc_uuid": "standards",
		"lc_title": "Learning Standards",
		"lc_title_plural": "Learning Standards",
		"lc_icon": "fas fa-map",
		"lc_color": "amber",
		"lc_guidance": "<p>Choose the learning standard(s) you will cover in this lesson.</p>",
		"lc_offer_to_include_in_sparkl": false,
	},
}

