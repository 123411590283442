<!-- Copyright 2024, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="1100px" content-class="k-alba-dialog" overlay-opacity="0.9" persistent scrollable>
		<v-card class="k-alba-outer">
			<v-card-title class="ma-0 pa-2" style="border-bottom:1px solid #999">
				<b>AI Lesson Builder Assistant (ALBA)</b>
				<v-spacer/>
				<!-- <v-btn small class="k-tight-btn mr-3" color="secondary" @click="restore_settings"><v-icon small class="mr-2">fas fa-undo</v-icon>Restore Previous Settings</v-btn> -->
				<v-hover v-slot:default="{hover}"><div :style="hover?'opacity:1':'opacity:0'"><v-btn small class="mr-3" color="#ccc" @click="show_gpt_test=!show_gpt_test">Toggle GPT Test Prompt</v-btn></div></v-hover>
				<v-btn small class="k-tight-btn mr-3" color="secondary" @click="clear_settings"><v-icon small class="mr-2">fas fa-undo</v-icon>Start Over</v-btn>
				<v-btn color="primary" @click="$emit('dialog_close')"><v-icon small class="mr-2">fas fa-xmark</v-icon>Done</v-btn>
			</v-card-title>
			<v-card-text class="pa-0 ma-0" style="font-size:14px; line-height:18px;"><div class="k-alba-outer-outer">
				<div class="k-alba-menu-stages">
					<!-- <div class="k-alba-menu-stage-outer" v-for="(stage, stage_label) in components">{{stage.menu_label}}</div> -->

					<div v-for="(stage, stage_label) in components" :key="stage_label"><v-hover v-slot:default="{hover}"><div class="k-alba-menu-stage-outer" :class="menu_stage_css(stage_label)" :style="hover?'opacity:1':''">
						<div class="k-alba-menu-stage" :class="`${stage.menu_color} lighten-4`" @click="go_to_stage(stage_label)">
							<v-icon style="width:20px" :color="`${stage.menu_color} darken-4`" small class="mr-2">{{stage.menu_icon}}</v-icon>
							<div class="k-alba-menu-stage-label">{{components[stage_label].menu_label}}</div>
							<v-spacer/>
							<v-icon>{{menu_stage_icon(stage_label)}}</v-icon>
						</div>
					</div></v-hover></div>
				</div>

				<div class="k-alba-content"><div class="k-alba-content-inner">

					<!-- <div class="k-alba-content-section">
						<h3>Target students</h3>
					</div> -->

					<!-- <div class="k-alba-content-section">
						<h3>Prior lesson</h3>
					</div> -->

					<div data-stage="standards" class="k-alba-content-section" :class="section_stage_css('standards')">
						<div class="k-alba-section-header">
							<v-icon class="k-alba-section-header-icon" @click="toggle_stage('standards')" :color="`${components.standards.menu_color} darken-4`">{{components.standards.menu_icon}}</v-icon>
							<h3 @click="toggle_stage('standards')">{{components.standards.menu_label}}</h3>
							<v-spacer/>
							<div class="k-alba-section-instructions">Choose the learning standard(s) you will cover in this lesson.</div>
							<div class="k-alba-section-summary text-center" :class="`${components.standards.menu_color} lighten-5`">
								<div v-if="stage_complete('standards')" class="d-flex justify-center"><CASEItemBtn v-for="(s) in standards" :key="s.identifier" :item="s" btn_color="pink darken-4" @click="choose_standards" small :outer_class="'mr-1'" /></div>
								<div v-else class="k-alba-section-summary-empty">No Standards selected</div>
							</div>
						</div>
						<v-expand-transition><div class="k-alba-section-editor" v-show="stage_open_for_editing('standards')">
							<div v-for="(s, index) in standards" :key="s.identifier"><v-hover v-slot:default="{hover}"><div class="k-alba-content-item" :class="hover?'grey lighten-5':''">
								<v-btn small icon outlined class="mt-1 mr-2 ml-0" @click="choose_standards"><v-icon color="pink darken-4" small>fas fa-map</v-icon></v-btn>
								<p v-if="s.humanCodingScheme"><b v-html="s.humanCodingScheme" class="mr-2"></b></p>
								<div style="flex:1 1 auto" v-html="U.marked_latex(s.fullStatement)"></div>
								<v-btn small icon outlined class="mt-1 mr-1 ml-2" @click="remove_standard(index)"><v-icon color="pink darken-4" small>fas fa-xmark</v-icon></v-btn>
							</div></v-hover></div>
							<div class="k-alba-section-buttons">
								<v-btn class="mr-2" small color="pink darken-4" dark @click="choose_standards"><v-icon x-small class="mr-1">fas fa-plus</v-icon>Add Standard</v-btn>
								<v-btn small color="#444" dark @click="stage_done_clicked('standards')"><v-icon small class="mr-2">fas fa-check</v-icon> Done</v-btn>
							</div>
						</div></v-expand-transition>
					</div>

					<!-- TOPICS -->
					<div data-stage="topics" class="k-alba-content-section" :class="section_stage_css('topics')">
						<div class="k-alba-section-header">
							<v-icon class="k-alba-section-header-icon" @click="toggle_stage('topics')" :color="`${components.topics.menu_color} darken-4`">{{components.topics.menu_icon}}</v-icon>
							<h3 @click="toggle_stage('topics')">{{components.topics.menu_label}}</h3>
							<v-spacer/>
							<div v-if="standards.length>0" class="k-alba-section-instructions">Break the standard{{standards.length>1?'s':''}} down into Key Concepts you will cover in this lesson.</div>
							<div v-else class="k-alba-section-instructions">Specify the Key Concepts you will this lesson.</div>
							<div class="k-alba-section-summary" :class="`${components.topics.menu_color} lighten-5`">
								<div v-if="stage_complete('topics')" v-html="section_summary('topics')"></div>
								<div v-else class="k-alba-section-summary-empty">No Key Concepts specified</div>
							</div>
						</div>

						<v-expand-transition><div class="k-alba-section-editor" v-show="stage_open_for_editing('topics')">
							<div class="mt-2"><froala-wrapper ref="froala_topics" :config="editor_config()" :parameter_object="components.topics" parameter="content" v-model="components.topics.content" /></div>
							<div v-for="(option, index) in components.topics.options" :key="index"><v-hover v-slot:default="{hover}"><div class="k-alba-content-item my-1 py-1" :class="hover?'grey lighten-5':''">
								<v-icon color="indigo darken-4" small style="margin-top:2px">fas fa-robot</v-icon>
								<div style="flex: 1 1 auto; padding:0 8px 0px 8px" v-html="option"></div>
								<v-btn small icon class="mr-1 ml-1" @click="remove_option('topics', index)"><v-icon color="red darken-4">fas fa-circle-xmark</v-icon></v-btn>
								<v-btn small icon class="mr-1 ml-1" @click="add_option('topics', index)"><v-icon color="green darken-4">fas fa-circle-check</v-icon></v-btn>
							</div></v-hover></div>
							<div class="k-alba-section-buttons">
								<v-btn small class="k-tight-btn mr-2" color="pink darken-4" dark :style="!stage_suggestible('topics')?'opacity:0.5':''" @click="suggest_topics"><v-icon x-small class="mr-1">fas fa-robot</v-icon>Suggest{{components.topics.content?' More':''}} Key Concepts</v-btn>
								<v-btn v-if="components.topics.options.length>0" small class="k-tight-btn mr-2" color="#ccc" @click="clear_options('topics')"><v-icon x-small class="mr-1">fas fa-circle-xmark</v-icon>Clear Suggestions</v-btn>
								<v-btn small color="#444" dark @click="stage_done_clicked('topics')"><v-icon small class="mr-2">fas fa-check</v-icon> Done</v-btn>
							</div>
						</div></v-expand-transition>
					</div>

					<!-- OPENING QUESTION -->
					<div data-stage="opener" class="k-alba-content-section" :class="section_stage_css('opener')">
						<div class="k-alba-section-header">
							<v-icon class="k-alba-section-header-icon" @click="toggle_stage('opener')" :color="`${components.opener.menu_color} darken-4`">{{components.opener.menu_icon}}</v-icon>
							<h3 @click="toggle_stage('opener')">{{components.opener.menu_label}}</h3>
							<v-spacer/>
							<div class="k-alba-section-instructions">Frame your lesson around an opening question for your students to answer.</div>
							<div class="k-alba-section-summary" :class="`${components.opener.menu_color} lighten-5`">
								<div v-if="stage_complete('opener')" v-html="section_summary('opener')"></div>
								<div v-else class="k-alba-section-summary-empty">No Opening Question specified</div>
							</div>
						</div>
						<v-expand-transition><div class="k-alba-section-editor" v-show="stage_open_for_editing('opener')">
							<div class="mt-2"><froala-wrapper ref="froala_opener" :config="editor_config()" :parameter_object="components.opener" parameter="content" v-model="components.opener.content" /></div>
							<div v-for="(option, index) in components.opener.options" :key="index"><v-hover v-slot:default="{hover}"><div class="k-alba-content-item my-1 py-1" :class="hover?'grey lighten-5':''">
								<v-icon color="indigo darken-4" small style="margin-top:2px">fas fa-robot</v-icon>
								<div style="flex: 1 1 auto; padding:0 8px 0px 8px" v-html="option"></div>
								<v-btn small icon class="mr-1 ml-1" @click="remove_option('opener', index)"><v-icon color="red darken-4">fas fa-circle-xmark</v-icon></v-btn>
								<v-btn small icon class="mr-1 ml-1" @click="add_option('opener', index)"><v-icon color="green darken-4">fas fa-circle-check</v-icon></v-btn>
							</div></v-hover></div>

							<div class="k-alba-section-buttons">
								<v-btn small class="k-tight-btn mr-2" color="indigo darken-4" dark :style="!stage_suggestible('opener')?'opacity:0.5':''" @click="suggest_opening_question"><v-icon x-small class="mr-1">fas fa-robot</v-icon>Suggest{{components.opener.options.length>0?' More':''}} Questions</v-btn>
								<v-btn v-if="components.opener.options.length>0" small class="k-tight-btn mr-2" color="#ccc" @click="clear_options('opener')"><v-icon x-small class="mr-1">fas fa-circle-xmark</v-icon>Clear Suggestions</v-btn>
								<v-btn small color="#444" dark @click="stage_done_clicked('opener')"><v-icon small class="mr-2">fas fa-check</v-icon> Done</v-btn>
							</div>
						</div></v-expand-transition>
					</div>

					<!-- REVIEW -->
					<div data-stage="review" class="k-alba-content-section" :class="section_stage_css('review')">
						<div class="k-alba-section-header">
							<v-icon class="k-alba-section-header-icon" @click="toggle_stage('review')" :color="`${components.review.menu_color} darken-4`">{{components.review.menu_icon}}</v-icon>
							<h3 @click="toggle_stage('review')">{{components.review.menu_label}}</h3>
							<v-spacer/>
							<div class="k-alba-section-instructions">What previous topics will you need to review before you start?</div>
							<div class="k-alba-section-summary" :class="`${components.review.menu_color} lighten-5`">
								<div v-if="stage_complete('review')" v-html="section_summary('review')"></div>
								<div v-else class="k-alba-section-summary-empty">No Review topics specified</div>
							</div>
						</div>

						<v-expand-transition><div class="k-alba-section-editor" v-show="stage_open_for_editing('review')">
							<div class="mt-2"><froala-wrapper ref="froala_review" :config="editor_config()" :parameter_object="components.review" parameter="content" v-model="components.review.content" /></div>
							<div v-for="(option, index) in components.review.options" :key="index"><v-hover v-slot:default="{hover}"><div class="k-alba-content-item my-1 py-1" :class="hover?'grey lighten-5':''">
								<v-icon color="indigo darken-4" small style="margin-top:2px">fas fa-robot</v-icon>
								<div style="flex: 1 1 auto; padding:0 8px 0px 8px" v-html="option"></div>
								<v-btn small icon class="mr-1 ml-1" @click="remove_option('review', index)"><v-icon color="red darken-4">fas fa-circle-xmark</v-icon></v-btn>
								<v-btn small icon class="mr-1 ml-1" @click="add_option('review', index)"><v-icon color="green darken-4">fas fa-circle-check</v-icon></v-btn>
							</div></v-hover></div>
							<div class="k-alba-section-buttons">
								<v-btn small class="k-tight-btn mr-2" color="indigo darken-4" dark :style="!stage_suggestible('review')?'opacity:0.5':''" @click="suggest_review"><v-icon x-small class="mr-1">fas fa-robot</v-icon>Suggest{{components.review.content?' More':''}} Review Topics</v-btn>
								<v-btn v-if="components.review.options.length>0" small class="k-tight-btn mr-2" color="#ccc" @click="clear_options('review')"><v-icon x-small class="mr-1">fas fa-circle-xmark</v-icon>Clear Suggestions</v-btn>
								<v-btn small color="#444" dark @click="stage_done_clicked('review')"><v-icon small class="mr-2">fas fa-check</v-icon> Done</v-btn>
							</div>
						</div></v-expand-transition>
					</div>

					<!-- IDO (TEACHER INSTRUCTION) -->
					<div data-stage="review" class="k-alba-content-section" :class="section_stage_css('ido')">
						<div class="k-alba-section-header">
							<v-icon class="k-alba-section-header-icon" @click="toggle_stage('ido')" :color="`${components.ido.menu_color} darken-4`">{{components.ido.menu_icon}}</v-icon>
							<h3 @click="toggle_stage('ido')">{{components.ido.menu_label}}</h3>
							<v-spacer/>
							<div class="k-alba-section-instructions">Plan the direct instruction you will do in the lesson.</div>
							<div class="k-alba-section-summary" :class="`${components.ido.menu_color} lighten-5`">
								<div v-if="stage_complete('ido')" v-html="section_summary('ido')"></div>
								<div v-else class="k-alba-section-summary-empty">No Teacher Instruction specified</div>
							</div>
						</div>

						<v-expand-transition><div class="k-alba-section-editor" v-show="stage_open_for_editing('ido')">
							<div class="mt-2"><froala-wrapper ref="froala_ido" :config="editor_config()" :parameter_object="components.ido" parameter="content" v-model="components.ido.content" /></div>
							<div v-for="(option, index) in components.ido.options" :key="index"><v-hover v-slot:default="{hover}"><div class="k-alba-content-item my-1 py-1" :class="hover?'grey lighten-5':''">
								<v-icon color="indigo darken-4" small style="margin-top:2px">fas fa-robot</v-icon>
								<div style="flex: 1 1 auto; padding:0 8px 0px 8px" v-html="option"></div>
								<v-btn small icon class="mr-1 ml-1" @click="remove_option('ido', index)"><v-icon color="red darken-4">fas fa-circle-xmark</v-icon></v-btn>
								<v-btn small icon class="mr-1 ml-1" @click="add_option('ido', index)"><v-icon color="green darken-4">fas fa-circle-check</v-icon></v-btn>
							</div></v-hover></div>
							<div class="k-alba-section-buttons">
								<v-btn small class="k-tight-btn mr-2" color="indigo darken-4" dark :style="!stage_suggestible('ido')?'opacity:0.5':''" @click="suggest_ido"><v-icon x-small class="mr-1">fas fa-robot</v-icon>Suggest{{components.ido.content?' More':''}} Teacher Instruction Ideas</v-btn>
								<v-btn v-if="components.ido.options.length>0" small class="k-tight-btn mr-2" color="#ccc" @click="clear_options('ido')"><v-icon x-small class="mr-1">fas fa-circle-xmark</v-icon>Clear Suggestions</v-btn>
								<v-btn small color="#444" dark @click="stage_done_clicked('ido')"><v-icon small class="mr-2">fas fa-check</v-icon> Done</v-btn>
							</div>
						</div></v-expand-transition>
					</div>

					<!-- WEDO (GUIDED PRACTICE) -->
					<div data-stage="review" class="k-alba-content-section" :class="section_stage_css('wedo')">
						<div class="k-alba-section-header">
							<v-icon class="k-alba-section-header-icon" @click="toggle_stage('wedo')" :color="`${components.wedo.menu_color} darken-4`">{{components.wedo.menu_icon}}</v-icon>
							<h3 @click="toggle_stage('wedo')">{{components.wedo.menu_label}}</h3>
							<v-spacer/>
							<div class="k-alba-section-instructions">Plan the guided practice you will do with your students.</div>
							<div class="k-alba-section-summary" :class="`${components.wedo.menu_color} lighten-5`">
								<div v-if="stage_complete('wedo')" v-html="section_summary('wedo')"></div>
								<div v-else class="k-alba-section-summary-empty">No Guided Practice specified</div>
							</div>
						</div>

						<v-expand-transition><div class="k-alba-section-editor" v-show="stage_open_for_editing('wedo')">
							<div class="mt-2"><froala-wrapper ref="froala_wedo" :config="editor_config()" :parameter_object="components.wedo" parameter="content" v-model="components.wedo.content" /></div>
							<div v-for="(option, index) in components.wedo.options" :key="index"><v-hover v-slot:default="{hover}"><div class="k-alba-content-item my-1 py-1" :class="hover?'grey lighten-5':''">
								<v-icon color="indigo darken-4" small style="margin-top:2px">fas fa-robot</v-icon>
								<div style="flex: 1 1 auto; padding:0 8px 0px 8px" v-html="option"></div>
								<v-btn small icon class="mr-1 ml-1" @click="remove_option('wedo', index)"><v-icon color="red darken-4">fas fa-circle-xmark</v-icon></v-btn>
								<v-btn small icon class="mr-1 ml-1" @click="add_option('wedo', index)"><v-icon color="green darken-4">fas fa-circle-check</v-icon></v-btn>
							</div></v-hover></div>
							<div class="k-alba-section-buttons">
								<v-btn small class="k-tight-btn mr-2" color="indigo darken-4" dark :style="!stage_suggestible('wedo')?'opacity:0.5':''" @click="suggest_wedo"><v-icon x-small class="mr-1">fas fa-robot</v-icon>Suggest{{components.wedo.content?' More':''}} Guided Practice Ideas</v-btn>
								<v-btn v-if="components.wedo.options.length>0" small class="k-tight-btn mr-2" color="#ccc" @click="clear_options('wedo')"><v-icon x-small class="mr-1">fas fa-circle-xmark</v-icon>Clear Suggestions</v-btn>
								<v-btn small color="#444" dark @click="stage_done_clicked('wedo')"><v-icon small class="mr-2">fas fa-check</v-icon> Done</v-btn>
							</div>
						</div></v-expand-transition>
					</div>

					<!-- YOUDO (INDEPENDENT PRACTICE) -->
					<div data-stage="review" class="k-alba-content-section" :class="section_stage_css('youdo')">
						<div class="k-alba-section-header">
							<v-icon class="k-alba-section-header-icon" @click="toggle_stage('youdo')" :color="`${components.youdo.menu_color} darken-4`">{{components.youdo.menu_icon}}</v-icon>
							<h3 @click="toggle_stage('youdo')">{{components.youdo.menu_label}}</h3>
							<v-spacer/>
							<div class="k-alba-section-instructions">Plan the independent practice you will have your students do.</div>
							<div class="k-alba-section-summary" :class="`${components.youdo.menu_color} lighten-5`">
								<div v-if="stage_complete('youdo')" v-html="section_summary('youdo')"></div>
								<div v-else class="k-alba-section-summary-empty">No Independent Practice specified</div>
							</div>
						</div>

						<v-expand-transition><div class="k-alba-section-editor" v-show="stage_open_for_editing('youdo')">
							<div class="mt-2"><froala-wrapper ref="froala_youdo" :config="editor_config()" :parameter_object="components.youdo" parameter="content" v-model="components.youdo.content" /></div>
							<div v-for="(option, index) in components.youdo.options" :key="index"><v-hover v-slot:default="{hover}"><div class="k-alba-content-item my-1 py-1" :class="hover?'grey lighten-5':''">
								<v-icon color="indigo darken-4" small style="margin-top:2px">fas fa-robot</v-icon>
								<div style="flex: 1 1 auto; padding:0 8px 0px 8px" v-html="option"></div>
								<v-btn small icon class="mr-1 ml-1" @click="remove_option('youdo', index)"><v-icon color="red darken-4">fas fa-circle-xmark</v-icon></v-btn>
								<v-btn small icon class="mr-1 ml-1" @click="add_option('youdo', index)"><v-icon color="green darken-4">fas fa-circle-check</v-icon></v-btn>
							</div></v-hover></div>
							<div class="k-alba-section-buttons">
								<v-btn small class="k-tight-btn mr-2" color="indigo darken-4" dark :style="!stage_suggestible('youdo')?'opacity:0.5':''" @click="suggest_youdo"><v-icon x-small class="mr-1">fas fa-robot</v-icon>Suggest{{components.youdo.content?' More':''}} Independent Practice Ideas</v-btn>
								<v-btn v-if="components.youdo.options.length>0" small class="k-tight-btn mr-2" color="#ccc" @click="clear_options('youdo')"><v-icon x-small class="mr-1">fas fa-circle-xmark</v-icon>Clear Suggestions</v-btn>
								<v-btn small color="#444" dark @click="stage_done_clicked('youdo')"><v-icon small class="mr-2">fas fa-check</v-icon> Done</v-btn>
							</div>
						</div></v-expand-transition>
					</div>

					<!-- CLOSING QUESTION -->
					<div data-stage="closer" class="k-alba-content-section" :class="section_stage_css('closer')">
						<div class="k-alba-section-header">
							<v-icon class="k-alba-section-header-icon" @click="toggle_stage('closer')" :color="`${components.closer.menu_color} darken-4`">{{components.closer.menu_icon}}</v-icon>
							<h3 @click="toggle_stage('closer')">{{components.closer.menu_label}}</h3>
							<v-spacer/>
							<div class="k-alba-section-instructions">Write a “capstone” question for your students to answer at the end of the lesson.</div>
							<div class="k-alba-section-summary" :class="`${components.closer.menu_color} lighten-5`">
								<div v-if="stage_complete('closer')" v-html="section_summary('closer')"></div>
								<div v-else class="k-alba-section-summary-empty">No Closing Question specified</div>
							</div>
						</div>
						<v-expand-transition><div class="k-alba-section-editor" v-show="stage_open_for_editing('closer')">
							<div class="mt-2"><froala-wrapper ref="froala_closer" :config="editor_config()" :parameter_object="components.closer" parameter="content" v-model="components.closer.content" /></div>
							<div v-for="(option, index) in components.closer.options" :key="index"><v-hover v-slot:default="{hover}"><div class="k-alba-content-item my-1 py-1" :class="hover?'grey lighten-5':''">
								<v-icon color="indigo darken-4" small style="margin-top:2px">fas fa-robot</v-icon>
								<div style="flex: 1 1 auto; padding:0 8px 0px 8px" v-html="option"></div>
								<v-btn small icon class="mr-1 ml-1" @click="remove_option('closer', index)"><v-icon color="red darken-4">fas fa-circle-xmark</v-icon></v-btn>
								<v-btn small icon class="mr-1 ml-1" @click="add_option('closer', index)"><v-icon color="green darken-4">fas fa-circle-check</v-icon></v-btn>
							</div></v-hover></div>

							<div class="k-alba-section-buttons">
								<v-btn small class="k-tight-btn mr-2" color="indigo darken-4" dark :style="!stage_suggestible('closer')?'opacity:0.5':''" @click="suggest_closing_question"><v-icon x-small class="mr-1">fas fa-robot</v-icon>Suggest{{components.closer.options.length>0?' More':''}} Questions</v-btn>
								<v-btn v-if="components.closer.options.length>0" small class="k-tight-btn mr-2" color="#ccc" @click="clear_options('closer')"><v-icon x-small class="mr-1">fas fa-circle-xmark</v-icon>Clear Suggestions</v-btn>
								<v-btn small color="#444" dark @click="stage_done_clicked('closer')"><v-icon small class="mr-2">fas fa-check</v-icon> Done</v-btn>
							</div>
						</div></v-expand-transition>
					</div>

					<!-- COMMON MISCONCEPTIONS -->
					<div data-stage="miscon" class="k-alba-content-section" :class="section_stage_css('miscon')">
						<div class="k-alba-section-header">
							<v-icon class="k-alba-section-header-icon" @click="toggle_stage('miscon')" :color="`${components.miscon.menu_color} darken-4`">{{components.miscon.menu_icon}}</v-icon>
							<h3 @click="toggle_stage('miscon')">{{components.miscon.menu_label}}</h3>
							<v-spacer/>
							<div class="k-alba-section-instructions">What common misconceptions might your students have about the lesson content?</div>
							<div class="k-alba-section-summary" :class="`${components.miscon.menu_color} lighten-5`">
								<div v-if="stage_complete('miscon')" v-html="section_summary('miscon')"></div>
								<div v-else class="k-alba-section-summary-empty">No Common Misconceptions specified</div>
							</div>
						</div>

						<v-expand-transition><div class="k-alba-section-editor" v-show="stage_open_for_editing('miscon')">
							<div class="mt-2"><froala-wrapper ref="froala_miscon" :config="editor_config()" :parameter_object="components.miscon" parameter="content" v-model="components.miscon.content" /></div>
							<div v-for="(option, index) in components.miscon.options" :key="index"><v-hover v-slot:default="{hover}"><div class="k-alba-content-item my-1 py-1" :class="hover?'grey lighten-5':''">
								<v-icon color="indigo darken-4" small style="margin-top:2px">fas fa-robot</v-icon>
								<div style="flex: 1 1 auto; padding:0 8px 0px 8px" v-html="option"></div>
								<v-btn small icon class="mr-1 ml-1" @click="remove_option('miscon', index)"><v-icon color="red darken-4">fas fa-circle-xmark</v-icon></v-btn>
								<v-btn small icon class="mr-1 ml-1" @click="add_option('miscon', index)"><v-icon color="green darken-4">fas fa-circle-check</v-icon></v-btn>
							</div></v-hover></div>
							<div class="k-alba-section-buttons">
								<v-btn small class="k-tight-btn mr-2" color="indigo darken-4" dark :style="!stage_suggestible('miscon')?'opacity:0.5':''" @click="suggest_miscon"><v-icon x-small class="mr-1">fas fa-robot</v-icon>Suggest{{components.miscon.content?' More':''}} Common Misconceptions</v-btn>
								<v-btn v-if="components.miscon.options.length>0" small class="k-tight-btn mr-2" color="#ccc" @click="clear_options('miscon')"><v-icon x-small class="mr-1">fas fa-circle-xmark</v-icon>Clear Suggestions</v-btn>
								<v-btn small color="#444" dark @click="stage_done_clicked('miscon')"><v-icon small class="mr-2">fas fa-check</v-icon> Done</v-btn>
							</div>
						</div></v-expand-transition>
					</div>

					<!-- TEST -->
					<div data-stage="test" v-if="show_gpt_test" class="k-alba-content-section" style="display:block">
						<div class="k-alba-section-header">
							<h3><span class="grey--text">GPT Test</span></h3>
						</div>
						<div class="my-2 k-alba-textarea"><v-textarea outlined hide-details clearable auto-grow style="width:100%" label="" placeholder="messages" v-model="prompt" rows="2"></v-textarea></div>
						<div class="d-flex align-center pb-2 mb-2" style="border-bottom:1px solid #ccc;">
							<b style="font-size:12px"># of response choices:</b><div style="width:80px" class="ml-1 mr-4"><v-text-field dense outlined hide-details label="" v-model="test_prompt_n" placeholder=""></v-text-field></div>
							<div><b>{{this.gpt_model}}</b>  |  temperature <b>{{this.gpt_temperature}}</b>  |  frequency_penalty <b>{{this.gpt_frequency_penalty}}</b></div>
							<v-spacer/>
							<v-btn small color="primary" @click="test_service">Send to GPT</v-btn>
						</div>
						<div class="float-right ml-1"><CopyBtn size="large" :val="test_prompt_for_copy" /></div>
						<div v-if="prompt_responses.length>0" class="my-2" v-html="format_gpt_output(prompt_responses)"></div>
					</div>

				</div></div>
			</div></v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CASEItemBtn from '../standards/CASEItemBtn'
import CopyBtn from '../utilities/CopyBtn'

export default {
	components: { CASEItemBtn, CopyBtn,  },
	props: {
		// req: { type: String, required: true },
		unit: { type: Object, required: false, default() { return null } },
		collection: { type: Object, required: false, default() { return null } },
	},
	data() { return {
		dialog_open: true,
		stage_showing: [],
		components: {
			standards: {menu_label: 'Standards', menu_color: 'pink', menu_icon: 'fas fa-map'},
			topics: {menu_label: 'Key Concepts', menu_color: 'pink', menu_icon: 'fas fa-list-check'},
			opener: {menu_label: 'Opening Question', menu_color: 'indigo', menu_icon: 'fas fa-circle-question'},
			review: {menu_label: 'Review', menu_color: 'indigo', menu_icon: 'fas fa-clipboard-list'},
			ido: {menu_label: 'Teacher Instruction', menu_color: 'indigo', menu_icon: 'fas fa-person-chalkboard'},
			wedo: {menu_label: 'Guided Practice', menu_color: 'indigo', menu_icon: 'fas fa-person-walking'},
			youdo: {menu_label: 'Independent Practice', menu_color: 'indigo', menu_icon: 'fas fa-person-running'},
			closer: {menu_label: 'Closing Question', menu_color: 'indigo', menu_icon: 'fas fa-circle-question'},
			miscon: {menu_label: 'Common Misconceptions', menu_color: 'indigo', menu_icon: 'fas fa-triangle-exclamation'},
			activity: {menu_label: 'Student Activity', menu_color: 'amber', menu_icon: 'fas fa-star'},
		},
		
		show_gpt_test: false,
		// system_prompt: '',
		prompt: 'Write a short, unique sentence that has never been spoken before, that uses all 26 English letters.',
		test_prompt_n: 1,
		prompt_responses: [],

		gpt_model: 'gpt-4',			// gpt-3.5-turbo gpt-4-turbo-preview
		// gpt_temperature: 0.4,
		gpt_temperature: 0,
		gpt_frequency_penalty: 0,
	}},
	computed: {
		...mapState(['user_info', 'site_config',]),
		...mapGetters([]),

		test_prompt_for_copy() {
			let s = `============== ${this.gpt_model} | temperature ${this.gpt_temperature} | frequency_penalty ${this.gpt_frequency_penalty}\n`
			s += this.prompt
			s += '\n---\n'
			s += this.prompt_responses.join('\n\n')
			return s
		},

		// compose a phrase about the grades being taught to send to gpt
		grade_phrase() {
			let grade_low = 100
			let grade_high = -10
			for (let i = 0; i < this.standards.length; ++i) {
				let s = this.standards[i]
				if (U.grade_value(s.grade_low) < grade_low) grade_low = U.grade_value(s.grade_low)
				if (U.grade_value(s.grade_high) > grade_high) grade_high = U.grade_value(s.grade_high)
			}

			grade_low = vapp.$store.state.grades[grade_low]		// todo: spell out "pre-kindergarten" and "kindergarten"
			grade_high = vapp.$store.state.grades[grade_high]

			if (grade_low == grade_high) return 'Grade ' + grade_low
			else return `Grades ${grade_low} to ${grade_high}`
		},

		standards() {
			// we hold the standards in the components.standards.option array
			return this.components.standards.options
		},

		// put the specified learning standards into a phrase to send to gpt
		standards_phrase() {
			let s = ''
			for (let i = 0; i < this.standards.length; ++i) {
				let standard = this.standards[i]
				if (i > 0) s += ' and '
				s += `"${U.html_to_text(standard.fullStatement)}"`
			}
			return s
		},
	},
	watch: {
		standards: { deep:true, handler() { this.save_settings() }},
		// topics: { deep:true, handler() { this.save_settings() }},
		components: { deep:true, handler() { this.save_settings() }},
		show_gpt_test() { this.save_settings() },
		prompt() { this.save_settings() },
		prompt_responses: { deep:true, handler() { this.save_settings() }},
	},
	created() {
		vapp.alba_component = this

		this.initialize_settings()

		// for now restore previous settings as soon as we launch; later require the user to do this with a button click
		this.restore_settings()
	},
	mounted() {
	},
	methods: {
		initialize_settings() {
			let components = {}
			// some things are fine with the default values set in data, but we need to initialize properties for some objects
			for (let key in this.components) {
				components[key] = this.initialize_component(key, {})
			}

			this.components = components
		},

		initialize_component(key, data) {
			if (empty(data)) data = {}
			let o = object_copy(this.components[key])
			sdp(o, data, 'marked_done', false)	// set to true when user has said they're initially done editing
			sdp(o, data, 'open_for_editing', true)
			sdp(o, data, 'options', [])		// list of text strings, usually suggested by gpt
			if (typeof(data.content) != 'string') data.content = ''
			sdp(o, data, 'content', '')
			return o
		},

		save_settings() {
			let o = {
				components: this.components,

				show_gpt_test: this.show_gpt_test,
				prompt: this.prompt,
				// system_prompt: this.system_prompt,
				test_prompt_n: this.test_prompt_n,
				prompt_responses: this.prompt_responses,
			}
			this.$store.commit('lst_set', ['alba_settings', o])
		},

		restore_settings() {
			let o = this.$store.state.lst.alba_settings
			for (let key in o) {
				if (key == 'components') {
					for (let ckey in o.components) {
						// if we're using this component (we might change components over time...), initialize the component's elements (we might change component elements over time)
						if (this.components[ckey]) this.components[ckey] = this.initialize_component(ckey, o.components[ckey])
					}
				} else if (!empty(this[key])) {
					this[key] = o[key]
				}
			}
		},

		clear_settings() {
			this.initialize_settings()
			for (let key in this.components) {
				let f = this.$refs['froala_' + key]
				if (f) f.set_value('')
			}
		},

		editor_config(text, inline) {
			let o = {
				placeholderText: text,
				// initOnClick: true,
				toolbarInline: (inline === true),
				paragraphFormat: {
					H3: 'Section Header',
					N: 'Normal',
					BLOCKQUOTE: 'Block Quote',
				    PRE: 'Code',
				},
				enter: FroalaEditor.ENTER_BR,
				zIndex: 1000,	// needed for to make table menus show up properly
			}
			let config = U.get_froala_config(o)

			// add the insert resource btn at the front of the moreRich buttons
			config.toolbarButtons.moreRich.buttons.unshift('insert_resource')
			config.toolbarButtons.moreRich.buttonsVisible += 1
			// and remove the 'insertHR' btn, if there
			let i = config.toolbarButtons.moreRich.buttons.findIndex(x=>x=='insertHR')
			if (i > -1) {
				config.toolbarButtons.moreRich.buttons.splice(i, 1)
				if (i <= (config.toolbarButtons.moreRich.buttonsVisible-1)) config.toolbarButtons.moreRich.buttonsVisible -= 1
			}
			// also remove the 'paragraphFormat' btn from the moreMisc buttons
			i = config.toolbarButtons.moreMisc.buttons.findIndex(x=>x=='paragraphFormat')
			if (i > -1) {
				config.toolbarButtons.moreMisc.buttons.splice(i, 1)
				if (i <= (config.toolbarButtons.moreMisc.buttonsVisible-1)) config.toolbarButtons.moreMisc.buttonsVisible -= 1
			}

			return config
		},

		stage_available(stage) {
			for (let s in this.components) {
				if (s == stage) return true
				// if we get to a stage before the specified stage that is not yet complete, the specified stage isn't available
				if (!this.components[s].marked_done) return false
			}
		},

		stage_complete(stage) {
			if (stage == 'standards') return this.standards.length > 0
			return !empty(this.components[stage].content)
		},

		stage_marked_done(stage) {
			return this.components[stage].marked_done
		},

		stage_open_for_editing(stage) {
			return this.stage_available(stage) && this.components[stage].open_for_editing
		},

		stage_suggestible(stage) {
			// standards are never suggestible (at least for now)
			if (stage == 'standards') return false
			
			// else if we have standards, topics, or an opener or closer, we can suggest anything else based on them
			if (this.standards.length > 0) return true
			if (this.stage_complete('topics')) return true
			if (this.stage_complete('opener')) return true
			if (this.stage_complete('closer')) return true
			
			return false
		},

		section_stage_css(stage) {
			let s = ''
			if (this.stage_marked_done(stage)) s += ' k-alba-section-stage-marked_done'
			if (this.stage_complete(stage)) s += ' k-alba-section-stage-complete'
			if (this.stage_available(stage)) s += ' k-alba-section-stage-available'
			if (this.stage_open_for_editing(stage)) s += ' k-alba-section-stage-open_for_editing'
			return s
		},

		menu_stage_css(stage) {
			let s = ''
			if (this.stage_marked_done(stage)) s += ' k-alba-menu-stage-marked_done'
			if (this.stage_complete(stage)) s += ' k-alba-menu-stage-complete'
			if (this.stage_available(stage)) s += ' k-alba-menu-stage-available'
			return s
		},

		menu_stage_icon(stage) {
			if (this.stage_complete(stage)) return 'fas fa-circle-check'
			// if not complete but marked done...
			if (this.stage_marked_done(stage)) return 'fas fa-circle-minus'
			else return 'far fa-circle'
		},

		section_summary(stage) {
			let content = this.components[stage].content
			if (['opener'].includes(stage)) {
				return content

			} else {
				let lines = content.split('<br>')
				let s = `<ul>`
				for (let line of lines) s += `<li>${line}</li>`
				s += `</ul>`
				return s
			}
		},

		stage_done_clicked(stage) {
			this.trim_content()
			this.components[stage].marked_done = true
			this.components[stage].open_for_editing = false

			// when you click done for standards, close the chooser if open
			if (stage == 'standards') {
				vapp.$refs.satchel.execute('hide')
			}
		},

		go_to_stage(stage) {
			this.components[stage].open_for_editing = true
			// this fn is called from the menu only. when called, if any previous stages have not yet been marked done, mark them now
			for (let s in this.components) {
				if (s == stage) break
				if (!this.components[s].marked_done) {
					this.components[s].marked_done = true
					this.components[s].open_for_editing = false
				}
			}


			// scroll to stage
		},

		toggle_stage(stage) {
			this.components[stage].open_for_editing = !this.components[stage].open_for_editing
		},

		remove_option(component_label, index) {
			let component = this.components[component_label]
			component.options.splice(index, 1)
		},

		clear_options(component_label) {
			let component = this.components[component_label]
			component.options = []
		},

		add_option(component_label, index) {
			let component = this.components[component_label]
			if (!empty(component.content)) component.content += '<br>'
			component.content += component.options[index]
			this.remove_option(component_label, index)

			this.$refs['froala_' + component_label].set_value(component.content)
		},

		trim_content() {
			for (let key in this.components) {
				this.components[key].content = $.trim(this.components[key].content)
			}
		},

		//////////////////////////////////////////
		// STANDARDS
		choose_standards(starting_framework_identifier) {
			if (typeof(starting_framework_identifier) != 'string') starting_framework_identifier = ''

			let data = { framework_identifier: '', item_identifier: '' }

			if (starting_framework_identifier) {
				data.framework_identifier = starting_framework_identifier
			} else {
				// TODO: if we didn't get the current collection has a framework and/or course cfitem specified, use them
			}

			// if we already have some standards...
			if (this.standards.length > 0) {
				// use the last standard for the framework_identifier (if we didn't receive a starting_framework_identifier)
				if (!starting_framework_identifier) data.framework_identifier = this.standards[this.standards.length-1].framework_identifier

				// pre-select all standards from that framework
				data.selected_items = []
				for (let standard of this.standards) {
					if (standard.framework_identifier == data.framework_identifier) {
						data.selected_items.push(standard.identifier)
					}
				}
			}

			// set hide_fn to hide the standards chooser if/when the bulk editor is no longer visible
			let show_data = { hide_fn: ()=>{ return ($('.k-alba-outer').length == 0) } }

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
						// convert aligned_item to CASE_Item structure, which is what we save for lessons
						aligned_item = new CASE_Item(aligned_item.cfitem, aligned_item.framework_identifier)

						// if we already have this item aligned, remove the standard
						let i = this.standards.findIndex(o=>o.identifier==aligned_item.identifier)
						if (i > -1) {
							this.standards.splice(i, 1)

							// re-initialize the chooser, showing the framework for the item we removed
							this.choose_standards(aligned_item.framework_identifier)

						} else {
							// Add the standard, which will include the cfitem and the framework_identifier (this is the format that Sparkl wants)
							this.standards.push(aligned_item)

							// re-initialize the chooser, showing the framework for the item we added
							this.choose_standards(aligned_item.framework_identifier)
						}
					})
				})
			})
		},

		remove_standard(index) {
			this.standards.splice(index, 1)
		},

		////////////////////////////////////
		// TOPICS
		suggest_topics() {
			this.trim_content()
			if (!this.stage_suggestible('topics')) {
				this.$alert('To have ALBA suggest Key Concepts, you must select one or more Standards, enter one or more Key Concepts yourself, or write an Opening Question or a Closing Question for the lesson.')
				return
			}

			let messages = this.messages_start('topics')
			
			if (!empty(this.components.topics.content)) {
				messages += `\nuser: I will be covering the following key concepts in the lesson: ${this.format_for_gpt_message(this.components.topics.content)}.`
				messages += `\nuser: Please create a list of additional key concepts students should learn in the lesson.`

			} else {
				messages += `\nuser: Please create a list of key concepts students should learn in the lesson.`
			}

			this.gpt({messages: messages}).then(responses=>{
				this.components.topics.options = this.components.topics.options.concat(this.parse_gpt_response_list(responses[0]))
			})
		},

		////////////////////////////////////
		// OPENING QUESTION
		suggest_opening_question() {
			this.trim_content()
			if (!this.stage_suggestible('opener')) {
				this.$alert('To have ALBA suggest an Opening Question, you must select one or more Standards, specify one or more Key Concepts, or write a Closing Question for the lesson.')
				return
			}

			let messages = this.messages_start('opener')

			messages += '\nuser: Suggest five different questions, making sure to reference real-world examples, to ask my students at the start of the lesson.'

			// console.log(messages); return
			this.gpt({messages: messages}).then(responses=>{
				this.components.opener.options = this.components.opener.options.concat(this.parse_gpt_response_list(responses[0]))
			})
		},

		////////////////////////////////////
		// REVIEW
		suggest_review() {
			this.trim_content()
			if (!this.stage_suggestible('review')) {
				this.$alert('To have ALBA suggest Review topics, you must select one or more Standards, specify one or more Key Concepts, or write an Opening or Closing Question for the lesson.')
				return
			}

			let messages = this.messages_start('review')

			messages += '\nuser: Create a list of key concepts that students must already know before starting the lesson.'

			// console.log(messages); return
			this.gpt({messages: messages}).then(responses=>{
				this.components.review.options = this.components.review.options.concat(this.parse_gpt_response_list(responses[0]))
			})
		},

		////////////////////////////////////
		// IDO
		suggest_ido() {
			this.trim_content()
			if (!this.stage_suggestible('ido')) {
				this.$alert('To have ALBA suggest Teacher Instruction ideas, you must select one or more Standards, specify one or more Key Concepts, or write an Opening or Closing Question for the lesson.')
				return
			}

			let messages = this.messages_start('ido')

			messages += '\nuser: Suggest some ideas for direct teacher instruction in the lesson.'

			// console.log(messages); return
			this.gpt({messages: messages}).then(responses=>{
				this.components.ido.options = this.components.ido.options.concat(this.parse_gpt_response_list(responses[0]))
			})
		},

		////////////////////////////////////
		// WEDO
		suggest_wedo() {
			this.trim_content()
			if (!this.stage_suggestible('wedo')) {
				this.$alert('To have ALBA suggest Guided Practice ideas, you must select one or more Standards, specify one or more Key Concepts, or write an Opening or Closing Question for the lesson.')
				return
			}

			let messages = this.messages_start('wedo')

			messages += '\nuser: Suggest some ideas for guided practice (“we do”) in the lesson.'

			// console.log(messages); return
			this.gpt({messages: messages}).then(responses=>{
				this.components.wedo.options = this.components.wedo.options.concat(this.parse_gpt_response_list(responses[0]))
			})
		},

		////////////////////////////////////
		// YOUDO
		suggest_youdo() {
			this.trim_content()
			if (!this.stage_suggestible('youdo')) {
				this.$alert('To have ALBA suggest Independent Practice ideas, you must select one or more Standards, specify one or more Key Concepts, or write an Opening or Closing Question for the lesson.')
				return
			}

			let messages = this.messages_start('youdo')

			messages += '\nuser: Suggest some ideas for student independent practice in the lesson.'

			// console.log(messages); return
			this.gpt({messages: messages}).then(responses=>{
				this.components.youdo.options = this.components.youdo.options.concat(this.parse_gpt_response_list(responses[0]))
			})
		},

		////////////////////////////////////
		// CLOSING QUESTION
		suggest_closing_question() {
			this.trim_content()
			if (!this.stage_suggestible('closer')) {
				this.$alert('To have ALBA suggest an Closing Question, you must select one or more Standards, specify one or more Key Concepts, or write an Opening Question for the lesson.')
				return
			}

			let messages = this.messages_start('closer')

			messages += '\nuser: Suggest five different questions to ask my students at the end of the lesson.'

			// console.log(messages); return
			this.gpt({messages: messages}).then(responses=>{
				this.components.closer.options = this.components.closer.options.concat(this.parse_gpt_response_list(responses[0]))
			})
		},

		////////////////////////////////////
		// MISCONCEPTIONS
		suggest_miscon() {
			this.trim_content()
			if (!this.stage_suggestible('miscon')) {
				this.$alert('To have ALBA suggest Common Misconceptions, you must select one or more Standards, specify one or more Key Concepts, or write an Opening or Closing Question for the lesson.')
				return
			}

			let messages = this.messages_start('miscon')

			messages += '\nuser: Create a list of common misconceptions that students may have about the lesson content.'

			// console.log(messages); return
			this.gpt({messages: messages}).then(responses=>{
				this.components.miscon.options = this.components.miscon.options.concat(this.parse_gpt_response_list(responses[0]))
			})
		},

		///////////////////////////////////////////
		// GPT AND TEST
		format_for_gpt_message(s) {
			// s = $.trim(s)
			// return U.html_to_text(s.replace(/<br>/g, ' '))

			let lines = s.split('<br>')
			s = ''
			for (let line of lines) {
				if (empty(line)) continue
				if (!empty(s)) s += ', '
				s += `"${line}"`
			}
			return s
		},

		// this will be called by all stages except [standards]
		messages_start(stage) {
			// system: You are a laconic assistant. You reply with brief, to-the-point answers. You do not repeat user questions in your answers.
			// system: You are a helpful assistant that does not repeat user questions in your answers.

			let messages = `user: I am a teacher. Can you help me write a lesson plan?`

			if (this.standards.length > 0) {
				messages += `\nassistant: Yes. What grade level are your students?`
				messages += `\nuser: ${this.grade_phrase}`
				messages += `\nassistant: What learning standards are covered in the lesson?`
				messages += `\nuser: ${this.standards_phrase}`
			}

			if (stage != 'topics' && !empty(this.components.topics.content)) {
				messages += `\nassistant: What key concepts will you cover?`
				messages += `\nuser: ${this.format_for_gpt_message(this.components.topics.content)}.`
			}

			if (stage != 'opener' && !empty(this.components.opener.content)) {
				messages += `\nassistant: What is your opening question for the lesson?`
				messages += `\nuser: ${this.format_for_gpt_message(this.components.opener.content)}.`
			}

			if (stage != 'closer' && !empty(this.components.closer.content)) {
				messages += `\nassistant: What is your closing question for the lesson?`
				messages += `\nuser: ${this.format_for_gpt_message(this.components.closer.content)}.`
			}

			return messages
		},

		gpt(params) {
			let payload = {
				user_id: this.user_info.user_id,
				n: params.n ?? 1,
				model: this.gpt_model,
				temperature: this.gpt_temperature,
				frequency_penalty: this.gpt_frequency_penalty,
				seed: U.random_int(1000000),
				messages: []
			}
			if (params.model) payload.model = params.model
			if (params.temperature) payload.temperature = params.temperature
			if (params.frequency_penalty) payload.frequency_penalty = params.frequency_penalty
			if (params.messages) {
				if (typeof(params.messages) == 'string') {
					// parse lines from prompt string into messages
					let lines = params.messages.split('\n')
					for (let line of lines) {
						line = $.trim(line)
						if (empty(line)) continue
						let role, content
						if (line.search(/(user|assistant|system)\s*:\s*(.*)/) > -1) {
							role = RegExp.$1
							content = RegExp.$2
						} else {
							role = 'user'
							content = line
						}
						payload.messages.push({role: role, content: content})	
					}

				} else {
					payload.messages = params.messages
				}

			} else {
				// if we don't get messages, we should at least get content_user, and might also get content_system
				if (params.content_user) payload.messages.push({role: 'user', content:params.content_user})
				if (params.content_system) payload.messages.push({role: 'system', content:params.content_system})
			}

			console.log('================================ gpt messages', payload)
			for (let message of payload.messages) console.log(`${message.role}: ${message.content}`)

			return new Promise((resolve, reject) => {
				U.loading_start()
				U.ajax('openai_query', payload, result=>{
					U.loading_stop()
					console.log('gpt output', result)
					if (result.status != 'ok') {
						this.$alert('Error querying GPT')
						reject()
						return
					}
					resolve(result.responses)
				})
			})
		},

		test_service() {
			let payload = {messages: $.trim(this.prompt)}
			if (this.test_prompt_n * 1 > 0) payload.n = this.test_prompt_n * 1
			this.gpt(payload).then(responses=>{ this.prompt_responses = responses })
		},

		format_gpt_output(output) {
			// output could be a string or an array of strings
			if (typeof(output) == 'string') output = [output]

			let r = ''
			for (let s of output) {
				s = s.replace(/\n/g, '<br>')
				r += `<div style="border-bottom:1px solid #ccc" class="mb-1 pb-1">${s}</div>`
			}

			return r
		},

		parse_gpt_response_list(response) {
			let arr = []
			let lines = response.split('\n')

			// if 
			for (let line of lines) {
				line = $.trim(line)
				if (empty(line)) continue

				// assume gpt always starts each list item with a number
				if (line.search(/^\d+\.\s+(.*)/) > -1) {
					// if quoted, get rid of the quotes
					line = RegExp.$1
					line = line.replace(/^"(.*?)"$/, '$1')
					arr.push(line)
				} else {
					console.log(`parse_gpt_response_list: unparsed line “${line}”`)
				}
			}
			return arr
		},
	}
}
</script>

<style lang="scss">
.k-alba-dialog {
	max-height:95vh!important;
}

.k-alba-outer-outer {
	display:flex;
	max-height:calc(95vh - 60px);
}

.k-alba-menu-stages {
	flex: 0 0 260px;
	background-color:#ccc;
	font-size:16px;
	border-right:1px solid #999;
	margin-right:-1px;
	display:flex;
	flex-direction:column;
	overflow:hidden;

	.k-alba-menu-stage-outer {
		flex: 0 1 auto;
		border-bottom:1px solid #999!important;
		opacity:0.7;
		// display:flex;
	}
	.k-alba-menu-stage {
		cursor:pointer;
		padding:8px;
		// font-weight:bold;
		font-size:15px;
		display:flex;
		.fas, .far { margin-top:-3px; font-size:20px!important; opacity:0.5; }
		.fas.fa-circle-check, .far.fa-circle { color:#222!important; }

		.k-alba-menu-stage-label {
			flex: 1 1 auto;
		}
	}

	.k-alba-menu-stage-available { 
		opacity:1;
		.fas, .far { opacity:1; }
	}
	.k-alba-menu-stage-marked_done { 
		font-weight:bold; 
		color:#555; 
	}
	.k-alba-menu-stage-complete { 
		opacity:1; 
		color:#000;
	}
}

.k-alba-content {
	flex: 1 1 auto;
	border-left:1px solid #999;
	display:flex;
	flex-direction:column;

	.k-alba-content-inner {
		flex:1;
		overflow:auto;
		// padding:0px 12px 8px 12px;
		// padding:0 0 8px 0;

	}

	.k-alba-content-section {
		display:none;
		border-bottom:1px solid #ccc;
		padding:8px 8px 8px 16px;
	}

	.k-alba-section-header {
		display:flex;
		align-items: flex-start;
		margin-bottom:8px;

		.k-alba-section-header-icon {
			font-size:24px!important;
			width:24px;
			margin:2px 8px 0 -8px;
			padding:0;
		}

		h3 {
			padding:4px 0;
			// margin-left:-8px;
			margin-right:12px;
			white-space:nowrap;
			cursor:pointer;
		}
	}

	.k-alba-section-summary {
		padding:4px 8px;
		border-radius:4px;
		flex: 0 1 600px;
		// max-width:600px;
	}

	.k-alba-section-summary-empty {
		text-align:center;
		font-size:14px;
		font-style:italic;
	}

	.k-alba-section-instructions {
		display:none;
		background-color:$v-green-lighten-4;
		padding:4px 8px;
		border-radius:4px;
		flex: 0 1 600px;
		font-size:14px;
	}

	.k-alba-section-editor {
		// display:none;
	}

	.k-alba-section-buttons {
		text-align:center;
		margin-top:12px;
	}

	// .k-alba-section-stage-editing { 
	// }
	.k-alba-section-stage-available { 
		display:block;
	}
	.k-alba-section-stage-open_for_editing { 
		.k-alba-section-instructions { display:block; }
		// .k-alba-section-editor { display:block; }
		.k-alba-section-summary { display:none; }
		// .k-alba-section-header-spacer { display:none; }
	}
	.k-alba-section-stage-marked_done { 
	}
	.k-alba-section-stage-complete { 
	}

	.k-alba-content-section.k-alba-section-stage-open_for_editing {
		// border-bottom-width:3px;
		background-color:#eee;
	}

	// .k-alba-content-section:first-child { border-top:0; padding-top:0; }
}

.k-alba-content-item {
	display:flex;
	align-items:flex-start;
	margin-left:-4px;
	padding-left:4px;
	border-radius:5px;
}

.k-alba-textarea textarea {
	font-size: 14px;
    line-height: 18px;
}

</style>