<template><div class="k-mini-nav">
	<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-show="tab_showing('classes')" v-on="on" fab x-small :class="current_section!='course'?'k-mini-nav-non-current-btn elevation-3':'elevation-2'" @click="go_to_home('classes', $event)"><v-icon small>{{ classes_tab.icon }}</v-icon></v-btn></template>{{ classes_tab.title }}</v-tooltip>
	<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-show="tab_showing('resourcerepos')" v-on="on" fab x-small :class="current_section!='repo'?'k-mini-nav-non-current-btn elevation-3':'elevation-2'" @click="go_to_home('resourcerepos', $event)"><v-icon small>{{ resourcerepos_tab.icon }}</v-icon></v-btn></template>{{ resourcerepos_tab.title }}</v-tooltip>
	<!-- <v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-show="tab_showing('pd')" v-on="on" fab x-small :class="current_section!='pd'?'k-mini-nav-non-current-btn elevation-3':'elevation-2'" @click="go_to_home('pd', $event)"><v-icon small>fas fa-user-graduate</v-icon></v-btn></template>Professional Development</v-tooltip> -->
	<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-show="tab_showing('mycollections')" v-on="on" fab x-small :class="current_section!='my'?'k-mini-nav-non-current-btn elevation-3':'elevation-2'" @click="go_to_home('mycollections', $event)"><v-icon small>{{ mycollections_tab.icon }}</v-icon></v-btn></template>{{ mycollections_tab.title }}</v-tooltip>
	<!-- <v-btn icon @click="go_to_home('', $event)"><v-icon>fas fa-arrow-circle-left</v-icon></v-btn> -->
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
		current_section: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
	}},
	computed: {
		...mapState(['site_config', 'user_info']),
		...mapGetters(['signed_in', 'studentish_role']),
		classes_tab() {
			const { classes: customized_classes = {} } = this.site_config.customized_tabs
			const icon = customized_classes.icon || 'fas fa-chalkboard'
			const title = customized_classes.title || 'Courses Home'
			return { icon, title }
		},
		resourcerepos_tab() {
			const { resourcerepos: customized_resourcerepos = {} } = this.site_config.customized_tabs
			const icon = customized_resourcerepos.icon || 'fas fa-diagram-project'
			const title = customized_resourcerepos.title || `${this.site_config.resourcerepos_noun} Home`
			return { icon, title }
		},
		mycollections_tab() {
			const { mycollections: customized_mycollections = {} } = this.site_config.customized_tabs
			const icon = customized_mycollections.icon || 'fas fa-cubes-stacked'
			const title = customized_mycollections.title || 'My Content Collections'
			return { icon, title }
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		// note that tab_showing here mostly mirrors what we do in WelcomeView
		tab_showing(id) {
			// some collection types are never viewable to users that aren't signed in, or to studentish users
			if (id == 'pd' && (!this.signed_in || this.studentish_role)) return false
			if (id == 'mycollections') {
				// for mycollections, we have a show_mycollections_when_not_signed_in flag; if yes, show the tab when not signed in
				if (this.site_config.show_mycollections_when_not_signed_in == 'yes' && !this.signed_in) return true
				// else don't show the tab if not signed in
				if (!this.signed_in || this.studentish_role) return false
			}
			// if main_tabs_to_include is 'all', include everything at this point
			if (this.site_config.main_tabs_to_include == 'all') return true
			// otherwise honor the array stored in main_tabs_to_include
			return this.site_config.main_tabs_to_include.includes(id)
		},
		go_to_home(new_section, event) {
			vapp.go_to_home(new_section, event)
		},
	}
}
</script>

<style lang="scss">
.k-mini-nav {
	margin-right:0px;
	display:flex;
	flex-direction: column;
	justify-content: center;
	background-color:rgba(255, 255, 255, 0.85);
	height:160px;
	border-radius:16px 0 0 16px;
	padding:12px 24px 12px 12px;

	.v-btn {
		margin:2px -16px 2px 0;
		// color:#444;
		// i { color:#fff!important; }
		border:1px solid #000;
		// background-color:#666;
		// box-shadow: none!important;
	}

	.k-mini-nav-non-current-btn {
		border-color:transparent;
		background-color:#fff!important;
		i { color:#fff!important; }
	}
}

.k-mini-nav-site-section-heading {
	text-align:center;
	font-weight:900;
	font-size:16px;
	line-height:16px;
	color:#333;
	text-transform: uppercase;
	margin:12px 0 -2px 0;
}
</style>
