<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div :class="folder_css_class" v-show="show_folder" @click="clear_last_viewed_resource">

	<!-- note that this won't be displayed for the top folder -->
	<div v-if="folder_title" class="k-resource-folder-header" :class="folder_header_css_class" @click.stop="toggle_folder_open">
		<div class="k-resource-folder-header-inner" :class="folder_header_inner_css_class">
			<v-checkbox class="shrink d-inline-block" style="margin:-2px -5px -2px 7px!important; padding:0!important;" v-if="enable_collection_editing&&folder.tcc_folder_id" hide-details :indeterminate="rcis_parent_or_child_included" v-model="rcis_checkbox_checked" @change="folder_checkbox_clicked" @click.stop=""></v-checkbox>
			<v-icon class="k-resource-folder-icon mr-2" style="margin-left:7px;" :style="folder_icon_css" small>fas {{folder_icon}}</v-icon>
			<div class="k-resource-folder-title" v-html="folder_title"></div>
			<v-tooltip bottom><template v-slot:activator="{on}"><v-icon v-on="on" class="k-collections-folder-search-result" @click.stop="$emit('clear_collection_last_search_results')" v-show="in_last_search_results">fas fa-search</v-icon></template><div style="text-align:center; font-size:12px; line-height:14px;">Item(s) in this folder were found<br>in your last resource search<br>(click to clear)</div></v-tooltip>
			<div class="k-resource-folder-count" :class="folder_being_edited?'mt-0':''">({{n_resources}}{{(!folder_open)?' ' +n_resources_word:''}})</div>
			<v-menu bottom left>
				<template v-slot:activator="{on}"><v-btn v-show="enable_collection_editing&&!is_default_collection&&!is_shadow_unit && !in_temp_course_guidance_folder" v-on="on" class="ml-1" style="z-index:3; margin-top:-4px;" icon small color="primary"><v-tooltip bottom><template v-slot:activator="{on}"><v-icon small v-on="on">fas fa-ellipsis-v</v-icon></template>Folder options</v-tooltip></v-btn></template>
				<v-list dense min-width="250">
					<v-list-item @click="edit_folder_title"><v-list-item-icon><v-icon small>fas fa-edit</v-icon></v-list-item-icon><v-list-item-title>Edit folder title</v-list-item-title></v-list-item>
					<v-list-item @click="edit_folder_color"><v-list-item-icon><v-icon x-small :color="folder.color">fas fa-circle</v-icon></v-list-item-icon><v-list-item-title>Edit folder color</v-list-item-title></v-list-item>
					<v-list-item @click="remove_folder"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Remove folder</v-list-item-title></v-list-item>
				</v-list>
			</v-menu>
			<div class="k-resource-folder-close-icon"><v-icon>fas fa-caret-up</v-icon></div>
		</div>
		<v-spacer/>
		<div v-if="n_resources>0||enable_collection_editing" class="k-resource-folder-reveal-icon"><v-icon>fas fa-caret-down</v-icon></div>
		<!-- <v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" v-show="!xxxenable_editing&&folder_todo_count>0&&!folder_open" class="k-collections-unit-list-item-assignment-total mt-1">{{folder_todo_count}}</div></template>{{folder_todo_count}} {{folder_todo_count==1?'Resource':'Resources'}} To Do</v-tooltip> -->
	</div>

	<v-expand-transition><div v-if="folder_open" class="k-resource-folder-resources" :class="folder_resources_css_class">
		<div v-if="enable_collection_editing && !allow_resource_reordering && !is_default_collection && !is_shadow_unit && !folder.tcc_folder_id && !folder.has_rcis" class="mb-2">
			<v-btn v-if="!in_temp_course_guidance_folder" x-small class="ml-3 k-tight-btn elevation-0" color="#555" dark @click="search_start"><v-icon x-small class="mr-1">fas fa-plus</v-icon>Add Content to {{folder_id=='top'?'Unit':'Folder'}}</v-btn>
			<v-btn v-if="!in_temp_course_guidance_folder" x-small class="ml-2 k-tight-btn elevation-0" color="#555" dark @click="create_resource_folder_start"><v-icon x-small class="mr-1">fas fa-folder</v-icon>Create a new {{folder_id=='top'?'Folder':'Sub-Folder'}}</v-btn>
			<div v-if="in_temp_course_guidance_folder">Resources for Course Guidance can only be edited in Unit 1 of the course collection (Unit 1: {{ collection.units[0].title }})</div>
		</div>

		<div :class="folder_resource_list.length>0?'mb-0':''">
			<draggable v-bind="drag_options" v-model="folder_resource_list" @end="drag_complete" class="k-resource-folder-resources-inner">
				<div v-for="(resource, index) in folder_resource_list" :data-resource_id="resource.resource_id?resource.resource_id:resource.lesson_id" :class="resource_collection_item_container_class(resource)"><v-hover v-slot:default="{hover}">
					<div v-if="resource.type=='folder'" class="d-flex align-top" :class="index==0?'k-resource-folder-resources-first-child':(index==folder_resource_list.length-1)?'k-resource-folder-resources-last-child':''">

						<div style="margin:8px 4px 0 4px; float:left;" v-if="enable_collection_editing&&allow_resource_reordering&&!resource.tcc_folder_id"><v-icon small color="#999" class="moveHandle">fas fa-arrows-alt</v-icon></div>
						<div style="flex:1 1 auto">
							<CollectionResourceFolder
								:key="resource.resource_id"
								:collection="collection"
								:folder_id="resource.resource_id"
								:unit="unit"
								:search_results_items="search_results_items"
								:enable_collection_editing="enable_collection_editing"
								:allow_resource_reordering="allow_resource_reordering"
								:folder_being_edited="folder_being_edited"
								:force_full_width="force_full_width"
								:term_mode="term_mode"
								@save_unit_edits="$emit('save_unit_edits', $event)"
								@copy_to_shadow_unit_finish="$emit('copy_to_shadow_unit_finish', $event)"
								@clear_collection_last_search_results="$emit('clear_collection_last_search_results')"
								@lesson_shift_update="$emit('lesson_shift_update',$event)"
							/>
						</div>
					</div>
					<div v-else style="height:100%" :class="resource_collection_item_class(resource, hover)">
						<div v-if="enable_collection_editing&&allow_resource_reordering" style="position:absolute;left:4px;top:4px;"><v-icon small color="#999" class="moveHandle">fas fa-arrows-alt</v-icon></div>
						<ResourceCollectionItem :key="resource.resource_id" :full_width_resource="full_width_resource(resource)"
							:item="resource"
							:lp_context="collection"
							:unit_context="unit"
							@edit_item_saved="edit_item_saved"
							@edit_item_cancel="edit_item_cancel"
							@add_duplicated_items="add_duplicated_items" 
							@remove_item="remove_item"
							@copy_to_shadow_unit_finish="$emit('copy_to_shadow_unit_finish', $event)"
							@clear_collection_last_search_results="$emit('clear_collection_last_search_results')"
							@lesson_shift_update="$emit('lesson_shift_update',$event)"
						/>
					</div>
				</v-hover></div>
				<div slot="footer" v-if="folder_resource_list.length==0 && allow_resource_reordering" class="k-resource-folder-empty-footer">This folder is currently empty</div>
			</draggable>
		</div>

	</div></v-expand-transition>
	
	<ResourceSearch v-if="show_search" 
		:dialog_title="resource_search_dialog_title"
		:item_types="available_item_types_to_add" 
		:existing_resources="unit.resources" :existing_lessons="unit.lessons"
		:home_collection="collection" :home_unit="unit" :allow_add_from_home="true"
		@add_items_from_search="add_items_from_search" 
		@edit_item_saved="edit_item_saved"
		@dialog_cancel="search_cancel" 
	/>

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import CollectionResourceFolder from './CollectionResourceFolder'
import ResourceCollectionItem from '../resources/ResourceCollectionItem'
import ResourceSearch from '../resources/ResourceSearch'

export default {
	name: 'CollectionResourceFolder',
	components: { draggable, CollectionResourceFolder, ResourceCollectionItem, ResourceSearch },
	props: {
		collection: { type: Object, required: true },
		folder_id: { type: String, required: true },
		unit: { type: Object, required: true },
		// note that if this component is being deployed to edit a unit, the value of the `unit` prop will be a *copy* of the original unit in the store;
		// that's why we set values directly below, rather than commit.set'ing them
		search_results_items: { type: Array, required: false, default() { return [] }},
		enable_collection_editing: { type: Boolean, required: false, default() { return false }},
		allow_resource_reordering: { type: Boolean, required: false, default() { return false }},
		// folder_being_edited will be true iff we're in the context where we're in the unit editor (CollectionUnitEdit)
		folder_being_edited: { type: Boolean, required: false, default() { return false }},
		force_full_width: { type: Boolean, required: false, default() { return false }},
		term_mode: { required: false, default() { return 'normal' }},
	},
	data() { return {
		show_resource_creator: false,
		drag_options: {
		    animation: 200,
		    handle: '.moveHandle',
			group: 'collection_resources',
			componentData: { attrs: { 'data-resource_id': this.folder_id }}
		},
		in_last_search_results: false,
		show_search: false,
		descendent_resource_ids: [],
	}},
	computed: {
		...mapState(['user_info', 'site_config', 'my_resources', 'my_lessons', 'last_viewed_resource_id']),
		...mapGetters(['beta_options', 'my_default_collection', 'signed_in', 'role', 'studentish_role', 'show_all_items_when_not_signed_in']),
		unit_mode() { return this.$store.state.lst.unit_mode },
		collection_view_mode() { 
			if (this.collection.collection_type === 'pd') {
				return 'list'
			}
			return this.$store.state.lst.collection_view_mode 
		},
		is_default_collection() { return (this.collection.course_code == 'default') },
		is_shadow_unit() { return (this.unit.shadows_lp_unit_id != 0) },
		is_collection_admin() {
			if (this.is_default_collection) return true
			return this.collection.user_is_lp_admin()
		},
		is_instance_unit() {
			if (this.unit.instance_unit_of !== 0) return true
			return false
		},
		is_flex_unit() {
			return this.unit.is_flex_unit
		},
		flex_unit_for_instance() {
			if (!this.is_instance_unit) return null
			const flex_unit = this.collection.units.find(u => u.lp_unit_id == this.unit.instance_unit_of)
			return flex_unit
		},
		unit_has_excluded_content_types() {
			// If this is a flex unit and content types have been excluded...
			if (this.is_flex_unit && this.unit.excluded_content_types?.length) return true
			// If this is an instance of a flex unit that has content types that have been excluded...
			if (this.is_instance_unit && this.flex_unit_for_instance?.excluded_content_types?.length) return true

			return false
		},
		available_item_types_to_add() {
			// for the default collection, we have one unit for each type...
			if (this.is_default_collection && this.unit.lp_unit_id == 2) return ['lessons']
			if (this.is_default_collection && this.unit.lp_unit_id == 3) return ['activities']
			if (this.is_default_collection && this.unit.lp_unit_id == 4) return ['resources']

			let available_types = ['resources','lessons','activities']
			// If this is a Flex Unit and the excluded_content_types array has items in it,
			// OR if this is a instance unit of a flex unit containing excluded_content_types, remove them
			if (this.unit_has_excluded_content_types) {
				if (this.is_flex_unit) {
					available_types = available_types.filter(item => !this.unit.excluded_content_types.includes(item))
				} else if (this.is_instance_unit) {
					available_types = available_types.filter(item => !this.flex_unit_for_instance.excluded_content_types.includes(item))
				}
			}
			return available_types
		},
		resource_search_dialog_title() {
			// for the default collection, we have one unit for each type...
			if (this.is_default_collection && this.unit.lp_unit_id == 2) return 'Add Lessons'
			if (this.is_default_collection && this.unit.lp_unit_id == 3) return `Add ${this.site_config.sparkl_app_name} Student Activities`
			if (this.is_default_collection && this.unit.lp_unit_id == 4) return 'Add Resources'
			return 'Add Lessons, Activities, or other Resources'
		},
		import_menu_item() {
			// for the default collection, we have one unit for each type...
			if (this.is_default_collection && this.unit.lp_unit_id == 2) return 'Import a Shared Lesson'
			if (this.is_default_collection && this.unit.lp_unit_id == 3) return `Import a shared ${this.site_config.sparkl_app_name} Student Activity`
			if (this.is_default_collection && this.unit.lp_unit_id == 4) return ''
			return 'Import a shared Lesson or Activity'
		},
		folder() { 
			return this.unit.resource_tree.folders.find(o=>o.folder_id==this.folder_id) ?? {} 
		},
		folder_title() { return this.folder.title },
		unit_component() {
			// trace up to find the unit_component that is an ancestor of this folder
			let comp = this.$parent
			while (comp && comp.is_collection_unit_component !== true) comp = comp.$parent
			return comp
		},
		course_guidance_items() {
			return this.unit_component.course_guidance_items
		},
		folder_resource_list: {
			get() {
				if (!this.folder.children) { console.log('NO FOLDER.CHILDREN');	return []; }	// shouldn't happen
				// console.log('folder_resource_list computed running...', this.folder_id, object_copy(this.folder.children))

				// if we're pulling items for the course_guidance folder, ALWAYS pull from unit 1, even if we're showing for unit 2/3/4/etc
				// E.G.: let this_unit = (if we're viewing unit 1) ? this.unit : unit 1

				////////////////////////////////////////////
				// add_item helper function
				const add_item = (item, type, item_id, flag) => {
					// skip resources that don't match search results
					if (this.search_results_items.length > 0 && !this.search_results_items.find(x=>x.type==type && x.item_id==item_id)) return

					// note that the restrictions below also have to be coded in CollectionUnit.base_unit_resource_count
					// and in n_resources below

					// don't show "restricted" items if the user has a studentish_role (which will be the case if the user isn't signed in)
					if (item.restricted && this.studentish_role) return

					// hide/show items marked as block or traditional; note that 'traditional' resource == 'normal' term_mode; but don't hide when editing
					if (!this.enable_collection_editing) {
						if (item?.block_or_traditional == 'block' && this.term_mode != 'block') return
						if (item?.block_or_traditional == 'traditional' && this.term_mode != 'normal') return
					}
					
					// if we're limiting access to teacher items, this is a non-staff user, and this is a course collection...
					if (!this.show_all_items_when_not_signed_in && this.studentish_role && this.collection.collection_type == 'course') {
						// the user can't see the item unless it's explicicly marked as family_avail, or it's part of a tcc collection
						if (!(item.family_avail || item.tcc_folder_id)) return
					}

					// items marked as leader_resource can only be viewed by admins and principals/assistant principals
					if (item.lp_category == 'leader_resource') {
						if (!(this.$store.getters.user_is_principal_or_ap || this.is_collection_admin)) {
							return
						}
					}

					// note that resources the user shouldn't see because of their role will be filtered out when we first add the resources (in learning_progression.js)

					// else add if the item is one of this folder's children
					if (this.folder.children.find(x=>x==item_id) || flag == 'is_course_guidance_item') {
						let folder_assignment = this.unit.resource_tree.folder_assignments.find(x=>x.resource_id == item_id && x.parent_folder_id == this.folder_id && x.type == type)
						// MC: We want to use the folder assignments of unit 1 for course guidance items
						if (flag == 'is_course_guidance_item') folder_assignment = this.collection.units[0].resource_tree.folder_assignments.find(x=>x.resource_id == item_id && x.type == type)

						if (!folder_assignment) { 
							console.log('BAAD!!', object_copy(item)); 
							return 
						}	// shouldn't happen

						// console.log('adding', item)
						// BUT, if there's already something at this sequence number, do our best to put it in a good place; this shouldn't in theory happen, but stuff happens...
						if (arr[folder_assignment.seq]) {
							// put it as close to possible to where seq says it should go...
							let added = false
							for (let seq = folder_assignment.seq+1; seq < arr.length; ++seq) {
								if (!arr[seq]) {
									// console.warn('added at ' + seq)
									arr[seq] = item
									added = true
									break
								}
							}
							if (!added) {
								// console.warn('pushed: ' + arr.length)
								arr.push(item)
							}
						} else arr[folder_assignment.seq] = item
					}
					
					// PW 4/13/2024: this clause would include items that don't have any folder assignments in the top folder.
					// we used to do this, but it should happen anymore, and may slow things down, so we'll leave it out for now.
					// IF WE HAVE DIFFICULTY WITH ITEMS NOT APPEARING, WE MAY WANT TO BRING THIS BACK AS A QUICK FIX...
					// } else if (this.folder_id == 'top') {
					// 	if (this.unit.resource_tree.folder_assignments.find(x=>x.resource_id == item_id)) return
					// 	arr.push(item)
					// }
				}

				/////////////////////////////////////////
				// start of logic for folder_resource_list proper
				let arr = []
				// add resources and lessons
				for (let resource of this.unit.resources) add_item(resource, 'resource', resource.resource_id)
				for (let lesson of this.unit.lessons) add_item(lesson, 'lesson', lesson.lesson_id)
				
				// for the course_guidance folder, we want to include items from other course_guidance folders in the same collection -- unless we're in edit mode
				if (!this.folder_being_edited && this.in_temp_course_guidance_folder && this.course_guidance_items['course_guidance']) {
					if (this.folder.folder_id.startsWith('course_guidance')) {
						for (const item of this.course_guidance_items['course_guidance'].items) {
							if (!empty(item.lesson_id)) add_item(item, 'lesson', item.lesson_id, 'is_course_guidance_item')
							else add_item(item, 'resource', item.resource_id, 'is_course_guidance_item')
						}
					} else {
						if (this.folder.folder_id in this.course_guidance_items) {
							for (const item of this.course_guidance_items[this.folder.folder_id].items) {
								if (!empty(item.lesson_id)) add_item(item, 'lesson', item.lesson_id, 'is_course_guidance_item')
								else add_item(item, 'resource', item.resource_id, 'is_course_guidance_item')
							}
						}
					}

				}

				// now deal with sub-folders
				for (let folder of this.unit.resource_tree.folders) {
					if (folder.parent_folder_id == this.folder_id) {
						// import tcc items if necessary
						if (folder.rcis && U.object_has_keys(folder.rcis)) {
							// don't show tcc's if not signed in
							// TODO: also don't show tcc's to students/parents viewing a course they're not taking
							if (this.signed_in) this.import_tcc_items_for_rc_folder(folder)
						}

						// create a "fake resource" for the folder
						let ffr = {
							type: 'folder',
							resource_id: folder.folder_id
						}
						// as above, if there's already something at this sequence number, splice or push
						// if (arr[folder.seq]) arr.splice(folder.seq, 0, ffr)
						if (arr[folder.seq]) arr.push(ffr)
						else arr[folder.seq] = ffr
					}
				}

				// remove empty vals, which can happen if/when items are deleted
				for (let i = arr.length-1; i >= 0; --i) {
					if (empty(arr[i])) {
						// console.log('removing', i, arr[i])
						arr.splice(i, 1)
					}
				}

				// for default/shadow-unit folders, sort
				if (this.is_default_collection || this.is_shadow_unit) {
					if (this.$store.state.lst.default_collection_sort_by == 'created_at') {
						if (this.$store.state.lst.default_collection_sort_by_created_at_order == 'asc') {
							arr.sort((a,b)=>{
								// the first two lines here make it so that folders always come first
								if (a.created_at && !b.created_at) return 1
								if (b.created_at && !a.created_at) return -1
								return U.natural_sort(b.created_at+'', a.created_at+'')
							})
						} else {
							arr.sort((a,b)=>{
								if (a.created_at && !b.created_at) return 1
								if (b.created_at && !a.created_at) return -1
								return U.natural_sort(a.created_at+'', b.created_at+'')
							})
						}
					} else {
						arr.sort((a,b)=> {
							if (a.created_at && !b.created_at) return 1
							if (b.created_at && !a.created_at) return -1
							if (a.lesson_title && b.lesson_title) return U.natural_sort(a.lesson_title, b.lesson_title)
							if (a.description && b.description) return U.natural_sort(a.description, b.description)
							if (a.description && b.lesson_title) return U.natural_sort(a.description, b.lesson_title)
							if (a.lesson_title && b.description) return U.natural_sort(a.lesson_title, b.description)
						})
					}
				}

				return arr
			},
			set(val) {
				// this needs to be structured as a computed like this for the draggable component to allow reordering
				// console.log(val)
			},
		},
		is_top_level_folder() { return this.folder.parent_folder_id == 'top' },
		folder_color() {
			// we only use colors for top-level folders
			// if (!this.is_top_level_folder) return ''
			return this.folder.color
		},
		folder_css_class() {
			let s = ''
			if (this.folder_id != 'top') s += ` k-resource-folder k-resource-folder-${this.collection_view_mode}`
			if (this.folder_open) s += ' k-resource-folder-opened'
			if (this.folder_color) s += ` k-resource-folder-colored k-resource-folder-colored-${this.folder_color}`

			if (this.last_viewed_resource_id == this.folder.folder_id) s += ' k-resource-folder-last-viewed'
			return s
		},
		folder_header_css_class() {
			let s = ''
			if (this.folder_color) s += ` k-resource-folder-header-colored k-resource-folder-header-colored-${this.folder_color}`
			return s
		},
		folder_header_inner_css_class() {
			let s = ''
			if (this.folder_color) s += ` k-resource-folder-header-inner-colored k-resource-folder-header-inner-colored-${this.folder_color}`
			return s
		},
		folder_resources_css_class() {
			let s = ''
			if (this.folder_color) s += ` k-resource-folder-resources-colored k-resource-folder-resources-colored-${this.folder_color}`
			return s
		},
		folder_icon() {
			// if (this.folder_color) return 'fa-th-list'
			if (this.folder_open) return 'fa-folder-open'
			else return 'fa-folder'
		},
		folder_icon_css() {
			// return U.subject_tile_css(this.collection) + '-text'
			return U.get_contrast_color(U.get_collection_color(this.collection, 'text'))
		},
		n_resources() {
			let resource_ids = []
			this.in_last_search_results = false
			this.descendent_resource_ids = []

			let n_in_folder = (folder_id) => {
				let n = 0
				let folder = this.unit.resource_tree.folder_hash[folder_id]
				if (!folder) return 0
				for (let resource_id of folder.children) {
					// the resource will be shown if we don't have search results, or if the resource was found by the search
					if (this.search_results_items.length > 0) {
						if (!this.search_results_items.find(x=>x.item_id==resource_id)) continue
					}

					// do some extra checks for non-tcc items -- see folder_resource_list (we don't do these for tcc items because sometimes tccs are huge, and we get a delay from looking up the tcc item)
					if (!folder.tcc_folder_id) {
						if (isNaN(resource_id*1)) {
							let item = this.unit.resources.find(x=>x.resource_id == resource_id)
							if (item) {
								if (item.restricted && this.studentish_role) continue

								if (!this.enable_collection_editing) {
									if (item?.block_or_traditional == 'block' && this.term_mode != 'block') continue
									if (item?.block_or_traditional == 'traditional' && this.term_mode != 'normal') continue
								}
								
								// if we're limiting access to teacher items, this is a non-staff user, and this is a course collection...
								if (!this.show_all_items_when_not_signed_in && this.studentish_role && this.collection.collection_type == 'course') {
									// the user can't see the item unless it's explicicly marked as family_avail, or it's part of a tcc collection
									if (!(item.family_avail || item.tcc_folder_id)) continue
								}

								// items marked as leader_resource can only be viewed by admins and principals/assistant principals
								if (item.lp_category == 'leader_resource') {
									if (!(this.$store.getters.user_is_principal_or_ap || this.is_collection_admin)) {
										continue
									}
								}
							}
						} else {
							let item = this.unit.lessons.find(x=>x.lesson_id == resource_id)
							if (item && item.restricted && this.studentish_role) continue
						}
					}
					++n
					if (!resource_ids.includes(resource_id)) resource_ids.push(resource_id)
					// also note here if we find a descendent item that is in the last search results
					if (this.$store.state.collection_last_search_results.includes(resource_id)) this.in_last_search_results = true
				}

				for (let folder of this.unit.resource_tree.folders) {
					if (folder.parent_folder_id == folder_id) {
						resource_ids.push(folder.folder_id)
						n += n_in_folder(folder.folder_id)
						// also note here if we find a descendent item that is in the last search results (does this apply for folders?)
						if (this.$store.state.collection_last_search_results.includes(folder.folder_id)) this.in_last_search_results = true
					}
				}
				return n
			}

			// if the folder doesn't have any items that passed the add_item criteria (see above), we surely don't have any items in the folder
			// if (this.folder_resource_list.length == 0) return 0

			// else use this recursive fn to do a deep count of the folder and its subfolders
			let n = n_in_folder(this.folder_id)

			// add count of any extra course guidance items
			if (this.in_temp_course_guidance_folder && (!this.folder_being_edited && this.folder_id.startsWith('course_guidance') && 'course_guidance' in this.course_guidance_items)) n += this.course_guidance_items['course_guidance'].count
			if (this.in_temp_course_guidance_folder && (!this.folder_being_edited && this.folder_id in this.course_guidance_items)) n += this.course_guidance_items[this.folder_id].count
			if (this.folder_id == 'top' && this.course_guidance_items['course_guidance']) n += this.course_guidance_items['course_guidance'].count

			this.descendent_resource_ids = resource_ids
			return n
		},
		n_resources_word() { return U.ps('resource', this.n_resources) },
		show_folder() {
			if (this.is_course_guidance_subfolder && this.enable_collection_editing) return false
			// always show all folders to collection admins if we don't have search results...
			if (this.is_collection_admin && this.search_results_items.length == 0) {
				// but only if we're in edit mode
				if (this.enable_collection_editing) return true
			}
			// otherwise, don't show the folder if the folder wouldn't have any resources showing (note that n_resource searches down the tree)
			return (this.n_resources > 0)
		},
		folder_todo_count() {
			return 0
			let n_in_folder = (folder_id) => {
				let n = 0
				for (let fa of this.unit.resource_tree.folder_assignments) {
					if (fa.parent_folder_id == folder_id) {
						let resource = this.unit.resources.find(x=>x.resource_id == fa.resource_id)

						// if the resource is marked todo
						if (resource.todo) {
							// then if the user hasn't completed it, add to the count
							// note that the todo_status for a video may be 5-95, indicating partial completion
							if (!(this.user_info.todo_status[resource.resource_id] > 100)) {
								++n
							}
						}
					}
				}
				for (let folder of this.unit.resource_tree.folders) {
					if (folder.parent_folder_id == folder_id) {
						n += n_in_folder(folder.folder_id)
					}
				}
				return n
			}
			return n_in_folder(this.folder_id)
		},
		folder_open: {
			get() { 
				if (this.folder_id == 'top') return true
				return this.$store.state.lst.collections_opened_folders[this.folder_id] == 'open'
			},
			set(val) { 
				if (this.folder_id == 'top') return
				let o = extobj(this.$store.state.lst.collections_opened_folders)
				if (val) o[this.folder_id] = 'open'
				else delete o[this.folder_id]
				this.$store.commit('lst_set', ['collections_opened_folders', o]) 
			}
		},
		tcc_folder() { 
			if (empty(this.folder.tcc_folder_id)) return null
			return this.unit.resource_tree.folders.find(x=>x.folder_id == this.folder.tcc_folder_id)
		},
		rcis_checkbox_checked: {
			get() {
				if (!this.tcc_folder) return false
				for (let rcid in this.tcc_folder.rcis) {
					if (!this.collection.resource_collections.find(x=>x.resource_id==rcid)) continue
					if (this.tcc_folder.rcis[rcid].includes(this.folder.folder_id)) return true
				}
				return false
			},
			set(val) {
				// when checkbox is checked...
				for (let rcid in this.tcc_folder.rcis) {
					if (!this.collection.resource_collections.find(x=>x.resource_id==rcid)) continue

					let i = this.tcc_folder.rcis[rcid].indexOf(this.folder.folder_id)
					if (i > -1) {
						// if it was already in the rcis array, take it out
						this.$store.commit('set', [this.tcc_folder.rcis[rcid], 'SPLICE', i])
					} else {
						// otherwise add it in
						this.$store.commit('set', [this.tcc_folder.rcis[rcid], 'PUSH', this.folder.folder_id])
					}
					// for now we assume a single tcc per folder
					return
				}
			},
		},
		rcis_parent_or_child_included() {
			if (!this.tcc_folder) return false
			if (this.rcis_checkbox_checked) return false

			// first check for parents
			let comp = this
			while (comp && comp != vapp) {
				if (comp.rcis_checkbox_checked) {
					return true
				}
				comp = comp.$parent
			}

			// then check for descendents
			for (let resource_id of this.descendent_resource_ids) {
				for (let rcid in this.tcc_folder.rcis) {
					if (!this.collection.resource_collections.find(x=>x.resource_id==rcid)) continue
					if (this.tcc_folder.rcis[rcid].includes(resource_id)) return true
				}
			}

			return false
		},
		in_temp_course_guidance_folder() {
			return (this.folder.folder_id.startsWith('course_guidance') || this.folder.folder_id in this.course_guidance_items) && this.unit.lp_unit_id !== this.collection.units[0].lp_unit_id
		},
		is_course_guidance_subfolder() {
			return (this.folder.folder_id in this.course_guidance_items) && this.unit.lp_unit_id !== this.collection.units[0].lp_unit_id
		}
	},
	created() {
		this.import_tcc_items()
	},
	mounted() {
	},
	methods: {
		import_tcc_items() {
			for (let folder of this.unit.resource_tree.folders) {
				if (folder.parent_folder_id == this.folder_id) {
					// import tcc items if necessary
					if (folder.rcis && U.object_has_keys(folder.rcis)) {
						// don't show tcc's if not signed in
						// TODO: also don't show tcc's to students/parents viewing a course they're not taking
						if (this.signed_in) this.import_tcc_items_for_rc_folder(folder, (this.enable_collection_editing?'load_all':'reload'))
					}
				}
			}
		},

		// traverse a resource collection generated from a common cartridge (i.e. HMH resources in HenryConnects) and pull in selected items and sub-items
		import_tcc_items_for_rc_folder(tcc_folder, flag) {
			if (!tcc_folder.rcis || !U.object_has_keys(tcc_folder.rcis)) return		// shouldn't happen

			let this_store = this.$store
			let this_unit = this.unit
			let studentish_role = this.studentish_role
			let this_role = this.role
			let tcc_resources_added = {}

			// if flag is non-empty, clear existing folder_assignments first
			// TODO: have to deal with this
			if (!empty(flag)) {
				// first build up a list of items to clear
				let fas_to_clear = []
				let folders_to_clear = []
				function clear_existing_fas(folder_id) {
					for (let i = 0; i < this_unit.resource_tree.folder_assignments.length; ++i) {
						let fa = this_unit.resource_tree.folder_assignments[i]
						if (fa.parent_folder_id == folder_id) {
							fas_to_clear.push(i)
							// remove the resources here too; we'll add them back below
							let index = this_unit.resources.findIndex(x=>x.resource_id==fa.resource_id)
							if (index > -1) this_unit.resources.splice(index, 1)
						}
					}
					for (let i = 0; i < this_unit.resource_tree.folders.length; ++i) {
						let folder = this_unit.resource_tree.folders[i]
						if (folder.parent_folder_id == folder_id) {
							clear_existing_fas(folder.folder_id)
							folders_to_clear.push(i)
							// clear the folder from folder_hash
							if (this_unit?.resource_tree?.folder_hash) delete this_unit.resource_tree.folder_hash[folder_id]
						}
					}
				}
				clear_existing_fas(tcc_folder.folder_id)
				// clear the tcc_folder's children array, and add the tcc_folder back to the hash
				this.$store.commit('set', [tcc_folder, 'children', []])
				this.$set(this_unit.resource_tree.folder_hash, tcc_folder.folder_id, tcc_folder)

				// then clear them by re-creating the arrays
				let new_fas = []
				for (let i = 0; i < this_unit.resource_tree.folder_assignments.length; ++i) {
					if (!fas_to_clear.includes(i)) new_fas.push(this_unit.resource_tree.folder_assignments[i])
				}
				// console.log(tcc_folder.folder_id + ' new_fas: ' + new_fas.length + ' / old fas: ' + this.unit.resource_tree.folder_assignments.length)
				this.$store.commit('set', [this.unit.resource_tree, 'folder_assignments', new_fas])

				let new_folders = []
				for (let i = 0; i < this_unit.resource_tree.folders.length; ++i) {
					if (!folders_to_clear.includes(i)) new_folders.push(this_unit.resource_tree.folders[i])
				}
				this.$store.commit('set', [this.unit.resource_tree, 'folders', new_folders])

				this.$store.commit('set', [tcc_folder, 'tcc_loaded', false])
				// note that we don't need to clear resources from the tcc out of the unit here (we do that if the unit is saved)
			}

			if (tcc_folder.tcc_loaded) { 
				// console.log('tcc items already loaded...')
				return 
			}
			// console.log('loading tcc items...')

			// later we can extend this to allow for items from multiple collections in the same unit folder; for now we only allow for one
			let rc
			let collection_inclusions	// note that this could be empty; if so, *everything* from the tcc is included
			for (let rcid in tcc_folder.rcis) {
				rc = this.collection.resource_collections.find(x=>x.resource_id==rcid)
				if (rc) {
					// if flag is 'load_all', leave collection_inclusions so we show everything
					// if flag is 'reload', we will clear the existing fas above and reload based on the current state of tcc_folder.rcis
					if (flag != 'load_all') collection_inclusions = tcc_folder.rcis[rcid]
					break
				}
			}
			if (empty(rc)) return	// shouldn't happen
			if (empty(collection_inclusions) || collection_inclusions.length == 0) collection_inclusions = null

			// console.log('HERE -- TRAVERSING!', flag, tcc_folder.title, rc, collection_inclusions)

			traverse_tcc_collection(rc.collection_json, false, tcc_folder.folder_id, 0, 0)

			// mark that the tcc items have been loaded, so we don't reload them later
			this.$store.commit('set', [tcc_folder, 'tcc_loaded', true])

			function traverse_tcc_collection(node, parent_included, parent_folder_id, next_seq_val, level=0) {
				let s = 'traverse_tcc_collection ' + level; for (let i = 0; i < level; ++i) s = '  ' + s
				
				// if this is a folder/node
				// node.c means it's a folder/node
				if (!empty(node.c)) {
					// node_included = (parent_included == true || everything is included || the node is explicitly included)
					let node_included = (parent_included == true || empty(collection_inclusions) || collection_inclusions.includes(node.f))

					// the node_folder_id for this node's children is the incoming parent_folder_id for level 0, or node.f otherwise
					let node_folder_id = (level == 0) ? parent_folder_id : node.f
					let node_title = node.t

					// If a folder includes nothing but another folder (in HMH, "Lesson 1: How are Living Things Grouped" -> "Lesson-Level Resources")...
					if (node.c.length == 1 && !empty(node.c[0].c)) {
						// ... load children of the subfolder, but use the title/folder_id of node
						node = node.c[0]
					}

					let included_children_count = 0
					let node_children = []
					// for each child, drill down, passing parent_included = node_included, and sending the current included_children_count as next_seq_val
					for (let child of node.c) {
						let rv = traverse_tcc_collection(child, node_included, node_folder_id, included_children_count, level+1)

						// if we get something back:
						if (rv != null) {
							// if we get back a resource, add the folder_assignment for the resource (we know at this point the folder will be included)
							if (!empty(rv.resource_id)) {
								node_children.push(rv)
							}
							// increment included_children_count whether the added child was a resource or a folder; remember that this value will be the next_seq_val of the next child
							++included_children_count
						}
					}

					// once we get to here for level 0, return
					if (level == 0) {
						// push to children of the top folder
						return

					} else if (node_included || included_children_count > 0) {
						// don't add empty folders here
						if (included_children_count == 0) return null

						// only now (once we know it should be included), create a folder for the node and add to resource_tree.folders
						// console.log(`add node_folder: ${node_title} / folder_id ${node_folder_id} / parent_folder_id ${parent_folder_id}`)
						let node_folder = this_unit.create_resource_folder({title:node_title, parent_folder_id:parent_folder_id, folder_id:node_folder_id, seq:next_seq_val, tcc_folder_id:tcc_folder.folder_id, items:node_children})

						// return the node_folder
						// console.log(s, ` add node (${included_children_count}) ${node_folder.title}`, node_folder)
						return node_folder

					// else return null
					} else {
						// console.log(s, 'skip node ' + node_folder.title)
						return null
					}

				// else it's a resource/leaf
				} else if (!empty(node.r)) {
					// node_included = (parent_included == true || everything is included || the node is explicitly included)
					let node_included = (parent_included == true || empty(collection_inclusions) || collection_inclusions.includes(node.r))

					// if node_included:
					if (node_included) {
						// if the resource is already in this_unit.resources, don't add it again
						let r = tcc_resources_added[node.r]
						if (r) {
							// console.log('resource already added, but sometimes they include the same item multiple places in the tree...')
							return r
						}

						// teacher_facing is normally based on the "node.i" value...
						let teacher_facing = (node.i == 1)	// note magic number for HMH resources...

						// ...but to fix some HMH bugs, make sure that anything that has certain strings in the title is teacher facing
						if (node.t.search(/(assessment)|(teacher ebook)|(teacher edition)|(answer key)/i) > -1) teacher_facing = true
						// ...and that resources with "student ebook" in the title are student facing
						if (node.t.search(/student ebook/i) > -1) teacher_facing = false

						// don't load teacher facing items for students or parents
						if (teacher_facing && studentish_role) return null

						// else create the resource
						r = new Resource({
							resource_id: node.r,
							type: 'collection_item',
							teacher_facing: teacher_facing,
							description: node.t,
							tcc_folder_id: tcc_folder.folder_id,
						})
						tcc_resources_added[r.resource_id] = r

						// push the resource to unit.resources
						this_store.commit('set', [this_unit.resources, 'PUSH', r])

						// return the resource
						// console.log(s, ' add leaf ' + r.description)
						return r

					// else return null
					} else {
						// console.log(s, 'skip leaf ' + node.t)
						return null
					}	
				}
			}
		},

		// send issue report requests to vapp
		report_issue(issue_params) {
			// issue_params comes in with resource specified; add unit
			issue_params.lp_unit = this.unit
			vapp.report_issue(issue_params)
		},

		resource_collection_item_container_class(resource) {
			let s = 'k-resource-folder-resource-container-width-' + (this.full_width_resource(resource) ? 'full' : 'half')
			if (resource.type == 'folder') {
				s += ' k-resource-folder-resource-container-folder'
				if (this.$store.state.lst.collections_opened_folders[resource.resource_id] == 'open') s += ' k-resource-folder-resource-container-folder-open'
				else s += ' k-resource-folder-resource-container-folder-closed'
			}
			return s
		},

		resource_collection_item_class(resource, hover) {
			let s = ''
			if (this.full_width_resource(resource)) s += ' k-resource-folder-resource-item-full-wrapper'
			else s += ' k-resource-folder-resource-item-tile-wrapper'
			if (hover) s += ' k-resource-folder-resource-item-hovered'
			if (this.enable_collection_editing) {
				if (this.allow_resource_reordering) s += ' k-resource-folder-resource-item-full-wrapper-reordering'
			}
			return s
		},

		full_width_resource(resource) {
			// always show folders in full-width mode
			if (resource.type == 'folder') return true

			// allow for force_full_width -- used when in unit editor mode
			if (this.force_full_width) return true

			// otherwise show in full-width mode if we're in 'list' mode for collections
			return this.collection_view_mode == 'list'
		},

		toggle_folder_open() {
			// don't allow the folder to be open if there are no resources, unless we're in edit mode
			if (this.n_resources == 0 && !this.enable_collection_editing && !this.folder_open) return

			this.folder_open = !this.folder_open

			this.$store.commit('set', ['last_viewed_resource_id', (this.folder_open) ? this.folder.folder_id : ''])
		},

		remove_item(item, flag) {
			// we have to check the lp out for editing before being able to remove, unless we're in my_content or the sandbox or the unit editor
			if (!(this.is_default_collection || this.is_shadow_unit || this.folder_being_edited)) {
				if (flag != 'checked_out') {
					this.$store.dispatch('edit_access_control', {lp_id: this.collection.lp_id, lp_updated_at: this.collection.updated_at, action: 'checkout'}).then((result)=>{
						console.log('edit request (remove_item): ' + result.status)
						this.remove_item(item, 'checked_out')
					}).catch((e)=>{
						// if this doesn't work, don't enter edit mode
						console.log('error requesting editor checkout for remove_item', e)
					})
					return
				}
			}

			// NOTE: when we remove an item, we don't actually delete the item itself from the database; we just remove the item from the collection it's in
			// the to-be-removed item could be a resource or lesson
			const display_type = item.lesson_id ? 'Lesson' : item.type == 'sparkl' ? `${this.site_config.sparkl_app_name} Student Activity` : 'Resource'

			if (!this.$store.state.confirm_resource_removals) {
				this.remove_item_confirmed(item)
				return
			}

			this.$confirm({
				title: 'Please Confirm',
				text: `Are you sure you want to remove this ${display_type}?`,
				secondaryCheckbox: 'Don’t ask for confirmation of subsequent removals',
				secondaryCheckboxInitiallyOn: false,
				acceptText: `Remove ${display_type}`,
				acceptIcon: 'fas fa-trash-alt',
				acceptColor: 'red darken-2',
			}).then(arr => {
				// set confirm_resource_removals in store -- if the box is checked, it means they *don't* want to confirm in the future
				this.$store.commit('set', ['confirm_resource_removals', !arr[1]])
				this.remove_item_confirmed(item)

			}).catch(arr => {
				// if we checked out, need to check back in
				if (flag == 'checked_out') this.$store.dispatch('edit_access_control_checkin', {lp_id: this.collection.lp_id})
			}).finally(f => { });
		},

		remove_item_confirmed(item) {
			// remove the item, from the unit resources/lessons and from folder_assignments
			this.unit.remove_item(item)

			// call save_unit_edits immediately if we're not in the context of the unit editor, where we want the user to be able to cancel
			if (!this.folder_being_edited) this.$emit('save_unit_edits', 'then_checkin')
		},

		// this is called from ResourceEditor or LessonEditor, via ResourceCollectionItem or ResourceSearch
		// important note: this fn is only called when an *already-existing* item is edited;
		// new items are added via ResourceSearch, which calls add_items_from_search in the CollectionResourceFolder; that fn that adds the item(s) to the unit/folders and calls save_unit_edits
		// note that a version of this fn is also in DirectivesWrapper
		edit_item_saved(args) {
			// args will include `type` and `updated_resource` or `updated_lesson`
			// console.log('edit_item_saved!!', args)

			if (args.type == 'resource' || args.type == 'activity') {
				// console.log('edit_item_saved in CollectionResourceFolder for resource')

				// sparkl activities are saved as resources
				let updated_resource = new Resource(args.updated_resource)

				// call update_content_item mutation to make sure the item's changes are reflected everywhere
				this.$store.commit('update_content_item', updated_resource)

				// we also have to update the lesson in this.unit.lessons, because when we're in the unit editor, we're working from a copy of the unit
				let index = this.unit.resources.findIndex(x=>x.resource_id == updated_resource.resource_id)
				if (index != -1) {
					this.unit.resources.splice(index, 1, updated_resource)
				}

				// the user may have just changed a sparkl resource that has an associated “activity” record; if so, save the activity record if needed
				let a = this.$store.state.my_activities.find(x=>x.resource_id == updated_resource.resource_id)
				if (a) {
					let changed = false
					if (a.activity_title != updated_resource.description) {
						a.activity_title = updated_resource.description
						changed = true
					}
					if (a.activity_description != updated_resource.long_description) {
						a.activity_description = updated_resource.long_description
						changed = true
					}
					if (changed) {
						console.warn('saving updated activity')
						let payload = {
							activity_class: 'teacher',
							activity_data: a.copy_for_save(),
						}
						this.$store.dispatch('save_activity', payload).then((result)=>{
							// recreate the activity from returned data, and update via add_to_my_activities
							let new_activity = new Activity(result.activity)
							this.$store.commit('add_to_my_activities', new_activity)
						})
					}
				}

			} else if (args.type == 'lesson') {
				// this will be called by ResourceCollectionItem for editing an existing lesson, or by ResourceSearch for making an additional change to an already-saved new lesson. Note the difference here between lessons and resources: once you create a new resource via ResourceSearch, the resource editor is immediately closed; whereas a newly-created lesson can be edited even after it's saved
				let updated_lesson = args.updated_lesson

				// apply *_showing values from edited_lesson if we have it
				if (args.edited_lesson) {
					updated_lesson.resources_showing = args.edited_lesson.resources_showing
					updated_lesson.standards_showing = args.edited_lesson.standards_showing
					updated_lesson.student_description_showing = args.edited_lesson.student_description_showing
					for (let i = 0; i < args.edited_lesson.lesson_plan.length; ++i) {
						updated_lesson.lesson_plan[i].lc_showing = args.edited_lesson.lesson_plan[i].lc_showing
						updated_lesson.lesson_plan[i].lc_open_for_editing = args.edited_lesson.lesson_plan[i].lc_open_for_editing
					}
				}

				// call update_content_item mutation to make sure the item's changes are reflected everywhere
				let l = new Lesson(updated_lesson)
				this.$store.commit('update_content_item', l)

				// we also have to update the lesson in this.unit.lessons, because when we're in the unit editor, we're working from a copy of the unit
				let index = this.unit.lessons.findIndex(x=>x.lesson_id == updated_lesson.lesson_id)
				if (index != -1) {
					this.unit.lessons.splice(index, 1, l)
				}
			}
		},

		edit_item_cancel(args) {
			// don't need to do anything here
		},

		/////////////////////////////////////////////////////////////
		search_start() {
			// add a placeholder resource so we know where to put selected item(s)
			this.resource_search_placeholder = new Resource({
				resource_id: 'new',
				description: '<b>Added item(s)...</b>', 
				teacher_facing: true,
			})
			// mark the placeholder item so that we don't save it (see learning_progressions.js)
			this.resource_search_placeholder.placeholder = true

			// push the placeholder resource; when the user is done searching/adding, we'll splice it back out in add_items_from_search_cancel
			this.unit.resources.push(this.resource_search_placeholder)
			this.unit.add_item_to_folder({item:this.resource_search_placeholder, parent_folder_id:this.folder_id, placeholder:true})
			this.$store.commit('set', ['last_viewed_resource_id', this.resource_search_placeholder.resource_id])

			// show the search dialog
			this.show_search = true
		},

		add_items_from_search(items) {
			// find the resource_search_placeholder in folder_assignments
			let folder_assignment_placeholder = this.unit.resource_tree.folder_assignments.find(x=>x.resource_id==this.resource_search_placeholder.resource_id)

			// go through each item...
			for (let item of items) {
				// add to resources/lessons. Note that "items" here are ResourceSearch "search results" objects, where type can be 'lesson', 'activity' (sparkl resource), or 'resource' (non-sparkl resource)
				if (item.type == 'lesson') this.unit.lessons.push(new Lesson(item.value))
				else if (item.type == 'resource' || item.type == 'activity') this.unit.resources.push(new Resource(item.value))

				// add to folder_assignments, initially in place of the folder_assignment_placeholder; then increment seq of folder_assignment_placeholder. this makes added items appear in order before the placeholder
				this.unit.add_item_to_folder({
					type: (item.type == 'lesson') ? 'lesson' : 'resource',
					resource_id: item.item_id,
					parent_folder_id: folder_assignment_placeholder.parent_folder_id,
					seq: folder_assignment_placeholder.seq
				})
				folder_assignment_placeholder.seq += 1
			}

			// call save_unit_edits immediately if we're not in the context of the unit editor, where we want the user to be able to cancel
			if (!this.folder_being_edited) this.$emit('save_unit_edits', 'add_items_from_search')
			this.$inform(`${items.length} ${U.ps('item', items.length)} added`)
		},

		add_duplicated_items(args) {
			// based on add_items_from_search
			let {original_item, duplicated_items} = args	
			// we're not currently using original_item, but we could, e.g., for determining where to position the duplicated items in the folder

			// go through each item...
			for (let item of duplicated_items) {
				// add to the unit's resources or lessons, and get args for add_item_to_folder below
				let item_type, item_id
				if (item.lesson_id) {
					item_type = 'lesson'
					item_id = item.lesson_id
					this.unit.lessons.push(new Lesson(item))
				} else {
					item_type = 'resource'
					item_id = item.resource_id
					this.unit.resources.push(new Resource(item))
				}
				console.warn('here', {
					type: item_type,
					resource_id: item_id,
					parent_folder_id: this.folder.folder_id,
					seq: this.unit.get_max_folder_sequence(this.folder.folder_id) + 1,
				})

				// add to folder_assignments, at the bottom of the folder
				this.unit.add_item_to_folder({
					type: item_type,
					resource_id: item_id,
					parent_folder_id: this.folder.folder_id,
					seq: this.unit.get_max_folder_sequence(this.folder.folder_id) + 1,
				})
			}

			// call save_unit_edits immediately if we're not in the context of the unit editor, where we want the user to be able to cancel
			if (!this.folder_being_edited) this.$emit('save_unit_edits', 'then_checkin')
			if (duplicated_items.length > 1) this.$inform(`${items.length} ${U.ps('item', items.length)} duplicated`)
		},

		search_cancel() {
			// remove the placeholder item from the unit
			this.unit.remove_item({item: this.resource_search_placeholder})

			this.resource_search_placeholder = null
			this.show_search = false

			// emit event for parent to handle -- e.g. CollectionUnit may clear the edit lock
			this.$emit('resource_search_closed')
		},

		drag_complete(evt) {
			// get resource_id moved
			let item_moved_resource_id = oprop(evt, 'item', 'attributes', 'data-resource_id', 'nodeValue')

			// get resource_id of the folder we moved to
			let moved_to_folder_id = oprop(evt, 'to', 'attributes', 'data-resource_id', 'nodeValue')
			// let moved_from_resource_id = oprop(evt, 'from', 'attributes', 'data-resource_id', 'nodeValue')

			// construct a list of updates we need to make
			let updates = []

			// start with items other than the moved item in the moved_to folder
			let to_children = $(evt.to).children()
			for (let i = 0; i < to_children.length; ++i) {
				// skip the moved item if we encounter it; also skip the "footer" if there is one
				let child_resource_id = $(to_children[i]).attr('data-resource_id')
				if (child_resource_id == item_moved_resource_id || empty(child_resource_id)) continue

				updates.push({
					resource_id: child_resource_id,
					parent_folder_id: moved_to_folder_id,
				})
			}
			// add the moved item at position newIndex
			updates.splice(evt.newIndex, 0, {
				resource_id: item_moved_resource_id,
				parent_folder_id: moved_to_folder_id,
			})
			// console.log(updates)

			// update folder sequences
			for (let i = 0; i < updates.length; ++i) {
				let update = updates[i]
				let folder_assignment = this.unit.resource_tree.folder_assignments.find(o=>o.resource_id==update.resource_id)
				if (empty(folder_assignment)) {
					// if empty the item should be a folder
					folder_assignment = this.unit.resource_tree.folders.find(o=>o.folder_id==update.resource_id)
				}
				folder_assignment.parent_folder_id = update.parent_folder_id
				folder_assignment.seq = i

				// if we moved it out of the current folder...
				if (moved_to_folder_id != this.folder_id) {
					// remove from the current folder's children
					let i = this.folder.children.indexOf(update.resource_id)
					if (i > -1) this.folder.children.splice(i, 1)

					// add to moved-to folder's children if needed
					let f = this.unit.resource_tree.folder_hash[moved_to_folder_id]
					if (f) {
						if (!f.children.find(x=>x==update.resource_id)) {
							// console.log('adding to moved-to folder')
							f.children.push(update.resource_id)
						} else {
							// console.log('not adding...')
						}
					}
				}
			}

			// call save_unit_edits immediately?? no; by not saving immediately the user can cancel and not have the changes apply; also saving makes things slow down signficantly when we have lots of hmh resources...
			// note that we only allow reordering from the unit editor interface
			// this.$emit('save_unit_edits')
		},

		create_resource_folder_start() {
			this.$prompt({
				title: 'New Resource Folder',
				text: 'Enter a title for your new resource folder:',
				acceptText: 'Create Folder',
				acceptIcon: 'fas fa-folder',
				cancelIcon: 'fas fa-times',
			}).then(folder_title => {
				if (!empty(folder_title)) {
					// add a new folder to resource_tree
					this.unit.create_resource_folder({title:folder_title, parent_folder_id:this.folder_id})

					// call save_unit_edits immediately if we're not in the context of the unit editor, where we want the user to be able to cancel
					if (!this.folder_being_edited) this.$emit('save_unit_edits')
				}
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		edit_folder_title() {
			this.$prompt({
				title: 'Edit Folder Title',
				text: 'Enter a new title for the folder:',
				acceptText: 'Save',
				initialValue: this.folder_title,
			}).then(folder_title => {
				if (!empty(folder_title)) {
					let folder = this.unit.resource_tree.folders.find(x=>x.folder_id==this.folder_id)
					folder.title = folder_title
					// call save_unit_edits immediately if we're not in the context of the unit editor, where we want the user to be able to cancel
					if (!this.folder_being_edited) this.$emit('save_unit_edits')
				}
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		edit_folder_color() {
			this.$prompt({
				title: 'Edit Folder Color',
				text: 'Select a color for the folder:',
				promptType: 'select',
				selectOptions: [{value:'', text: 'None (white)'}, 
					{value:'red', text: 'Red'},
					{value:'orange', text: 'Orange'},
					{value:'lime', text: 'Lime'},
					{value:'green', text: 'Green'},
					{value:'teal', text: 'Teal'},
					{value:'cyan', text: 'Cyan'},
					{value:'blue', text: 'Blue'},
					{value:'indigo', text: 'Indigo'},
					{value:'purple', text: 'Purple'},
					{value:'pink', text: 'Magenta'},
					{value:'brown', text: 'Brown'},
					{value:'blue-grey', text: 'Grey'},
				],
				initialValue: this.folder_color,
				acceptText: 'Select',
			}).then(color => {
				let folder = this.unit.resource_tree.folders.find(x=>x.folder_id==this.folder_id)
				this.$store.commit('set', [folder, 'color', color])
				// call save_unit_edits immediately if we're not in the context of the unit editor, where we want the user to be able to cancel
				if (!this.folder_being_edited) this.$emit('save_unit_edits')
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		remove_folder() {
			let text = sr('Are you sure you want to delete folder “<b>$1</b>”?', this.folder_title)
			if (this.n_resources > 0) {
				text += sr(' This will also remove the $1 $2 currently in the folder from the unit resource collection.', this.n_resources, this.n_resources_word)
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: text,
			    acceptText: 'Delete',
				acceptColor: 'red',
			}).then(y => {
				let remove_folder_recurse = (folder_id, unit) => {
					// go through all folder_assignments
					for (let i = unit.resource_tree.folder_assignments.length-1; i >= 0; --i) {
						let fa = unit.resource_tree.folder_assignments[i]
						// if we found something in this folder
						if (fa.parent_folder_id == folder_id) {
							// TODO: Don't delete the resource if it appears somewhere else...
							// delete the resource
							let index = unit.resources.findIndex(x=>x.resource_id==fa.resource_id)
							if (index > -1) unit.resources.splice(index, 1)
							index = unit.lessons.findIndex(x=>x.lesson_id==fa.resource_id)
							if (index > -1) unit.lessons.splice(index, 1)

							// and delete the folder_assignment
							unit.resource_tree.folder_assignments.splice(i, 1)
						}
					}
					// recurse to remove units and folders that are children of this folder
					for (let folder of unit.resource_tree.folders) {
						if (folder.parent_folder_id == folder_id) {
							remove_folder_recurse(folder.folder_id, unit)
						}
					}

					// remove this folder itself
					let index = unit.resource_tree.folders.findIndex(x=>x.folder_id == folder_id)
					if (index > -1) unit.resource_tree.folders.splice(index, 1)
					delete unit.resource_tree.folder_hash[folder_id]
				}
				remove_folder_recurse(this.folder_id, this.unit)
				if (this.folder_id in this.course_guidance_items) {
					for (const unit of this.collection.units) {
						remove_folder_recurse(this.folder_id, unit)
					}
				}
				// call save_unit_edits immediately if we're not in the context of the unit editor, where we want the user to be able to cancel
				if (!this.folder_being_edited) this.$emit('save_unit_edits')
			}).catch(n=>{console.log(n)}).finally(f=>{})

		},

		clear_last_viewed_resource() {
 			this.$store.commit('set', ['last_viewed_resource_id', null])
		},

		folder_checkbox_clicked() {

		},
	}
}
</script>

<style lang="scss">
$v-folder-border-color: #ddd;
$v-folder-border-radius: 6px;

.k-resource-folder {
	// border-top:1px solid #ddd;
	// border-bottom:1px solid #ddd;
	// padding:4px 0 4px 4px;
	// margin-top:-1px;
	padding-top:4px;
	padding-bottom:4px;

	.k-resource-folder-resources {
		padding-left:20px;
		margin-top:4px;
	}

	.k-resource-collection-item {
		margin:4px 4px;
	}
}

.k-resource-folder {
	border-radius:$v-folder-border-radius;
	margin:1px 0 1px 0;
}

.k-resource-folder {
	.k-resource-folder {
		border-radius:3px;
		margin:-1px 0 0 0;
		// margin:0;
	}

	.k-resource-folder.k-resource-folder-colored {
		margin-top:1px;
		margin-bottom:1px;
	}

	.k-resource-folder-resources-first-child > div:first-of-type > .k-resource-folder {
		// border-radius:$v-folder-border-radius $v-folder-border-radius 0 0;
	}

	.k-resource-folder-resources-last-child > div:first-of-type > .k-resource-folder {
		// border-radius:0 0 $v-folder-border-radius $v-folder-border-radius;
	}
}

.k-resource-folder-tiles {
	// background-color:rgba(0,0,0,0.025);
	// border:1px solid #ccc;
	// border-radius:$v-folder-border-radius;
	// margin:4px 0;
	// padding-right:4px;
}


.k-resource-folder-resources-inner {
	display:flex;
	flex-wrap:wrap;
	align-items: stretch;

	.k-resource-folder-resources-first-child {
		.k-resource-folder {
			// border-top:0;
			// padding-top:0;
			// margin-top:0;
		}
	}
}

.k-resource-folder-header {
	display:flex;
	cursor:pointer;
	align-items: flex-start;
	position:relative;
}

.k-resource-folder-header-inner {
	display:flex;
	align-items: flex-start;
	position:relative;
}

.k-resource-folder-reveal-icon {
	margin:-4px 12px 0 8px;
}

.k-resource-folder-close-icon {
	margin:-2px 8px 0 10px;
}

.k-resource-folder-resource-container-folder-open {
	.k-resource-folder-list, .k-resource-folder-tiles {
		// background-color:transparent;
		border:0px;
		// margin-bottom:2px;
		// margin-top:2px;
	}

	.k-resource-folder-header-inner {
		border-top: 1px solid $v-folder-border-color;
		border-left: 1px solid $v-folder-border-color;
		border-right: 1px solid $v-folder-border-color;
		background-color:#fff;
		padding: 4px 12px 4px 4px;
		border-radius: $v-folder-border-radius $v-folder-border-radius 0 0;
		margin: -4px 0px -5px 0;
	}

	.k-resource-folder-resources {
		border:1px solid $v-folder-border-color;
		border-radius:0 $v-folder-border-radius $v-folder-border-radius $v-folder-border-radius;
		padding-right:6px;
		padding-top:8px;
		padding-bottom:8px;
	}

	.k-resource-folder-tiles {
		.k-resource-folder-header-inner {
			// margin-top:0;
		}

		.k-resource-folder-resources {
		}
	}

	.k-resource-folder-reveal-icon { display:none; }
	.k-resource-folder-close-icon { display:block; }
}

// note that it's important that -closed comes after -open
.k-resource-folder-resource-container-folder-closed {
	.k-resource-folder-list, .k-resource-folder-tiles {
		border:1px solid $v-folder-border-color;
	}

	.k-resource-folder-header-inner {
		border:0;
		margin:0;
		padding:0;
	}

	.k-resource-folder-resources {
		border:0;
	}

	.k-resource-folder-reveal-icon { display:block; }
	.k-resource-folder-close-icon { display:none; }
}

.k-collections-folder-search-result {
	font-size:14px!important;
	margin-top:5px;
	margin-left:4px;
	color:#666!important;
}

.k-resource-folder-icon {
	margin-top:4px;
}

.k-resource-folder-title {
	// font-weight:bold;
}

.k-resource-folder-count {
	margin-left:8px;
	margin-top:3px;
	color:#999;
	font-size:0.8em;
	font-style:italic;
	white-space:nowrap;
}

.k-resource-folder-colored-indigo { background-color:$v-indigo-darken-3; border-color:$v-indigo-darken-3!important; }
.k-resource-folder-header-inner-colored-indigo.k-resource-folder-header-inner { background-color:$v-indigo-darken-3; border-color:$v-indigo-darken-3; }
.k-resource-folder-resources-colored-indigo.k-resource-folder-resources { border-color:$v-indigo-darken-3; }

.k-resource-folder-colored-brown { background-color:$v-brown-darken-3; border-color:$v-brown-darken-3!important; }
.k-resource-folder-header-inner-colored-brown.k-resource-folder-header-inner { background-color:$v-brown-darken-3; border-color:$v-brown-darken-3; }
.k-resource-folder-resources-colored-brown.k-resource-folder-resources { border-color:$v-brown-darken-3; }

.k-resource-folder-colored-cyan { background-color:$v-cyan-darken-3; border-color:$v-cyan-darken-3!important; }
.k-resource-folder-header-inner-colored-cyan.k-resource-folder-header-inner { background-color:$v-cyan-darken-3; border-color:$v-cyan-darken-3; }
.k-resource-folder-resources-colored-cyan.k-resource-folder-resources { border-color:$v-cyan-darken-3; }

.k-resource-folder-colored-green { background-color:$v-green-darken-3; border-color:$v-green-darken-3!important; }
.k-resource-folder-header-inner-colored-green.k-resource-folder-header-inner { background-color:$v-green-darken-3; border-color:$v-green-darken-3; }
.k-resource-folder-resources-colored-green.k-resource-folder-resources { border-color:$v-green-darken-3; }

.k-resource-folder-colored-pink { background-color:$v-pink-darken-3; border-color:$v-pink-darken-3!important; }
.k-resource-folder-header-inner-colored-pink.k-resource-folder-header-inner { background-color:$v-pink-darken-3; border-color:$v-pink-darken-3; }
.k-resource-folder-resources-colored-pink.k-resource-folder-resources { border-color:$v-pink-darken-3; }

.k-resource-folder-colored-blue { background-color:$v-blue-darken-3; border-color:$v-blue-darken-3!important; }
.k-resource-folder-header-inner-colored-blue.k-resource-folder-header-inner { background-color:$v-blue-darken-3; border-color:$v-blue-darken-3; }
.k-resource-folder-resources-colored-blue.k-resource-folder-resources { border-color:$v-blue-darken-3; }

.k-resource-folder-colored-teal { background-color:$v-teal-darken-3; border-color:$v-teal-darken-3!important; }
.k-resource-folder-header-inner-colored-teal.k-resource-folder-header-inner { background-color:$v-teal-darken-3; border-color:$v-teal-darken-3; }
.k-resource-folder-resources-colored-teal.k-resource-folder-resources { border-color:$v-teal-darken-3; }

.k-resource-folder-colored-purple { background-color:$v-purple-darken-3; border-color:$v-purple-darken-3!important; }
.k-resource-folder-header-inner-colored-purple.k-resource-folder-header-inner { background-color:$v-purple-darken-3; border-color:$v-purple-darken-3; }
.k-resource-folder-resources-colored-purple.k-resource-folder-resources { border-color:$v-purple-darken-3; }

.k-resource-folder-colored-orange { background-color:$v-deep-orange-darken-3; border-color:$v-deep-orange-darken-3!important; }
.k-resource-folder-header-inner-colored-orange.k-resource-folder-header-inner { background-color:$v-deep-orange-darken-3; border-color:$v-deep-orange-darken-3; }
.k-resource-folder-resources-colored-orange.k-resource-folder-resources { border-color:$v-deep-orange-darken-3; }

.k-resource-folder-colored-lime { background-color:$v-lime-darken-3; border-color:$v-lime-darken-3!important; }
.k-resource-folder-header-inner-colored-lime.k-resource-folder-header-inner { background-color:$v-lime-darken-3; border-color:$v-lime-darken-3; }
.k-resource-folder-resources-colored-lime.k-resource-folder-resources { border-color:$v-lime-darken-3; }

.k-resource-folder-colored-red { background-color:$v-red-darken-3; border-color:$v-red-darken-3!important; }
.k-resource-folder-header-inner-colored-red.k-resource-folder-header-inner { background-color:$v-red-darken-3; border-color:$v-red-darken-3; }
.k-resource-folder-resources-colored-red.k-resource-folder-resources { border-color:$v-red-darken-3; }

.k-resource-folder-colored-blue-grey { background-color:$v-blue-grey-darken-3; border-color:$v-blue-grey-darken-3!important; }
.k-resource-folder-header-inner-colored-blue-grey.k-resource-folder-header-inner { background-color:$v-blue-grey-darken-3; border-color:$v-blue-grey-darken-3; }
.k-resource-folder-resources-colored-blue-grey.k-resource-folder-resources { border-color:$v-blue-grey-darken-3; }

.k-resource-folder-header-colored {
	color:#fff;
	.v-icon { color:#fff!important; }
	.k-resource-folder-title { font-weight:bold; }
	.k-resource-folder-count { color:#fff; }
}

.k-resource-folder-opened {
	background-color:#fff!important;
	margin-top:4px!important;
	margin-bottom:4px!important;
}

.k-resource-folder-empty-footer {
	color:#999;
	font-style:italic;
}

.k-resource-folder-last-viewed {
	.k-resource-folder-header-inner {
		border-color:$v-yellow-darken-1!important;
		border-width:2px!important;
		margin-bottom:-6px;
		// background-color:#ffc!important;
	}
	.k-resource-folder-resources {
		border-color:$v-yellow-darken-1!important;
		border-width:2px!important;
		// background-color:#ffc!important;
	}
}

// this is better defined in ResourceCollectionView
// .k-resource-collection-activity-wrapper {
// 	flex:0 1 auto;
// 	min-width:180px;
// 	max-width:180px;
// }

// this style is applied when "list view" is selected; for tile view it is "xxx-half"
.k-resource-folder-resource-container-width-full {
	flex:1 0 100%;
}

.k-resource-folder-resource-container-width-full:nth-child(even) { background-color:#eee; }
.k-resource-folder-resource-container-width-full:nth-child(odd)  { background-color:#f8f8f8; }
.k-resource-folder-list .k-resource-folder-resource-item-hovered { 
	background-color:#e2e2e2; 
	// .k-resource-collection-item-title-link { font-weight:bold!important; }
}

.k-resource-folder-resource-container-folder {
	background-color:transparent!important;
}

.k-resource-folder-resource-item-tile-wrapper {
	display:flex;
	text-align:center;
	padding-bottom:2px;
	.k-resource-collection-item-created-date { display:none; }
	.k-resource-collection-item-stars { display:none; }
}

.k-resource-folder-resource-item-full-wrapper-reordering {
	padding-left:20px!important;
	position:relative;
}

.k-resource-folder-resource-item-full-wrapper {
	padding:2px 0;

	.k-resource-collection-item {
		background-color:transparent!important;
		box-shadow:none!important;
		border-radius:0!important;
		border-width:0 0 0 4px!important;
		font-size:16px;
		line-height:19px;
		min-height:28px;
		margin:0;
		padding:0 24px 0 0;
	}

	.k-resource-collection-item--title {
		display:flex;
		flex-align:start;
	}
	.k-resource-collection-item-title-link {
		color:#000!important;
		// this makes titles wrap when too long
		flex:1 0 400px;
		// TODO: leave more space for todo items??
		// old settings for controling length of titles...
		// max-width: calc(100% - 102px);
		// max-width: calc(100% - 130px);
	}
	.k-resource-collection-item-stars { 
		font-size:13px;
		color:#666;
		margin-left:8px;
		.fa-star { color:#999; font-size:10px; margin-left:2px; }
	}
	.k-resource-collection-item-created-date {
		flex:0 0 100px;
		text-align:center;
		white-space:nowrap;
		font-size:14px;
	}

	.k-resource-collection-item--title { font-size:16px; line-height:19px; }
	.k-resource-collection-item-really-long-title .k-resource-collection-item--title { font-size:16px; line-height:19px; }
	.k-resource-collection-item-long-title .k-resource-collection-item--title { font-size:16px; line-height:19px; }

	// below are no longer used
	// .k-resource-collection-item--leader-resource { .k-resource-collection-item-type-icon .fas {color:$v-brown-darken-3!important;}}
	// .k-resource-collection-item--assessment { .k-resource-collection-item-type-icon .fas {color:$v-pink-accent-4!important;}}
	// .k-resource-collection-item--lesson { .k-resource-collection-item-type-icon .fas {color:$v-blue-accent-4!important;}}
	// .k-resource-collection-item--sparkl-activity { .k-resource-collection-item-type-icon .fas {color:$v-deep-purple-accent-3!important;}}
	// .k-resource-collection-item--sparkl-bank { .k-resource-collection-item-type-icon .fas {color:$v-red-accent-4!important;}}
	// .k-resource-collection-item--teacher-resource { .k-resource-collection-item-type-icon .fas {color:$v-teal-darken-2!important;}}
	// .k-resource-collection-item--student-resource { .k-resource-collection-item-type-icon .fas {color:$v-orange-darken-4!important;}}
	// .k-resource-collection-item--adv { .k-resource-collection-item-type-icon .fas {color:$v-purple-accent-4!important;}}
	// .k-resource-collection-item--ese { .k-resource-collection-item-type-icon .fas {color:$v-lime-darken-4!important;}}
	// .k-resource-collection-item--unit-planning-guide { .k-resource-collection-item-type-icon .fas {color:$v-green-accent-4!important;}}

	.k-resource-collection-item-search-result { 
		font-size:16px!important;
		margin-top:-3px;
		color:#666!important;
	}

	.k-resource-collection-item-type-icon {
		float:left;
		text-align:center;
		height:18px;
		width:22px;
		margin:-1px 8px 0 0px;
	}

	.k-resource-collection-item-menu-btn {
		.fas {color:#000!important;}
	}

	.k-resource-collection-imported-to-my-collections {
		// border-left:5px solid #000!important;
	}
}

.k-resource-folder-resource-container-width-half {
	flex:1 0 auto;
	// min-width:calc(33% - 4px);
	// max-width:calc(33% - 4px);
	min-width:132px;
	max-width:calc(20% - 4px);
	width:180px;
	margin:0 2px;

	.k-resource-collection-item {
		width:100%;
	}
}

</style>
