<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-directive-item-view-mid" :class="item.o.item_showing?'white':''" @click.stop=""> <!-- click.stop prevents click event from bubbling up and de-highlighting last-clicked activity -->
	<div class="k-directive-item-title" style="cursor:pointer" @click="toggle_item_showing">
		<v-btn x-small fab color="orange lighten-1" class="mr-3 elevation-0"><v-icon>fas {{item.o.item_showing?'fa-caret-down':'fa-caret-right'}}</v-icon></v-btn>

		<v-icon v-if="!($vuetify.breakpoint.xs||tight_width)&&item.o.activity_type!='google_assignment'" class="mr-2" color="primary">{{item.icon}}</v-icon>
		<img v-if="!($vuetify.breakpoint.xs||tight_width)&&item.o.activity_type=='google_assignment'" class="mr-2 k-google-assignment-icon" src="https://www.gstatic.com/prof/logo_assignments_128dp.png">
		<v-icon small v-if="!($vuetify.breakpoint.xs||tight_width)&&has_unread_messages" class="mr-1">far fa-comment</v-icon>
		<div v-if="!($vuetify.breakpoint.xs||tight_width)" style="cursor:pointer" class="k-lesson-title" v-html="item.title"></div>
		<v-spacer v-if="!($vuetify.breakpoint.xs||tight_width)" />
		<div v-if="!($vuetify.breakpoint.xs||tight_width)" class="k-lesson-course-unit-header mr-2" v-html="course_unit_header"></div>
		<div v-if="!($vuetify.breakpoint.xs||tight_width)&&(!item.o.item_showing||item_is_lesson)" style="margin-top:-3px"><v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" v-html="date_header"></div></template><div v-html="date_tooltip"></div></v-tooltip></div>
		<v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" v-if="student_view&&!item_is_lesson" class="ml-2" style="margin-bottom:-2px" v-html="student_status_icon"></div></template>{{student_status_tooltip}}</v-tooltip>

		<div v-if="($vuetify.breakpoint.xs||tight_width)" style="flex:1 0 auto">
			<div class="mx-1 float-right" v-html="date_header"></div>
			<div class="k-lesson-title" v-html="item.title"></div>
			<div class="k-lesson-course-unit-header text-left ml-0 mt-1" v-html="course_unit_header"></div>
		</div>
		<v-menu offset-y bottom right><template v-slot:activator="{on}"><v-btn v-on="on" v-if="is_content_editor" v-show="$vuetify.breakpoint.smAndUp&&!item_being_edited" fab x-small class="ml-2" :color="(item.o.item_showing||hover)?'primary':'#ccc'" dark @click.stop="last_viewed_activity_or_lesson_id=item.id"><v-icon small>fas fa-bolt</v-icon></v-btn></template>
			<v-list dense>
				<!-- <v-list-item v-if="true" click.stop="add_to_gc"><v-list-item-icon><img src="./../../images/google_classroom_logo.png" style="height:18px;margin-top:3px;margin-left:-4px;border-radius:3px;"></v-list-item-icon><v-list-item-title>Add to Google Classroom</v-list-item-title></v-list-item> -->
				<v-list-item v-if="is_editable" @click="edit_item"><v-list-item-icon><v-icon small color="light-blue">fas fa-edit</v-icon></v-list-item-icon><v-list-item-title>Edit {{(item.o.activity_type == 'lesson') ? 'Lesson Plan' : 'Activity'}}</v-list-item-title></v-list-item>
				<v-list-item v-if="is_editable" @click="duplicate_item"><v-list-item-icon><v-icon small color="teal">fas fa-copy</v-icon></v-list-item-icon><v-list-item-title>Duplicate {{directive_type_label}}</v-list-item-title></v-list-item>
				<v-list-item @click="open_in_new_window"><v-list-item-icon><v-icon small color="purple">fas fa-arrow-up-right-from-square</v-icon></v-list-item-icon><v-list-item-title>Open {{(item.o.activity_type == 'lesson') ? 'Lesson Plan' : 'Assignment'}} in a new window</v-list-item-title></v-list-item>
				<v-list-item @click="share_item"><v-list-item-icon><v-icon small color="purple">fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title>Share {{directive_type_label}}</v-list-item-title></v-list-item>
				<!-- ability to print activities is coming... -->
				<v-list-item v-if="item_is_lesson" @click="print_lesson"><v-list-item-icon><v-icon small color="#666">fas fa-print</v-icon></v-list-item-icon><v-list-item-title>Print {{directive_type_label}}</v-list-item-title></v-list-item>
				<!-- archiving will be coming later... -->
				<v-list-item v-if="can_archive" @click="archive_item"><v-list-item-icon><v-icon small color="orange">fas fa-box-archive</v-icon></v-list-item-icon><v-list-item-title>Archive {{directive_type_label}}</v-list-item-title></v-list-item>
				<!-- don't allow copying lesson links to clipboard for now, because students can't currently see lessons -->
				<v-list-item v-if="item.o.activity_type!='lesson'" @click="copy_link_to_clipboard"><v-list-item-icon><v-icon small>fas fa-link</v-icon></v-list-item-icon><v-list-item-title>Copy {{directive_type_label}} link to clipboard</v-list-item-title></v-list-item>

				<v-list-item v-if="!item_is_lesson" style="border-top:1px solid #ccc"><v-list-item-title>{{site_config.sparkl_app_name}} Activity ID: {{item.o.tool_activity_id}}</v-list-item-title></v-list-item>
				<v-list-item v-if="item_is_lesson" style="border-top:1px solid #ccc"><v-list-item-title>Lesson ID: {{item.o.lesson_id}}</v-list-item-title></v-list-item>
			</v-list>
		</v-menu>
	</div>

	<v-expand-transition><div v-if="item.o.item_showing">
		<div v-if="item_is_lesson" :class="use_enhanced_lesson_editor?'mt-2':''">
			<!-- show "OG" lessons inline; show LPC lessons in a dialog -->
			
			<LessonView v-if="!item_being_edited&&!use_enhanced_lesson_editor" :lesson="item.o" :show_print_dialog="show_print_dialog" :print_lesson_dom_id="print_lesson_dom_id" :title_showing="false" />
			<LessonEditor v-if="item_being_edited&&!use_enhanced_lesson_editor" ref="item_editor" :original_lesson="item.o" :course_code="course_code" :lp_unit_id="lp_unit_id" lesson_class="teacher" @edit_lesson_cancel="edit_lesson_cancel" @edit_lesson_saved="edit_lesson_saved" />

			<div v-if="!item_being_edited&&use_enhanced_lesson_editor" class="text-center mb-2"><i>Lesson plan showing in dialog</i></div>
			<v-dialog v-if="!item_being_edited&&use_enhanced_lesson_editor" v-model="item.o.item_showing" max-width="1200" persistent scrollable :hide-overlay="lesson_shifted" overlay-opacity="0.8" :content-class="'k-lpe-editor-dialog k-resource-collection-item-lesson-card-dialog'+(lesson_shifted?' k-resource-collection-lesson-item-card-dialog-shifted':'')">
				<v-card class="k-resource-collection-item-lesson-card">
					<div class="d-flex align-center pb-2">
						<v-icon class="mr-2">fas fa-rectangle-list</v-icon>
						<div class="k-lesson-title" style="font-weight:bold" v-html="item.o.lesson_title"></div>
						<v-spacer/>
						<div v-if="!($vuetify.breakpoint.xs)" class="k-lesson-course-unit-header" v-html="course_unit_header"></div>
						<div v-if="!($vuetify.breakpoint.xs)" class="mr-2" v-html="date_header"></div>
					</div>
					<LessonView :lesson="item.o" :show_print_dialog="show_print_dialog" :print_lesson_dom_id="print_lesson_dom_id" @close_lesson="toggle_item_showing(false)" />

					<v-card-actions class="px-1 pt-2 pb-0">
						<v-tooltip top><template v-slot:activator="{on}"><v-btn v-on="on" class="ml-1 mr-1" fab x-small dark color="secondary" @click="shift_lesson"><v-icon small>fas {{lesson_shifted?'fa-angles-left':'fa-angles-right'}}</v-icon></v-btn></template>Shift Lesson Plan</v-tooltip>
						<v-tooltip top><template v-slot:activator="{on}"><v-btn v-on="on" class="ml-1 mr-1" fab x-small dark color="#333" @click="print_lesson"><v-icon small>fas fa-print</v-icon></v-btn></template>Print Lesson Plan</v-tooltip>
						<v-tooltip top><template v-slot:activator="{on}"><v-btn v-on="on" class="ml-1 mr-1" fab x-small dark color="#333" @click="open_in_new_window"><v-icon small>fas fa-arrow-up-right-from-square</v-icon></v-btn></template>Open Lesson Plan in new window</v-tooltip>
						<v-tooltip top><template v-slot:activator="{on}"><v-btn v-on="on" class="ml-1 mr-1" fab x-small color="purple" dark @click="share_item"><v-icon>fas fa-share-nodes</v-icon></v-btn></template>Share Lesson with other educators</v-tooltip>
						<v-tooltip top><template v-slot:activator="{on}"><v-btn v-show="is_editable" v-on="on" class="ml-1 mr-1" fab x-small dark color="blue darken-2" @click="edit_item"><v-icon small>fas fa-edit</v-icon></v-btn></template>Edit Lesson</v-tooltip>
						<v-tooltip top><template v-slot:activator="{on}"><v-btn v-show="can_archive" v-on="on" class="ml-1 mr-1" fab x-small dark color="orange darken-2" @click="archive_item"><v-icon small>fas fa-box-archive</v-icon></v-btn></template>Archive Lesson</v-tooltip>
						<v-spacer/>
						<v-btn color="secondary" dark @click="toggle_item_showing(false)"><v-icon small class="mr-2">fas fa-times</v-icon>Done</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-dialog v-if="item_being_edited&&use_enhanced_lesson_editor" v-model="item_being_edited" :max-width="1200" persistent scrollable :retain-focus="false" :hide-overlay="lesson_shifted" :content-class="`k-resource-collection-item-lesson-card-dialog k-lpe-editor-dialog ${lesson_shifted?'k-resource-collection-lesson-item-card-dialog-shifted':''}`">
				<v-card class="k-resource-collection-item-lesson-card">
					<div class="d-flex">
						<v-icon class="mr-2" style="margin-top:-2px;">fas fa-rectangle-list</v-icon>
						<div class="k-lesson-title"><b class="mr-2" style="font-size:18px">Edit Lesson</b><span :style="lesson_editor_title_style">{{item.o.lesson_title}}</span></div>
						<v-spacer/>
						<div style="cursor:pointer; white-space:nowrap; font-size:15px;" @click="show_llm_lesson_plan_description"><v-icon color="light-blue" class="mr-2">fas fa-info-circle</v-icon>Lesson Plan Companion<v-icon class="k-lpe-dog-icon mx-2" style="margin-top:-5px">fas fa-dog</v-icon>(<b class="red--text text--darken-3">BETA</b>)</div>
					</div>
					<div class="k-lpe-lesson-edit-outer">
						<LessonEditorEnhanced ref="lesson_editor_component" :original_lesson="item.o" lesson_class="teacher" :course_code="course_code" :lp_unit_id="lp_unit_id" :enable_lesson_shift="false" @edit_lesson_cancel="edit_lesson_cancel" @edit_lesson_saved="edit_lesson_saved" />
					</div>
				</v-card>
			</v-dialog>
		</div>
		<div v-else>
			<DirectiveActivityView v-if="!item_being_edited && !show_message_editor" :activity="item.o" :print_lesson_dom_id="print_lesson_dom_id" :title_showing="false" @create_message="create_message" @edit="edit_item" @edit_activity_saved="edit_activity_saved" />
			<div v-if="item_being_edited && !show_message_editor" class="mt-3">
				<DirectiveActivityEditor ref="item_editor" :original_activity="item.o" :course_code="course_code" :lp_unit_id="lp_unit_id" activity_class="teacher" @edit_activity_cancel="edit_activity_cancel" @edit_activity_saved="edit_activity_saved" />
			</div>
			<div v-if="show_message_editor && !item_being_edited" class="mt-3">
				<MessageEditor v-if="!student_view" :message="new_message" :activity_id="item.o.activity_id" :course_code="item.o.course_code" :activity_recipients="activity_message_recipients" @editor_cancel="show_message_editor = false" />
				<MessageEditor v-if="student_view"  :message="new_message" :activity_id="item.o.activity_id" :course_code="item.o.course_code" :is_student_generated="true" @editor_cancel="show_message_editor = false" :reply_to_string="send_to_string" />
			</div>
			<MessageList v-if="has_messages" :activity_id="item.o.activity_id" />
		</div>
	</div></v-expand-transition>

	<LessonPrint v-if="show_print_dialog" :lesson="item.o" :print_lesson_dom_id="print_lesson_dom_id" @dialog_cancel="show_print_dialog=false" />
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import LessonView from '../lessons/LessonView'
import LessonEditor from '../lessons/LessonEditor'
import LessonEditorEnhanced from '../lessons/LessonEditorEnhanced'
import DirectiveActivityView from '../directives/DirectiveActivityView'
import DirectiveActivityEditor from '../directives/DirectiveActivityEditor'
import LessonPrint from '../lessons/LessonPrint'
import MessageEditor from '../messages/MessageEditor'
import MessageList from '../messages/MessageList'

export default {
	name: 'DirectivesListItem',
	components: { LessonView, LessonEditor, LessonEditorEnhanced, DirectiveActivityView, DirectiveActivityEditor, LessonPrint, MessageEditor, MessageList },
	props: {
		item: { type: Object, required: true },
		course_code: { type: String, required: false, default() { return '' } },
		lp_unit_id: { type: Number, required: false, default() { return 0 } },
		tight_width: { type: Boolean, required: false, default() { return false } },
		show_title: { type: Boolean, required: false, default() { return true } },
		hover: { type: Boolean, required: false, default() { return false } },
		filtered_origin_teacher_id: { type: Number, required: false, default() { return null } },
	},
	data() { return {
		item_being_edited: false,
		show_message_editor: false,
		new_message: null,
		lesson_expanded: false,
		show_print_dialog: false,
		print_lesson_dom_id: U.new_uuid(),
		student_status_tooltip: '',	// set along with student_status_icon
		lesson_shifted: false,
	}},
	computed: {
		...mapState(['user_info', 'my_lessons', 'my_activities', 'my_activity_results', 'single_item', 'single_item_mode', 'messages', 'sis_classes', 'site_config']),
		...mapGetters(['role', 'system_role', 'manage_assignments', 'simulating_user']),

		// this component only shows existing lessons (not new lessons). it doesn't currently support switching between LPC and OG lessons
		// use_enhanced_lesson_editor determines if the lesson uses the LPC
		use_enhanced_lesson_editor() { return this.item.o.lp_variant=='B' },

		viewing_my_activities() { return this.filtered_origin_teacher_id == null || this.filtered_origin_teacher_id == this.user_info.user_id },
		is_editable() { 
			// if I'm not viewing my own activities (i.e. I'm viewing a coteacher's activities), can't edit anything
			if (!this.viewing_my_activities) return false

			// otherwise the item is editable
			return true
		},
		can_archive() {
			// SF: 8-15-24 : Archiving is currently only allowed while viewing your own Activities from within a Unit OR in the course-wide materials tab
			const is_in_unit = this.lp_unit_id !== 0
			const is_in_course_wide_materials = this.$store.state.lst.collection_assignments_or_messages_mode === 'course_assignments'
			return this.viewing_my_activities && (is_in_unit || is_in_course_wide_materials)
		},
		student_view() { 
			if (this.single_item_mode == 'studentpreview') return true
			return this.role != 'staff' && this.role != 'admin' 
		},
		is_content_editor() { 
			if (this.single_item_mode == 'studentpreview') return false
			return this.role == 'staff' || this.role == 'admin' 
		},
		has_messages() {
			return this.messages?.filter((message) => message.activity_id === this.item.o.activity_id)?.length > 0
		},
		has_unread_messages() {
			return this.messages?.filter((message) => message.activity_id === this.item.o.activity_id)?.some((message) => !message.is_read)
		},
		course_unit_header() { return this.item.o.course_unit_header() },
		date_header() { return this.item.o.date_header() },
		item_is_lesson() { return this.item.o.activity_type == 'lesson' },
		directive_type_icon() { return U.directive_type_icon(this.item.o.activity_type) },
		directive_type_label() { return U.directive_type_label(this.item.o.activity_type) },
		// show_date() { return this.item_is_lesson || !this.item.o.item_showing },
		// show_availability() { return !this.item_is_lesson && !this.item.o.item_showing },
		item_is_available() { return this.item.o.available_to_students() },
		date_tooltip() {
			// for staff/admins, tooltip shows whether or not it is available to students
			if (!this.student_view) {
				let s = sr('<nobr><b>$1</b></nobr> <nobr>to students</nobr>', (this.item_is_available?'AVAILABLE':'NOT AVAILABLE'))
				// also explain what the * means if there is one
				if (this.item.o.has_multiple_available_dates() || this.item.o.has_multiple_due_dates()) {
					s += `<div>*Multiple dates assigned</div>`
				}
				return s
			}

			// for students/parents, tooltip shows when the activity is due
			else if (this.item_is_lesson) return sr('<nobr>This lesson is</nobr> <nobr>scheduled for $1</nobr>', this.item.o.date_header())
			else if (this.item.o.due_date()) {
				if (!this.item.o.due_date_has_passed()) return sr('<nobr>Activity should be</nobr> <nobr>completed by $1</nobr>', this.item.o.due_date_header())
				else return sr('<nobr>Activity was due</nobr> <nobr>on $1</nobr>', this.item.o.due_date_header())
			} else return sr('<nobr>This activity</nobr> <nobr>has no due date</nobr>')
		},
		activity_result() {
			if (this.item_is_lesson) return {}
			// if we don't already have an activity_result, create one here
			if (empty(this.my_activity_results[this.item.o.activity_id])) {
				// note that we have to cast the activity_id as a string when we do the set commit, because my_activity_results is an object
				this.$store.commit('set', [this.my_activity_results, this.item.o.activity_id+'', new Activity_Result(null, this.item.o, this.user_info)])
			}
			return this.my_activity_results[this.item.o.activity_id]
		},
		student_status_icon() {
			if (this.item_is_lesson) return ''
			let cls, icon
			if (this.item.o.due_date_has_passed() && !this.activity_result.complete()) {
				cls = 'k-status-icon-past-due'
				if (!this.activity_result.started()) {
					icon = 'circle-minus'
					this.student_status_tooltip = 'Activity is past due; you have not started it'
				} else {
					icon = 'circle-half-stroke'
					this.student_status_tooltip = 'Activity is past due; you have started but not completed it'
				}
			} else {
				if (!this.activity_result.started()) {
					cls = 'k-status-icon-not-started'
					icon = 'circle-minus'
					this.student_status_tooltip = 'You have not started this activity'
				} else if (this.activity_result.complete()) {
					cls = 'k-status-icon-complete'
					icon = 'circle-check'
					this.student_status_tooltip = 'You have completed this activity'
				} else {
					cls = 'k-status-icon-started'
					icon = 'circle-half-stroke'
					this.student_status_tooltip = 'You have started, but haven’t completed, this activity'
				}
			}
			return sr('<i class="k-status-icon $1 fas fa-$2"></i>', cls, icon)
		},
		activity_message_recipients() {
			return this.item.o.assigned_to.map(assignee => assignee.user_sourcedId)
		},
		last_viewed_activity_or_lesson_id: {
			get() { return this.$store.state.last_viewed_activity_or_lesson_id },
			set(val) { this.$store.commit('set', ['last_viewed_activity_or_lesson_id', val]) }
		},
		send_to_string() {
			// String to pass to MessageEditor to indicate who the message is being sent to
			// This only applies in this case for student-generated messages
			const sis_class = this.sis_classes?.filter(c => c.course_code == this.item.o.course_code)?.slice(0, 1)?.pop()
			const teacher_string = sis_class?.classCodes?.slice(0, 1)?.pop()
			// If we can't get the class or teacher, just return the default string of 'Teacher'
			if (!teacher_string) return 'Teacher'
			// sis_class->classCodes is a comma-separated string of the form "Last, First". Normalize it.
			const match = teacher_string.match(/^(\w+), (\w+)$/);
			if (!match) return teacher_string;
			return `${match[2]} ${match[1]}`;
		},
		reslink() {
			let url = window.location.origin + '/'
			if (this.item.o.activity_type == 'activity') {
				url += `activity/${this.item.o.activity_id}`
			} else if (this.item.o.activity_type == 'google_assignment') {
				url += `activity/${this.item.o.activity_id}`
			} else if (this.item.o.activity_type == 'lesson') {
				url += `lesson/${this.item.o.lesson_id}`
			}
			return url
		},
		lesson_editor_title_style() {
			// try to make sure lesson title fits in the space we have alloted; if it doesn't, we get an ugly extra scroll bar
			let s = U.get_max_font_size_for_block_width({size_hi:18, size_lo:12, width:700, val:this.item.o.lesson_title})
			return `font-size:${s}px`
		},
	},
	watch: {
		'item.o.item_showing'(val) {
			// console.log('item.o.item_showing: ' + val, $(this.$el).find('.k-directive-item-title').length)
			// if showing, scroll to show the item
			if (val) {
				// this component might be rendered multiple places in the dom; only scroll if this one is actually visible
				if ($(this.$el).find('.k-directive-item-title').is(':visible')) {
					setTimeout(x=>this.$vuetify.goTo($(this.$el).find('.k-directive-item-title')[0], {offset:96}), 300)
				}
			}
		}
	},
	created() {
		// get lesson_masters if we haven't already gotten done so, unless we're viewing a single activity
		if (!this.single_item || this.item_is_lesson) this.$store.dispatch('get_lesson_masters')
	},
	mounted() {
	},
	methods: {
		toggle_item_showing(val) {
			if (!empty(val?.target)) $(val.target).closest('button').blur()
			if (typeof(val) != 'boolean') val = !this.item.o.item_showing
			// if we're editing, close the editor rather than immediately collapsing
			if (this.item_being_edited) {
				if (this.item_is_lesson) {
					this.$refs.item_editor.edit_lesson_cancel()
				} else {
					this.$refs.item_editor.edit_activity_cancel()
				}
			} else {
				this.$store.commit('set', [this.item.o, 'item_showing', val])
			}

			this.last_viewed_activity_or_lesson_id = this.item.id
		},

		////////////////////////////////////////////////////////
		// for the enhanced lesson editor, define new fns for saving and closing the editor. probably we should apply these to the OG editor too...

		// edit_lesson_enhanced_saved(args) {
		edit_lesson_saved(args) {
			// the lesson editor will have saved the resource to the db; args will include the updated_lesson data from the serve and the edited_lesson construct, which we use to restore user preferences

			// save the args we received
			this.edit_lesson_saved_args = args

			// if flag is 'and_close', proceed to edit_lesson_cancel
			if (args.flag == 'and_close') {
				this.edit_lesson_cancel()
			}
			// if the user later cancels the lesson edit, or clicks "save and close" when there's nothing to save, it'll go right to edit_lesson_cancel, and we'll used the saved args then
		},

		edit_lesson_cancel(flag) {
			// once we finally close the lesson editor, if we saved any changes to the lesson at any time, we need to bubble updated_resource up (to DirectivesWrapper, via one of the DirectivesList wrappers) for further processing
			// note that we can't do this before closing, because if we do so we disrupt the editor 
			if (this.edit_lesson_saved_args) {
				this.$emit('edit_item_saved', {
					type: 'lesson', 
					updated_lesson: this.edit_lesson_saved_args.updated_lesson, 
					edited_lesson: this.edit_lesson_saved_args.edited_lesson, 
					flag: flag,	// I don't think this is used in the handler, but no harm in sending it
				})
			}
			// remember to reset edit_lesson_saved_args
			this.edit_lesson_saved_args = null

			// reset the editing flag for the lesson (unless the flag says we already have done so), then close the editor
			if (flag != 'already_checked_in') {
				U.ajax('reset_checked_out_for_edit', {user_id: this.user_info.user_id, lesson_id: this.item.o.lesson_id})
			}
			this.item_being_edited = false
		},

		////////////////////////////////////////////////////////
		edit_activity_saved(edited_activity) {
			// the activity editor will have saved the activity to the db; splice to my_activities
			let new_activity = new Activity(edited_activity)

			// preserve booleans for components that are showing
			new_activity.item_showing = this.item.o.item_showing
			new_activity.standards_showing = this.item.o.standards_showing

			// add_to_my_activity will splice assuming the activity is already there
			this.$store.commit('add_to_my_activities', new_activity)
		},

		edit_item() {
			if (this.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			this.toggle_item_showing(true)

			// if this item is a lesson, check it out for editing
			if (this.item_is_lesson) {
				// note that unlike when editing a lesson from a shared collection, we don't first reload the lesson contents here; 
				// that's probably fine, since it's very unlikely (currently, possibly impossible) for a lesson in the assignment center area to actually be shared between multiple teachers
				const payload = {
					user_id: this.user_info.user_id,
					lesson_id: this.item.o.lesson_id
				}
				this.$store.dispatch('check_out_lesson_for_editing', payload).then(() => {
					this.item_being_edited = true
				}).catch((err) => {
					console.log(err)
				})
			} else {
				this.item_being_edited = true
			}
		},

		edit_activity_cancel() {
			this.item_being_edited = false
		},

		create_message(creator_sourcedId = null) {
			let m = {
				author_user_id: this.user_info.user_id,
				author_sourcedId: this.user_info.sis_user_sourcedId,
				course_code: this.item.o.course_code,
				message_id: 0,
				message_level: 0,
				subject: this.item.title,
				parent_message_id: 0,
				first_name: this.user_info.first_name,
				last_name: this.user_info.last_name
			}
			if (creator_sourcedId) {
				// This is a student-generated message, so we need to set the teacher_sourcedId
				m.recipients = [creator_sourcedId]
			}
			this.new_message = new window.Message(m)
			this.$store.commit("set", [this.messages, "PUSH", this.new_message])
			this.show_message_editor = true
		},

		duplicate_item() {
			if (this.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			// the parent component needs to handle duplication...
			this.$emit('duplicate_item', this.item)
		},

		delete_item() {
			if (this.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			this.$confirm({
			    title: 'Are you sure?',
			    text: 'Are you sure you want to delete this ' + this.directive_type_label + '?',
			    acceptText: 'Delete',
				acceptColor: 'red',
			}).then(y => {
				U.loading_start()
				let payload = {user_id: this.user_info.user_id}
				let service = (this.item_is_lesson) ? 'delete_lesson' : 'delete_activity'
				if (this.item_is_lesson) payload.lesson_id = this.item.o.lesson_id
				else payload.activity_id = this.item.o.activity_id
				U.ajax(service, payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in ajax call'); vapp.ping(); return;
					}

					if (this.item_is_lesson) {
						let index = this.my_lessons.findIndex(x => x.lesson_id == this.item.o.lesson_id)
						this.my_lessons.splice(index, 1)
					} else {
						this.$store.commit('remove_from_my_activities', this.item.o)
						// let index = this.my_activities.findIndex(x => x.activity_id == this.item.o.activity_id)
						// this.my_activities.splice(index, 1)
					}
				});
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		archive_item() {
			if (this.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			this.$confirm({
			    title: 'Please Confirm',
			    text: 'Are you sure you want to archive this ' + this.directive_type_label + '?',
				acceptText: `Archive ${this.directive_type_label}`,
				acceptIcon: 'fas fa-trash-alt',
				acceptColor: 'red darken-2',
			}).then(y => {
				this.$emit('archive_items', [this.item.o])
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		copy_link_to_clipboard() {
			// console.log(this.item.o.activity_type, this.item)
			U.copy_to_clipboard(this.reslink)
			this.$inform(this.directive_type_label + ' link copied to clipboard.')
		},

		open_in_new_window() {
			window.open(this.reslink)
		},

		// allow the user to shift the lesson to the right of the screen, so that other resources in the lesson (e.g. unit planning guides) are viewable on the left
		// note that the btn to call this fn only shows if we are showing the lesson in the context of a collection
		shift_lesson(val) {
			if (typeof(val) == 'boolean') this.lesson_shifted = val
			else this.lesson_shifted = !this.lesson_shifted

			// emit an event that will bubble up to the collection, that causes the collection's resources to be shifted to the left; and makes it so that if the user switches to a different unit or collection, the lesson will be closed and the collection will be unshifted.
			if (this.lesson_shifted) this.$emit('lesson_shift_update', {shifted_for_lesson:this})
			else this.$emit('lesson_shift_update', {shifted_for_lesson:false})
		},

		print_lesson() {
			// the lesson has to be showing for the print function to work 
			this.toggle_item_showing(true)
			this.$nextTick(x=>this.show_print_dialog = true)
		},

		share_item() {
			let msg = sr('<div class="mb-2">To share this $1, give the following $1 ID to your colleague:</div>', this.directive_type_label)
			msg += sr('<div class="mb-2 text-center" style="font-size:22px"><b>$1</b></div>', this.item.id_for_sharing)
			msg += sr('<div style="font-size:14px; line-height:18px;">Your colleague can import this $1 by clicking the “+CREATE” button from the “My Lessons & Activities” area of a course or unit, choosing “Import shared assignment”, and entering this $1 ID.</div>', this.directive_type_label)

			this.$confirm({
				title: 'Share ' + this.directive_type_label,
				text: msg,
				cancelText: 'Copy ' + this.directive_type_label + ' ID',
				dialogMaxWidth: 600,
			}).catch(e=>{
				// note that this ID will start with 'L' or 'A' for a lesson or activity
				U.copy_to_clipboard(this.item.id_for_sharing)
				this.$inform(sr('$1 ID copied to clipboard', this.directive_type_label))
			})
		},

		show_llm_lesson_plan_description() { vapp.show_llm_lesson_plan_description() },
	}
}
</script>

<style lang="scss">
// these classes probably belong somewhere else...
.k-status {
	display:inline-block;
	white-space:nowrap;
	font-size:14px;
	line-height:15px;
	font-weight:900;
	background-color:#999;	// this will get overwritten
	border-radius:6px;
	padding:4px 6px;
	color:#fff;
	text-align:center;
}
.k-status-not-started { background-color: $v-blue-grey-darken-3; }
.k-status-started { background-color: $v-orange-accent-4; }
.k-status-complete { background-color: $v-green-accent-4; }
.k-status-past-due { background-color: $v-red-accent-4; }

.k-status-icon {
	font-size:28px;
}
.k-status-icon-not-started { color: $v-blue-grey-darken-3; }
.k-status-icon-started { color: $v-orange-accent-4; }
.k-status-icon-complete { color: $v-green-accent-4; }
.k-status-icon-past-due { color: $v-red-accent-4; }

.k-google-assignment-icon {
	height:31px;
	padding:2px 0;
	border:1px solid #ccc;
	border-radius:3px;
}
</style>
