class Resource {
	constructor(data) {
		if (empty(data)) data = {}

		// generate a new resource_id if needed
		if (data.resource_id == 'new') this.resource_id = U.new_uuid()
		else if (!empty(data.resource_id)) this.resource_id = data.resource_id
		else {
			console.warn('Resource created with no resource_id')
			// this.resource_id = U.new_uuid()
		}

		// resource_template_id: if set, this resource is based on the resource that has resource_id == resource_template_id; see ResourceCollectionItem
		sdp(this, data, 'resource_template_id', '')

		sdp(this, data, 'agency_sanctioned', false)

		sdp(this, data, 'type', 'none', ['none', 'upload', 'html', 'website', 'document', 'video', 'interactive', 'safari', 'lti', 'resource_collection', 'collection_item', 'assessment', 'sparkl', 'sparkl_bank', 'google'])
		// a 'resource_collection' is an externally-defined collection -- e.g. a publisher's textbook resources, loaded via common cartridge

		sdp(this, data, 'url', '')
		sdp(this, data, 'description', '')
		sdp(this, data, 'long_description', '')
		sdp(this, data, 'lti_params', {})
		sdp(this, data, 'case_identifiers', [])		// used when importing common cartridges
		sdp(this, data, 'target_students', 'all', ['all', 'ese', 'adv'])

		// 'lp_category' isn't really about the lp; it's a legacy thing from HenryConnects. We should change this to an array of metadata values...
		// also, in Henry lp_category, `course_guidance` used to be `cross_unit`; update accordingly
		if (data.lp_category == 'cross_unit') {
			this.lp_category = 'course_guidance'
			this.cross_unit = true
		} else {
			sdp(this, data, 'lp_category', '')	// unit_planning, course_guidance, course_ebook, stem_resource, leader_resource
			sdp(this, data, 'cross_unit', false)	// set to true for resources that should be shown in all units of an LP
		}

		sdp(this, data, 'creator', 0)
		sdp(this, data, 'created_at', '')
		sdp(this, data, 'mappings', [])	// this will be an array of values such as 'course-1234', 'grade-4', or 'subject-Math'
		sdp(this, data, 'family_avail', false)	// set to true for resources that should be shown to families (false by default)
		sdp(this, data, 'block_or_traditional', 'both', ['both', 'block', 'traditional'])	// some resources have to be limited to the "block" or "traditional" format
		sdp(this, data, 'todo', false)		// is this resource marked as a "todo" item for users (i.e. for professional development)?

		if (!empty(data.extensions)) {
			this.extensions = {}
			// do some translation for alex
			for (let key in data.extensions) {
				if (key == 'content') {
					// old alex sometimes had content_source = 'other' and content_source_other = the actual content source...
					if (!empty(data.extensions.content.content_source_other)) this.extensions.content_source = data.extensions.content.content_source_other
					else if (!empty(data.extensions.content.content_source)) this.extensions.content_source = data.extensions.content.content_source

					sdp(this.extensions, data.extensions.content, 'content_type', '')

				} else if (key == 'license') {
					// license_type 'custom' translates to no license_text
					if (!empty(data.extensions.license.license_type) && data.extensions.license.license_type.toLowerCase() != 'custom') this.extensions.license_text = data.extensions.license.license_type
					sdp(this.extensions, data.extensions.license, 'license_uri', '')

				} else {
					this.extensions[key] = data.extensions[key]
				}
			}
		}
		/* possible extensions:
			content_source: "National Public Radio",
			content_type: "Learning Activity" (options are specfied by instance; user can add a custom value)
			license_text (license_type in ALEX): "Attribution Non-Commercial No Derivatives",
			license_uri: ""
			approved_date: "2022-04-26", (not editable)
			accessibility: [
				"Audio resources: includes a transcript or subtitles",
				"<p>Graphics: includes alt tags or long descriptions",
				"Text Resources: Content is organized under headings and subheadings",
				"Video resources: includes closed captioning or subtitles"
			]
		*/
	
		// standards stored like lessons
		this.standards = []
		if (!empty(data.standards)) {
			// a bug, fixed on 6/17/2024, was causing uploaded-file resources with standards specified to have the standards param saved as, e.g., "[object Object],[object Object]"
			// so if data.standards is a string, skip it, and (below) if an incoming standard doesn't have an identifier (i.e. a CASE GUID), skip those too
			if (typeof(data.standards) == 'string') {
				console.warn(`data.standards is string for resource ${this.resource_id} [${this.description.substr(0,40)}]`)
			} else {
				for (let standard of data.standards) {
					if (empty(standard.identifier)) {
						console.warn(`skipping empty standard in resource ${this.resource_id} [${this.description.substr(0,40)}]`)
						continue
					}
					// for now at least we'll use the learning progression CASE_Item structure for standards; this is a bit simpler than the full CFItem structure
					this.standards.push(new CASE_Item(standard))
				}
			}
		}
		
		sdp(this, data, 'stars_available', -1)	// this should be filled in for sparkl activities (?)

		if (!empty(data.lti_params)) {
			// some legacy HMH resources were saved without setting agency_sanctioned and/or teacher_facing properly, so...
			// default value for agency_sanctioned is true for lti links
			sdp(this, data, 'agency_sanctioned', true)

			// and if lti_params.custom_resource_url has "/teacher/" in it, default value for teacher_facing is true (otherwise false)
			if (!empty(data.lti_params.custom_resource_url) && data.lti_params.custom_resource_url.search(/\/teacher\b/)) {
				sdp(this, data, 'teacher_facing', true)
			} else {
				sdp(this, data, 'teacher_facing', false)
			}

		} else {
			// if not an LTI link, default value for agency_sanctioned is false and default value for teacher_facing is false
			sdp(this, data, 'agency_sanctioned', false)
			sdp(this, data, 'teacher_facing', false)
			// caveat: assessments are always teacher-facing
			if (this.type == 'assessment') this.teacher_facing = true
		}
		// resource is district-sanctioned if it's added in a LP

		// if resource is restricted to certain classes of users this will be set to a string that designates who's authorized
		sdp(this, data, 'restricted', '')

		// supplemental urls, e.g. for assessments (blueprints and paper copies)
		this.supp_links = []
		if (!empty(data.supp_links)) {
			for (let sl of data.supp_links) {
				let o = {}
				sdp(o, sl, 'description', '')
				sdp(o, sl, 'type', '')
				sdp(o, sl, 'url', '')
				this.supp_links.push(o)
			}
		}

		// temp things that we delete before saving
		sdp(this, data, 'full_resource_data_loaded', false)
		sdp(this, data, 'quick_look_showing', false)
		sdp(this, data, 'resource_showing', false)
		sdp(this, data, 'search_match', false)
		// we may retrieve a resource's lti_form from the get_resource_record service
		sdp(this, data, 'lti_form', '')
		sdp(this, data, 'tcc_folder_id', '')	// this can be set for tcc_folder_id items; we don't save it

		// properties that were used at one time but are no longer used, and should be removed to save space:
		// sdp(this, data, 'case_identifiers', [])	// replaced with "standards"
		// sdp(this, data, 'shareable', true)	// is this resource shareable? (not currently used for anything)
		// sdp(this, data, 'district_sanctioned', false)	// replaced with agency_sanctioned

	}

	copy_for_save() {
		let o = $.extend(true, {}, this)
		delete o.editing
		delete o.quick_look_showing
		delete o.resource_showing
		delete o.created_at
		delete o.lti_form
		delete o.full_resource_data_loaded
		delete o.search_match
		delete o.tcc_folder_id

		// set shareable and professional_development and todo to 1 or 0
		o.shareable = (this.shareable) ? 1 : 0
		o.todo = (this.todo) ? 1 : 0
		o.professional_development = (this.professional_development) ? 1 : 0

		// get savable copies for standards
		o.standards = []
		for (let standard of this.standards) {
			o.standards.push(standard.copy_for_save())
		}
		return o
	}

	// return the fontawesome icon class that should go with this resource type. this should be paired with class `fas`
	icon() {
		if (this.type == 'website' || this.type == 'document' || this.type == 'upload') {
			// for google drive links, return generic document icon
			if (this.url.search(/(drive|docs)\.google/) > -1) return 'fa-file'
			let ext = this.file_extension()
			if (ext == 'pdf') return 'fa-file-pdf'
			if (this.is_image()) return 'fa-file-image'
			if (ext.indexOf('doc') == 0) return 'fa-file-word'
			if (ext.indexOf('ppt') == 0) return 'fa-file-powerpoint'
			return this.type == 'website' ? 'fa-link' : 'fa-file'
		}
		if (this.type == 'assessment') return 'fa-clipboard-list'	// list-alt / list-ol
		if (this.type == 'video' || this.type == 'interactive') return 'fa-video'
		if (this.type == 'sparkl') return 'fa-star'
		if (this.type == 'sparkl_bank') return 'fa-building-columns'
		if (this.type == 'html') return 'fa-file-alt'
		if (this.type == 'google') return 'fa-chalkboard'
		if (this.type == 'folder') return 'fa-folder'
		return 'fa-file'
	}

	type_label(capitalize) {
		// if capitalize is not specified, *DO* capitalize
		capitalize = (capitalize !== false)
		
		if (this.type == 'upload') return capitalize ? 'Uploaded File' : 'uploaded file'
		if (this.type == 'html') return capitalize ? 'HTML Resource' : 'HTML resource'
		if (this.type == 'website') return capitalize ? 'Website' : 'website'
		if (this.type == 'document') return capitalize ? 'Document' : 'document'
		if (this.type == 'video') return capitalize ? 'Video' : 'video'
		if (this.type == 'interactive') return capitalize ? 'Interactive' : 'interactive'
		if (this.type == 'safari') return capitalize ? 'Safari Resource' : 'Safari resource'
		if (this.type == 'lti') return capitalize ? 'LTI Resource' : 'LTI resource'
		if (this.type == 'resource_collection') return capitalize ? 'Resource Collection' : 'resource collection'
		if (this.type == 'collection_item') return capitalize ? 'Collection Item' : 'collection item'
		if (this.type == 'assessment') return capitalize ? 'Assessment' : 'assessment'
		if (this.type == 'sparkl') return `${vapp.site_config.sparkl_app_name} ${capitalize ? 'Activity' : 'activity'}`
		if (this.type == 'sparkl_bank') return `${vapp.site_config.sparkl_app_name} ${capitalize ? 'Item Bank' : 'Item Bank'}`
		if (this.type == 'google') return `Google Assignment`
		return ('unknown type')
	}

	// /course/41.0140/0
	full_url() {
		if (this.type == 'upload' || this.type == 'html') {
			// if the uploaded file or html has been sent to edelex, it will be a fully-qualified url
			if (this.url.includes('https:')) return this.url

			// otherwise refer to user-files
			return `${location.protocol}//${location.host}/user-files/${this.url}`

		} else if (this.type == 'sparkl_bank') {
			// we want sparkl_banks to always open via cureum, so we know what to do with things the user creates from them
			return `${window.location.origin}/itembank/${this.url}`

		} else if (this.type == 'sparkl') {
			// qualified sparkl url
			return `${vapp.$store.state.site_config.sparkl_origin}/${this.url}`

		} else if (this.type != 'lti' && !empty(this.url)) {
			// for other types EXCEPT lti, if we have a url, use it
			return this.url

		} else {
			// for lti or if url field is empty, use /reslink/resource_id url form
			return `${window.location.origin}/reslink/${this.resource_id}`
		}
	}

	has_openable_url() {
		// if we don't have a url obviously we can't open
		if (empty(resource.url)) return false

		// lti, assessment resources, and sparkl activities don't open directly as links
		if (resource.type == 'lti' || resource.type == 'assessment' || resource.type == 'sparkl') return false

		// if we get to here, assume the url is openable
		return true
	}

	standalone_link() {
		return this.full_url()
	}

	// return true iff this resource is visible by the given user role; if role not specified, use state role
	// note that callers of this fn should check themselves for whether or not the user is an editor for the collection, as collection editors can always see everything
	is_visible_by_user(role) {
		// if show_all_items_when_not_signed_in is 'yes', everyone gets to see everything
		if (vapp.site_config.show_all_items_when_not_signed_in == 'yes') return true

		// for lti/collection_item items, which we assume (at least for now) are publisher-supplied
		if (this.type == 'collection_item' || this.type == 'lti') {
			// don't show if not signed in
			if (!vapp.$store.getters.signed_in) return false
		}

		if (empty(role)) role = vapp.$store.getters.role

		// student-facing (non-teacher_facing) items are available to everyone
		if (!this.teacher_facing) return true

		// parents and students can't see anything else
		if (role == 'student' || role == 'parent') return false

		// the following restriction was moved to CollectionResourceFolder/CollectionUnit, because we need editors to be able to see these items
		// // items marked as leader_resource can only be viewed by admins and principals/assistant principals (not "regular" teachers)
		// if (this.lp_category == 'leader_resource') {
		// 	return (role == 'admin' || vapp.$store.getters.user_is_principal_or_ap)
		// }

		// if we get to here the user can see the resource
		return true
	}

	file_extension() {
		let match = this.url.match(/\.([a-zA-Z0-9]+)(?:[\?#]|$)/)
		if (match) return match[1].toLowerCase()
		else return ''
	}

	is_image() {
		if (this.type == 'website' || this.type == 'document' || this.type == 'upload') {
			return ['jpg', 'jpeg', 'gif', 'png'].includes(this.file_extension())
		}
		return false
	}

	is_pdf() {
		if (this.type == 'website' || this.type == 'document' || this.type == 'upload') {
			return this.file_extension() == 'pdf'
		}
	}

	is_youtube() {
		return (this.type == 'video' && this.url.search(/(youtube|youtu\.be)/) > -1)
	}

	is_iframeable() {
		// assume iframeing is OK for html and some uploads
		if (this.type == 'html') return true

		// Note: sharepoint docs don't open in an iframe; google docs probably wouldn't either
		// if (this.type == 'document') return true

		// youtube videos might work? but not always unfortunately
		// if (this.item.url.search(/youtu.*?be/i) > -1) return true

		// uploaded pdfs and images should work
		if (this.type == 'upload') {
			if (this.is_pdf() || this.is_image()) return true
		}

		// for other things be safe and don't allow it
		return false
	}

	get_sparkl_activity_html(link_description, flag) {
		if (empty(link_description)) link_description = this.description
		let raw_link = this.full_url()
		
		// adjust content based on the resource type
		let content
		if (this.type == 'video') {
			content = `<p>${link_description}: <a class="k-host-link-nobr" title="${link_description}" data-resource-link-id="${U.new_uuid()}" onclick="vapp.open_resource_link(\'${this.resource_id}\',this,'${raw_link}')"><i class="fas ${this.icon()} mr-2"></i>LINK</a></p><p><iframe src="${U.get_iframeable_video_url(this.url)}" class="k-exercise-video-iframe"></iframe></p>`

		} else if (this.is_image()) {
			content = `<p>${link_description}</p><p><img src="${this.full_url()}" class="fr-fic fr-dib" style="width:100%"></iframe></p>`

		} else if (this.is_iframeable()) {
			content = `<p>${link_description}: <a class="k-host-link-nobr" title="${link_description}" data-resource-link-id="${U.new_uuid()}" onclick="vapp.open_resource_link(\'${this.resource_id}\',this,'${raw_link}')"><i class="fas ${this.icon()} mr-2"></i>LINK</a></p><p><iframe src="${this.full_url()}" class="k-exercise-video-iframe"></iframe></p>`

		} else {
			content = `<p>${link_description}: <a class="k-host-link-nobr" title="${link_description}" data-resource-link-id="${U.new_uuid()}" onclick="vapp.open_resource_link(\'${this.resource_id}\',this,'${raw_link}')"><i class="fas ${this.icon()} mr-2"></i>LINK</a></p><p></p>`
		}
		
		// if optional flag is 'exercise_part', send the data to paste this content into an existing Sparkl activity
		if (flag == 'exercise_part') {
			content = content.replace(/"/g, '\\"')
			return `SPARKL-EXERCISE-PART::{"new_part_body":"${content}","new_queries":[{"uuid":"${U.new_uuid()}","part_index":0,"type":"bc"}],"new_part_data":{"uuid":"${U.new_uuid()}","part_type":"basic_content"}}`
		} else {
			return content
		}
	}

	// return true IFF new_standards match (by identifier) the resource's current standards
	standards_match(new_standards) {
		// if both lengths are 0, we know they match; if the lengths differ, we know they don't match
		if (this.standards.length == 0 && new_standards.length == 0) return true
		if (this.standards.length != new_standards.length) return false

		// if we get to here the lengths are non-zero and match, so see if the same standards are in both arrays in the same order
		// (order might matter)
		for (let i = 0; i < this.standards.length; ++i) {
			// if any identifiers are different, no match
			if (this.standards[i].identifier != new_standards[i].identifier) return false
		}
		// if we get to here, match
		return true
	}

	// return the html to insert into a froala-editable area (e.g. a lesson component) with a link to a resource
	froala_resource_link_html() {
		let title = this.description.replace(/"/g, '\\"')
		// note nbsp's on either side, to make sure we can edit around the resource link
		return `&nbsp;<link class="k-lesson-component-resource-link fas ${this.icon()}" title="${title}" data-resource-link-id="${U.new_uuid()}" onclick="vapp.open_resource_link(\'${this.resource_id}\',this)">&nbsp;`
	}
	
	// try to guess the best resource type from the given url
	static guess_resource_type_from_url(url) {
		if (url.search(/(drive|docs)\.google/) > -1) return 'document'
		if (url.search(/(youtube|youtu\.be|vimeo)/) > -1) return 'video'
		if (url.search(/(sparkl-ed|velocity)/) > -1) return 'sparkl'

		// if we get to here, base on the extension if we have one
		let ext = url.replace(/.*?\.(\w+)$/, '$1')
		if (ext != url) {
			ext = ext.toLowerCase()
			if (['pdf', 'csv', 'tsv'].includes(ext)) return 'document'
			if (['jpg', 'jpeg', 'gif', 'pdf'].includes(ext)) return 'document'
			if (ext.indexOf('doc') == 0) return 'document'
			if (ext.indexOf('ppt') == 0) return 'document'
			if (ext.indexOf('cls') == 0) return 'document'
	
		}
		// default: 'website'
		return 'website'
	}
}
window.Resource = Resource

// standard sorting algorithm for resources: arr.sort(U.resources_sort)
U.resources_sort = function(a,b) {
	// teacher-facing on top
	if (a.teacher_facing && !b.teacher_facing) return -1
	if (b.teacher_facing && !a.teacher_facing) return 1

	// agency_sanctioned above non-agency_sanctioned
	if (a.agency_sanctioned && !b.agency_sanctioned) return -1
	if (b.agency_sanctioned && !a.agency_sanctioned) return 1

	// unit planning guides on top
	let a_planning = (a.description.search(/sample.unit/i) > -1 || a.description.search(/document.set/i) > -1) ? 1 : 0
	let b_planning = (b.description.search(/sample.unit/i) > -1 || b.description.search(/document.set/i) > -1) ? 1 : 0
	let dif = b_planning - a_planning
	if (dif != 0) return dif

	// for student resources, order by target_students: 'all', then 'ese', then 'adv'
	if (!a.teacher_facing && a.target_students != b.target_students) {
		if (a.target_students == 'all' && b.target_students != 'all') return -1
		if (b.target_students == 'all' && a.target_students != 'all') return 1
		// if we get here, neither a nor b is 'all', and they're different, so one must be ese and one must be adv

		if (a.target_students == 'adv') return -1
		else return 1
	}

	// if we get to here, order alphabetically by description
	if (a.description < b.description) return -1
	if (b.description < a.description) return 1
	return 0
}
