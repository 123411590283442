<!-- Part of the SPARKL educational activity system, Copyright 2025 by Common Good Learning Tools -->
<template><v-dialog v-model="dialog_open" :max-width="dialog_max_width" persistent scrollable>
	<v-card class="k-resource-editor-outer">

		<v-card-title style="border-bottom:1px solid #999" class="pa-2">
			<b class="ml-2" style="font-weight:900" v-html="header_text"></b>
			<v-spacer/>
			<v-btn small class="mr-2" color="secondary" @click="$emit('edit_resource_cancel')" tabindex="6"><v-icon small class="mr-2">fas fa-times</v-icon>Cancel</v-btn>
			<v-btn v-if="resource_type!='none'" small color="primary" @click="save_resource" tabindex="5"><v-icon small class="mr-2">fas fa-check</v-icon>Save Resource</v-btn>
		</v-card-title>

		<v-card-text class="pt-3 pb-1" style="background-color:#eee">
			<div class="mt-4">
				<div class="d-flex align-center mb-2">
					<b v-if="is_new_resource&&edited_resource.type=='none'" class="grey--text text--darken-3 mr-2">Choose a media type for your new resource:</b>
					<v-select v-model="edited_resource.type" :items="resource_types" label="Media Type" tabindex="1" outlined hide-details dense background-color="#fff" :style="(edited_resource.type=='none'||edited_resource.type=='html')?'max-width:320px':'max-width:180px'" @change="resource_type_selected"></v-select>
					<v-text-field v-show="resource_type!='none'&&resource_type!='html'" hide-details outlined clearable :label="url_field_label" v-model="edited_resource.url" background-color="#fff" dense tabindex="2" class="ml-2"></v-text-field>
					<v-btn v-show="resource_type=='upload'" @click="upload_file" class="ml-2" fab small color="light-blue" dark><v-icon>fas fa-file-upload</v-icon></v-btn>
				</div>

				<v-textarea v-show="resource_type!='none'" class="my-4" hide-details outlined label="Resource title/description" v-model="edited_resource.description" background-color="#fff" auto-grow dense rows="1" tabindex="3"></v-textarea>

				<div v-if="resource_type!='none'" class="mb-3">
					<div class="mb-1"><b>Long Description (optional):</b></div>
					<froala-wrapper v-model="edited_resource.long_description" />
				</div>

				<div v-if="resource_type=='assessment'" class="d-flex mb-3">
					<v-text-field hide-details outlined clearable label="Assessment Blueprint URL (optional)" class="mr-1" v-model="blueprint_url" background-color="#fff" dense></v-text-field>
					<v-text-field hide-details outlined clearable label="Teacher Test Booklet URL (optional)" class="ml-1" v-model="paper_url" background-color="#fff" dense></v-text-field>
				</div>

				<div class="mb-3" v-if="resource_type=='html'">
					<div class="mb-1"><b>Edit the resource HTML here:</b> (to edit HTML directly, click the <v-icon color="#000" small>fas fa-code</v-icon> button</b></div>
					<froala-wrapper ref="froala_wrapper" :config="html_resource_editor_config" v-model="resource_html" />
				</div>

				<div class="my-4 d-flex justify-center align-center flex-wrap">
					<div v-if="show_teacher_facing_control"><v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="edited_resource.teacher_facing" mandatory>
						<v-btn small light :value="false" style="letter-spacing:0" @click.stop="set_teacher_facing_default(false)">Student-Facing</v-btn>
						<v-btn small light :value="true" style="letter-spacing:0" @click.stop="set_teacher_facing_default(true)">Teacher-Facing</v-btn>
					</v-btn-toggle></div>

					<v-btn small color="#666" dark class="ml-4 k-tight-btn" @click="additional_metadata_showing=!additional_metadata_showing">Additional Tagging Options <v-icon small class="ml-2">fas fa-caret-{{additional_metadata_showing?'up':'down'}}</v-icon></v-btn>
				</div>

				<!-- below are shown if the user clicks to see additional options -->
				<div v-show="additional_metadata_showing">

					<!-- STANDARDS -->
					<div class="mb-4">
						<div class="d-flex align-center">
							<div><b>Standards:</b></div>
							<v-btn :text="new_standard_is_secondary" x-small color="primary" dark class="ml-2 k-nocaps-btn k-tight-btn" @click="align_to_standard('primary')"><v-icon small class="mr-1">fas fa-plus</v-icon> Add PRIMARY Alignment</v-btn>
							<v-btn :text="!new_standard_is_secondary" x-small color="#666" dark class="ml-2 k-nocaps-btn k-tight-btn" @click="align_to_standard('secondary')"><v-icon small class="mr-1">fas fa-plus</v-icon> Add SECONDARY Alignment</v-btn>
							<v-spacer/>
						</div>
						<draggable v-bind="drag_options" v-model="edited_resource.standards" @end="drag_complete" class="d-flex flex-wrap align-center justify-center my-1">
							<CASEItemBtn v-for="(s) in edited_resource.standards" :key="s.identifier" :item="s" @click="align_to_standard(s)" @delete="remove_standard(s)" :btn_color="s.btn_color(edited_resource.standards)" small show_move_icon show_delete_icon :outer_class="'ma-1'" />
						</draggable>
					</div>

					<!-- Mappings to subjects/grades/courses -- used for PD resources -->
					<div class="d-flex mb-3">
						<v-autocomplete chips deletable-chips multiple small-chips outlined background-color="#fff" dense hide-details class="mb-0 pb-0 mr-1" label="Subject(s)" placeholder="(type to search tags)" :items="subject_tag_options" v-model="subject_mappings"></v-autocomplete>
						<v-autocomplete chips deletable-chips multiple small-chips outlined background-color="#fff" dense hide-details class="mb-0 pb-0 mx-1" label="Grade(s)" placeholder="(type to search tags)" :items="grade_tag_options" v-model="grade_mappings"></v-autocomplete>
						<v-autocomplete chips deletable-chips multiple small-chips outlined background-color="#fff" dense hide-details class="mb-0 pb-0 ml-1" label="Course(s)" placeholder="(type to search tags)" :items="course_tag_options" v-model="course_mappings"></v-autocomplete>
					</div>

					<!-- Settings implemented for for HenryConnects -->
					<div class="d-flex justify-center align-center flex-wrap" v-if="show_teacher_facing_control">
						<div v-if="!edited_resource.teacher_facing&&site_config.show_target_students_menu_in_resource_editor=='yes'" class="mb-3">
							Target Students:
							<v-menu bottom left>
								<template v-slot:activator="{on}"><span v-on="on" class="ml-1">
									<v-btn small v-if="edited_resource.target_students=='all'" color="amber lighten-2">All Students</v-btn>
									<v-btn small v-if="edited_resource.target_students=='ese'" dark color="teal">ESE Students</v-btn>
									<v-btn small v-if="edited_resource.target_students=='adv'" dark color="purple">Advanced Learners</v-btn>
								</span></template>
								<v-list dense>
									<v-list-item @click="edited_resource.target_students='all'"><v-list-item-title>All Students</v-list-item-title></v-list-item>
									<v-list-item @click="edited_resource.target_students='ese'"><v-list-item-title>ESE Students</v-list-item-title></v-list-item>
									<v-list-item @click="edited_resource.target_students='adv'"><v-list-item-title>Advanced Learners</v-list-item-title></v-list-item>
								</v-list>
							</v-menu>
						</div>

						<div v-if="site_config.show_special_resource_category_menu_in_resource_editor=='yes'" class="mb-3">
							<v-menu bottom left>
								<template v-slot:activator="{on}"><span v-on="on" class="ml-3">
									<v-btn small class="k-tight-btn k-nocaps-btn" v-if="edited_resource.lp_category==''">Special Resource Category…</v-btn>
									<v-btn small class="k-tight-btn k-nocaps-btn" v-if="edited_resource.lp_category=='unit_planning'" dark color="green">Unit Planning Resource</v-btn>
									<!-- <v-btn small class="k-tight-btn k-nocaps-btn" v-if="edited_resource.lp_category=='course_guidance'" dark color="cyan">Resources for Course Guidance</v-btn> -->
									<v-btn small class="k-tight-btn k-nocaps-btn" v-if="edited_resource.lp_category=='stem_resource'" dark color="lime">STEM Resource</v-btn>
									<v-btn small class="k-tight-btn k-nocaps-btn" v-if="edited_resource.lp_category=='leader_resource'" dark color="brown">Leader Resource</v-btn>
									<!-- <v-btn small class="k-tight-btn k-nocaps-btn" v-if="edited_resource.lp_category=='course_ebook'" dark color="indigo">Course Ebook</v-btn> -->
								</span></template>
								<v-list dense>
									<v-list-item @click="edited_resource.lp_category=''"><v-list-item-title>-- NONE --</v-list-item-title></v-list-item>
									<v-list-item @click="edited_resource.lp_category='unit_planning'"><v-list-item-title>Unit Planning Resource</v-list-item-title></v-list-item>
									<v-list-item @click="edited_resource.lp_category='leader_resource'"><v-list-item-title>Leader Resource</v-list-item-title></v-list-item>
									<!-- <v-list-item @click="edited_resource.lp_category='course_guidance'"><v-list-item-title>Resources for Course Guidance (shown in all units)</v-list-item-title></v-list-item> -->
									<v-list-item @click="edited_resource.lp_category='stem_resource'"><v-list-item-title>STEM Resource</v-list-item-title></v-list-item>
									<!-- <v-list-item @click="edited_resource.lp_category='course_ebook'"><v-list-item-title>Course Ebooks (shown in all units)</v-list-item-title></v-list-item> -->
								</v-list>
							</v-menu>
						</div>
						<!-- <div v-if="unit&&edited_resource.teacher_facing">
							<div class="mx-4"><v-checkbox class="mt-0 pt-0" :label="'Unit planning resource'" v-model="unit_planning" hide-details></v-checkbox></div>
						</div> -->
					</div>

					<!-- settings implemented for HenryConnects -->
					<div v-if="show_block_control||show_family_control||show_todo_control" class="d-flex mb-3"><v-spacer/>
						<div v-if="show_block_control" class="k-inline-checkbox-holder mx-2 mt-3">
							<v-checkbox class="ma-0 pa-0" :label="'Show in both block and traditional formats'" v-model="both_block_and_traditional" hide-details></v-checkbox>
							<div v-show="!both_block_and_traditional"><div class="d-flex align-center" style="font-size:14px"><v-spacer/>
								<div :style="edited_resource.block_or_traditional=='traditional'?'':'font-weight:bold'">Block</div><v-switch label="" v-model="block_or_traditional" hide-details class="mt-0 ml-3 mr-1"></v-switch><div :style="edited_resource.block_or_traditional!='traditional'?'':'font-weight:bold'">Traditional</div>
							<v-spacer/></div></div>
						</div>
						<div v-if="show_family_control" class="k-inline-checkbox-holder mx-2 mt-3"><v-checkbox class="ma-0 pa-0" :label="'Show to family members'" v-model="edited_resource.family_avail" hide-details></v-checkbox></div>
						<div v-if="show_todo_control" class="k-inline-checkbox-holder mx-2"><v-checkbox class="ma-0 pa-0" :label="'Require completion in PD collection'" v-model="edited_resource.todo" hide-details tabindex="5"></v-checkbox></div>
					<v-spacer/></div>

					<!-- metadata fields implemented for ALEX -->
					<div class="d-flex mb-3">
						<v-text-field hide-details outlined clearable label="Content Source" class="mr-2" v-model="content_source" background-color="#fff" dense></v-text-field>
						<v-select v-model="content_type" :items="content_type_options" label="Content Type" outlined hide-details dense background-color="#fff" style="max-width:210px" @change="content_type_changed"></v-select>
					</div>
					<div class="d-flex mb-3">
						<v-text-field hide-details outlined clearable label="License URI" class="mr-1" v-model="license_uri" background-color="#fff" dense></v-text-field>
						<v-text-field hide-details outlined clearable label="License Description" class="ml-1" v-model="license_text" background-color="#fff" dense></v-text-field>
					</div>

					<!-- restricted access -->
					<div v-if="show_restricted_resources_control" class="d-flex mb-3"><v-spacer/>
						<div class="k-inline-checkbox-holder mx-2"><v-checkbox class="ma-0 pa-0" v-model="edited_resource.restricted" hide-details><template v-slot:label><div style="font-size:14px">Restrict resource to <b>authorized teachers and staff only</b></div></template></v-checkbox></div>
					<v-spacer/></div>

				</div> <!-- end of "additional tagging options" -->
			</div>
		</v-card-text>
	</v-card>
</v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import CASEItemBtn from '../standards/CASEItemBtn'

export default {
	components: { CASEItemBtn, draggable },
	props: {
		lesson: { type: Object, required: false, default() { return null } },
		unit: { type: Object, required: false, default() { return null } },
		course: { type: Object, required: false, default() { return null } },

		original_resource: { required: true },	// if this is type string, we're creating a new resource
	},
	data() { return {
		dialog_open: true,
		edited_resource: null,
		subject_mappings: [],
		grade_mappings: [],
		course_mappings: [],
		resource_html: null,

		// the following 2 are used for assessments
		blueprint_url: '',
		paper_url: '',

		uploaded_file: null,
		uploaded_file_name: '',

		drag_options: {
			animation: 200,
			handle: ".k-move-handle",
		},

		new_standard_is_secondary: false,
		content_type_options: [],
	}},
	computed: {
		...mapState(['all_courses', 'site_config']),
		additional_metadata_showing: {
			get() { return this.$store.state.lst.resource_editor_additional_metadata_showing },
			set(val) { this.$store.commit('lst_set', ['resource_editor_additional_metadata_showing', val]) }
		},
		resource_editor_case_framework_identifier: {
			get() { return this.$store.state.lst.resource_editor_case_framework_identifier },
			set(val) { this.$store.commit('lst_set', ['resource_editor_case_framework_identifier', val]) }
		},
		html_resource_editor_config() {
			return U.get_froala_config({
				placeholderText: '', 
				heightMin: 200,
				// enter: FroalaEditor.ENTER_BR,	// uncomment this to test the ENTER_BR setting for froala
				zIndex: 1000,
			})
		},
		resource_types() {
			let arr = []
			if (this.site_config.file_storage_system == 'equella') {
				arr.push({ value: 'equella', text: 'OpenEQUELLA Resource' })
			}
			arr.push({ value: 'website', text: 'Website' })
			arr.push({ value: 'document', text: 'Web document (e.g. Google Drive, OneDrive, etc.)' })
			arr.push({ value: 'video', text: 'Video' })
			// arr.push({ value: 'interactive', text: 'Interactive/Simulation' })
			arr.push({ value: 'upload', text: 'Uploaded File' })
			arr.push({ value: 'html', text: `HTML Resource (entered in ${this.site_config.app_name})` })
			if (this.site_config.include_assessment_resources == 'yes') {
				arr.push({ value: 'assessment', text: 'Illuminate Assessment' })
			}
			return arr
		},
		dialog_max_width() {
			return 820
			if (!this.edited_resource) return 640
			if (this.edited_resource.type == 'html') return 820
			if (this.additional_metadata_showing) return 820
			return 640
		},
		is_new_resource() { 
			// new resources will be marked with an `is_new_resource` flag. but this flag won't be propagated in edited_resource, because it's not part of the Resource class constructor
			return this.original_resource.is_new_resource 
		},
		resource_type() { return this.edited_resource.type },
		header_text() { return this.is_new_resource ? 'New Resource' : 'Edit Resource' },
		subject_tag_options() {
			let arr = [{value: 'subject-all', text: 'All subjects'}]
			for (let s in this.$store.state.subjects) {
				arr.push({value: ('subject-' + s).toLowerCase(), text: s})
			}
			return arr
		},
		grade_tag_options() {
			let arr = [
				{value: 'grade-all', text: 'All grades'},
				{value: 'grade-elem', text: 'Grades K-5'},
				{value: 'grade-mid', text: 'Grades 6-8'},
				{value: 'grade-high', text: 'Grades 9-12'},
			]
			for (let g of this.$store.state.grades) {
				arr.push({value: ('grade-' + g).toLowerCase(), text: 'Grade ' + g})
			}
			return arr
		},
		course_tag_options() {
			let arr = []
			for (let lp of this.all_courses) {
				if (lp.collection_type !== 'pd') arr.push({value: 'course-' + lp.course_code, text: U.html_to_text(lp.title)})
			}
			arr.sort((a,b)=>{
				if (a.text < b.text) return -1
				if (a.text > b.text) return 1
				return 0
			})

			return arr
		},
		url_field_label() {
			if (this.resource_type == 'sparkl') return 'Sparkl Activity ID'
			if (this.resource_type == 'upload') return 'Resource File Identifier'
			if (this.resource_type == 'assessment') return 'Illuminate Assessment ID'
			return 'Resource URL'
		},

		// checkbox for "Unit planning resource" -- only used when adding/editing resources aligned to units
		unit_planning: {
			get() { return this.edited_resource.lp_category == 'unit_planning' },
			set(val) {
				if (val) this.edited_resource.lp_category = 'unit_planning'
				else this.edited_resource.lp_category = ''
			}
		},
		show_teacher_facing_control() {
			if (this.course && this.course.collection_type === 'pd') return false
			return true
		},
		both_block_and_traditional: {
			get() { return this.edited_resource.block_or_traditional == 'both' },
			set(val) { this.edited_resource.block_or_traditional = val ? 'both' : 'traditional' }
		},
		block_or_traditional: {
			get() { return this.edited_resource.block_or_traditional == 'traditional' },
			set(val) { this.edited_resource.block_or_traditional = val ? 'traditional' : 'block' }
		},
		show_block_control() {
			if (!this.additional_metadata_showing) return false
			// show the block/traditional flag if site_config says to do so and this is a course collection
			if (this.site_config.include_block_or_traditional_setting_for_resources == 'no') return false
			if (!this.course) return false
			if (this.course.collection_type == 'course') return true
			return false
		},
		show_family_control() {
			if (!this.additional_metadata_showing) return false
			// show the block/traditional flag if site_config says to do so and this is a course or repo collection
			if (this.site_config.include_family_setting_for_resources == 'no') return false
			if (!this.course) return false
			if (this.course.collection_type == 'course' || this.course.collection_type == 'repo') return true
			return false
		},
		show_todo_control() {
			if (!this.additional_metadata_showing) return false
			// show the 'require completion' flag if this is a pd resource and show_pd is on
			if (this.site_config.show_pd == 'no') return false
			if (this.course && this.course.collection_type === 'pd') return true
			return false
		},
		show_restricted_resources_control() {
			if (!this.additional_metadata_showing) return false
			if (this.site_config.show_restricted_resources_control == 'yes') return true
			return false
		},

		// editable extensions
		license_text: {
			get() { return this.edited_resource.extensions.license_text || ''},
			set(val) { this.$set(this.edited_resource.extensions, 'license_text', val) }
		},
		license_uri: {
			get() { return this.edited_resource.extensions.license_uri || ''},
			set(val) { this.$set(this.edited_resource.extensions, 'license_uri', val) }
		},
		content_source: {
			get() { return this.edited_resource.extensions.content_source || ''},
			set(val) { this.$set(this.edited_resource.extensions, 'content_source', val) }
		},
		content_type: {
			get() { return this.edited_resource.extensions.content_type || ''},
			set(val) { this.$set(this.edited_resource.extensions, 'content_type', val) },
		},
	},
	watch: {
	},
	created() {
		vapp.resource_editor_component = this

		// console.log('ResourceEditor', this.original_resource, this.is_new_resource)
		// create edited_resource, based on original_resource (if we received one)
		let er = new Resource(this.original_resource)

		// make sure edited_resource has an extensions object
		if (empty(er.extensions)) er.extensions = {}

		// retrieve resource_html if we should already have it
		if (er.type == 'html' && !empty(er.url)) {
			let html_url = '/user-files/' + er.url
			$.ajax({
				url: html_url,
				success: (html)=>{
					this.resource_html = html
					this.$nextTick(x=>this.$refs.froala_wrapper.set_model_value())
				},
				cache: false,
			})
		}

		// split mappings into groups for editing
		for (let m of er.mappings) {
			if (m.indexOf('subject-') == 0) this.subject_mappings.push(m)
			else if (m.indexOf('grade-') == 0) this.grade_mappings.push(m)
			else if (m.indexOf('course-') == 0) this.course_mappings.push(m)
		}

		// for assessment, get blueprint_url and paper_url if there
		if (er.type == 'assessment') {
			let bp = er.supp_links.find(x=>x.type=='blueprint')
			if (!empty(bp)) this.blueprint_url = bp.url

			let p = er.supp_links.find(x=>x.type=='paper')
			if (!empty(p)) this.paper_url = p.url
		}

		// create array of content type options: start with the array from site_config...
		let arr = this.site_config.resource_content_type_options.concat([])
		// ... add an UNSPECIFIED option for leaving the field empty and an OTHER option for a custom type
		arr.unshift('–– UNSPECIFIED ––')
		arr.push('Other…')
		if (!empty(er.extensions.content_type) && !arr.includes(er.extensions.content_type)) {
			// and if a custom type was selected, add it
			arr.push(er.extensions.content_type)
		}
		this.content_type_options = arr

		this.edited_resource = er
	},
	mounted() {
	},
	methods: {
		upload_file() {
			// prompt for file to import
			this.$prompt({
				text: '<p>Select the file you’d like to upload:</p>',
				promptType: 'file',
				acceptText: 'Choose File',
				cancelText: 'Cancel'
			}).then(file => {
				// console.log(file)
				// TODO: check for appropriate file types -- file.type
				// TODO: check for file size -- file.size
				// make sure file has an extension
				if (file.name.search(/.*(\.\w+)$/) == -1) {
					this.$alert('Uploaded files must have a valid file name extension, such as “.pdf” or “.jpg”')
					return
				}

				this.uploaded_file = file
				this.uploaded_file_name = file.name.replace(/.*(\.\w+)$/, U.new_uuid() + '$1')

				// set url to this.uploaded_file_name
				this.edited_resource.url = this.uploaded_file_name

				// if description is empty, set to the actual file name
				if (empty(this.edited_resource.description)) {
					this.edited_resource.description = file.name
				}

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		set_teacher_facing_default(new_default) {
			this.$store.commit('lst_set', ['resource_editor_teacher_facing', new_default])
		},

		resource_type_selected() {
			if (this.resource_type == 'upload') {
				this.upload_file()
			}
		},

		content_type_changed() {
			if (this.content_type == 'Other…') {
				this.$prompt({
					title: 'Content Type',
					text: 'Enter the custom Content Type value',
					disableForEmptyValue: true,
					acceptText: 'Set Content Type',
				}).then(t => {
					t = $.trim(t)
					if (!empty(t)) {
						// add the newly-added type to the end of content_type_options
						this.content_type_options.push(t)
						this.content_type = t
					}
				}).catch(n=>{console.log(n)}).finally(f=>{})
			} else if (val == '–– UNSPECIFIED ––') {
				this.content_type = ''
			}
		},

		///////////////////////////////////
		// Standards
		align_to_standard(arg) {
			let start_item = null
			if (typeof(arg) == 'string') {
				// if we get a string argument, it tells us whether aligned standards are to be primary or secondary
				this.new_standard_is_secondary = (arg == 'secondary') ? true : false

			} else {
				// else the arg is the "start item" for SatchelEmbed
				start_item = arg
			}

			let data = { framework_identifier: '', item_identifier: '' }

			if (start_item?.framework_identifier) {
				data.framework_identifier = start_item.framework_identifier
			} else {
				// if we have a resource_editor_case_framework_identifier, send it
				if (this.resource_editor_case_framework_identifier) data.framework_identifier = this.resource_editor_case_framework_identifier
			}

			if (start_item?.identifier) {
				data.item_identifier = start_item.identifier
			} else {
				// we could set some kind of default here...
			}

			// add current lesson standards as selected items
			if (this.edited_resource.standards.length > 0) {
				data.selected_items = []
				for (let standard of this.edited_resource.standards) data.selected_items.push(standard.identifier)
			}

			// set hide_fn to hide the standards chooser if/when the editor is no longer visible
			let show_data = { hide_fn: ()=>{ return ($('.k-resource-editor-outer').length == 0) } }

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
						// if we already have this item aligned, remove the standard
						let i = this.edited_resource.standards.findIndex(o=>o.identifier==aligned_item.cfitem.identifier)
						if (i > -1) {
							this.edited_resource.standards.splice(i, 1)
							// re-initialize the chooser, showing the framework for the item we removed
							this.align_to_standard({framework_identifier: aligned_item.framework_identifier})

						} else {
							// Add the standard, along with the incoming framework_identifier, childOf, and sequenceNumber. use the learning progression CASE_Item structure for standards; this is a bit simpler than the full CFItem structure
							let o = new CASE_Item(aligned_item)

							// set is_secondary for the new standard
							o.is_secondary = this.new_standard_is_secondary
							console.warn('this.new_standard_is_secondary', this.new_standard_is_secondary)

							this.edited_resource.standards.push(o)
							// re-initialize the chooser, showing the framework for the item we added
							this.align_to_standard({framework_identifier: aligned_item.framework_identifier})
						}
					})
				})
			})
		},

		remove_standard(s, confirmed) {
			console.log(s)
			let i = this.edited_resource.standards.findIndex(o=>o.identifier==s.identifier)
			if (i > -1) {
				if (!this.confirmed_standard_delete && !confirmed) {
					this.$confirm({
						title: 'Remove Standard Alignment',
						text: 'Are you sure you want to remove this standard alignment from the Lesson Plan?',
						acceptText: 'Remove',
						acceptColor: 'red',
					}).then(y => {
						this.remove_standard(s, true)
						this.confirmed_standard_delete = true
					}).catch(n=>{console.log(n)}).finally(f=>{})
					return
				}

				this.edited_resource.standards.splice(i, 1)
			}
		},

		drag_complete(evt) {
			// standards will have been reordered; save whenever the user clicks save
		},

		///////////////////////////////////////////////
		save_resource(flag) {
			// combine mappings
			this.edited_resource.mappings = this.subject_mappings.concat(this.grade_mappings, this.course_mappings)

			// convert from resource_type to type list from resources.js
			if (this.resource_type == 'google_drive' || this.resource_type == 'google_assignment' || this.resource_type == 'onedrive') this.edited_resource.type = 'document'
			if (this.resource_type == 'equella') this.edited_resource.type = 'website'

			// for an assessment save supplemental links
			this.edited_resource.supp_links = []
			if (this.resource_type == 'assessment') {
				if (!empty(this.blueprint_url)) {
					this.edited_resource.supp_links.push({type: 'blueprint', url: this.blueprint_url})
				}
				if (!empty(this.paper_url)) {
					this.edited_resource.supp_links.push({type: 'paper', url: this.paper_url})
				}
			}

			let uploaded_file = null

			// if type is upload, we must have a resource guid in the url field
			if (this.edited_resource.type == 'upload') {
				if (!U.is_uuid(this.edited_resource.url.replace(/\.\w+$/, ''))) {
					this.$alert('For an Uploaded File resource, you must either choose a file to upload, or enter a previously-uploaded Resource File Identifier.')
					return
				}
				// if the user specified a file to be uploaded, include it in the resource save operation
				// (the user can alternatively specify an already-existing filename -- though this is risky and will probably be undocumented)
				if (this.edited_resource.url == this.uploaded_file_name) {
					uploaded_file = this.uploaded_file
				}

			} else if (this.edited_resource.type == 'html') {
				if (empty(this.resource_html)) {
					this.$alert('Please enter the text you’d like to save for this resource.')
					return
				}
				uploaded_file = this.resource_html
				// generate a filename for the html, if not already specified
				if (!U.is_uuid(this.edited_resource.url.replace(/\.\w+$/, ''))) {
					this.edited_resource.url = U.new_uuid() + '.html'
				}

			} else {
				if (empty(this.edited_resource.url)) {
					this.$alert('You must specify the URL for the resource.')
					return
				}
			}

			if (empty(this.edited_resource.description)) {
				this.$alert('Please enter a brief title or description for the resource.')
				return
			}

			if (this.subject_mappings.length > 0 && this.grade_mappings.length == 0) {
				this.$alert('When tagging resources to one or more subjects, please also choose one or more grade tags. (Choose “All grades” if the resource is relevant for all grades.)')
				return
			}
			if (this.grade_mappings.length > 0 && this.subject_mappings.length == 0) {
				this.$alert('When tagging resources to one or more grades, please also choose one or more subject tags. (Choose “All subjects” if the resource is relevant for all subjects.)')
				return
			}

			this.$store.dispatch('save_resource', {resource: this.edited_resource, uploaded_file: uploaded_file}).then((resource_data)=>{
				// once resource is saved, emit edit_resource_saved, sending on the resource data sent back from the service
				this.$emit('edit_resource_saved', resource_data)
			}).catch(error=>{
				console.log(error)
				this.$alert(sr('A problem occurred when attempting to save the resource. Message from server:<div class="mt-2">$1</div>', error))
			})
		},
	}
}
</script>

<style lang="scss">
.k-resource-editor-outer {
	background-color:#fbe4d4;

	.v-input--checkbox .v-label {
		color:#222;
	}
}

.k-inline-checkbox-holder {
	display: inline-block;
	background-color:#fff;
	padding:4px 6px;
	border:1px solid #9E9E9E;
	border-radius:4px;
}
</style>
