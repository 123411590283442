<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-main-collection k-standards-home-wrapper">
	<h2 class="k-page-title d-flex align-center">
		<b>{{ site_config.learning_standards_prefix }} Learning Standards</b>
		<v-spacer/>
		<!--<v-btn v-if="show_framework_tiles" small color="primary" dark class="k-nocaps-btn k-tight-btn" :href="site_config.satchel_origin" target="_blank"><v-icon small class="mr-2">fas fa-external-link-alt</v-icon>{{site_config.satchel_app_name}}<span v-if="$vuetify.breakpoint.smAndUp" class="ml-1">({{ satchel_domain }})</span></v-btn>-->

		<v-btn v-if="show_framework_tiles" color="primary" dark class="k-nocaps-btn k-tight-btn" @click="open_ext_standards()"><v-icon small class="mr-2">fas fa-external-link-alt</v-icon>{{site_config.satchel_app_name}}<span v-if="$vuetify.breakpoint.smAndUp" class="ml-1">({{ satchel_domain }})</span></v-btn>
	</h2>

	<div v-if="custom_tab_content_description" class="k-custom-tab-content-description" :style="site_config.customized_tabs.standards.description_style" v-html="custom_tab_content_description"></div>
	<div v-if="show_framework_tiles" class="k-fwcc-docs">
		<FrameworkListFramework v-for="(fr, j) in framework_records" :key="fr.lsdoc_identifier" :framework_record="fr" />
	</div>
	<div v-if="!show_framework_tiles" class="text-center mt-8 mb-4">
		<v-btn large color="primary" class="k-nocaps-btn k-tight-btn" :href="site_config.satchel_origin" target="_blank"><v-icon small class="mr-2">fas fa-external-link-alt</v-icon>{{site_config.satchel_app_name}}<span v-if="$vuetify.breakpoint.smAndUp" class="ml-1">({{ satchel_domain }})</span></v-btn>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import FrameworkListFramework from './FrameworkListFramework.vue'

export default {
	components: { FrameworkListFramework },
	props: {
	},
	data() { return {
	}},
	computed: {
		...mapState(['frameworks_loaded', 'framework_records', 'user_info', 'site_config']),
		tab_settings() { return this.site_config.customized_tabs?.standards },
		// by default we show framework tiles, but allow them to be hidden
		show_framework_tiles() { return this.tab_settings?.show_framework_tiles != 'no' },
		custom_tab_content_description() { return this.tab_settings?.description },
		satchel_domain() {
			return this.site_config.satchel_origin.match(/^(?:https?:\/\/)?([^\/]+)/i)[1]
		}
	},
	watch: {
	},
	created() {
	},
	mounted() {
		this.initialize()
	},
	methods: {
		// when this page is loaded, get the lsdoc_list
		initialize() {
			if (this.frameworks_loaded) {
				this.initialized = true
			} else {
				this.$store.dispatch('get_lsdoc_list').then(x=>this.initialized = true)
			}
		},

		// open an external standards application, this may be simple link or sso into a cglt standards server
		open_ext_standards() {
			// open the satchel_origin, with no path (e.g. 'https://case.georgiastandards.org')
			vapp.open_satchel_in_new_window('')
		},
	}
}
</script>

<style lang="scss">
.k-standards-home-wrapper {
}
.k-fwcc-docs {
	margin-top:8px;
	display:flex;
	align-items: flex-start;
	justify-content: center;
	flex-wrap:wrap;
}
</style>
