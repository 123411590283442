// custom buttons for this app

FroalaEditor.DefineIconTemplate('insert_resource_template', '<span style="display:flex; line-height: 24px; border-radius: 8px; background-color: #555; color: #fff; padding: 2px 8px; height: 28px; margin: 0 6px;"><span style="font-size: 24px; line-height: 24px; font-weight:normal; font-family:Arial; min-width: 0; margin-right: 2px; padding: 0; float: none; display: inline-block;">+</span><b style="font-family:Roboto; font-size:12px; margin-left:2px;">RESOURCE</b></span>')
FroalaEditor.DefineIcon('insert_resource', {NAME: 'insert_resource', template:'insert_resource_template'})
FroalaEditor.RegisterCommand('insert_resource', {
	title: 'Insert Resource',
	focus: true,
	undo: true,
	refreshAfterCallback: true,
	callback: function() {
		let fco = U.get_froala_component(this)
		if (fco.parent_component) fco.parent_component.froala_insert_resource_callback(fco)
	}
})

FroalaEditor.DefineIconTemplate('convert_to_resource_template', '<span style="display:flex; line-height: 24px; border-radius: 8px; background-color: #555; color: #fff; padding: 2px 8px; height: 28px; margin: 0 6px;"><b style="font-family:Roboto; font-size:12px; margin-left:2px;">Convert to Resource</b></span>')
FroalaEditor.DefineIcon('convert_to_resource', {NAME: 'convert_to_resource', template:'convert_to_resource_template'})
FroalaEditor.RegisterCommand('convert_to_resource', {
	title: 'Convert to Resource',
	focus: true,
	undo: true,
	refreshAfterCallback: true,
	callback: function() {
		let fco = U.get_froala_component(this)
		// TODO
		if (fco.parent_component) fco.parent_component.froala_insert_resource_callback(fco)
	}
})

///////////////////////////////////////////////////////////////
// whenever a FroalaWrapper is inserted, if the component wants to do anything "special" for the froala, like customizing the available buttons,
// the component should call this fn first to get the standard config, then it can overwrite anything it wishes to
U.get_froala_config = function(params) {
	// params could include, e.g.:
	// placeholderText
	// heightMin
	// heightMax
	// toolbarInline: true,

	// first we add incoming params to the standard params for Sparkl...
	if (empty(params)) params = {}
	let config = $.extend({
		// theme: 'dark',
		toolbarButtons: {
			// moreRich: {buttons: ['bold', 'italic', 'formatUL', 'formatOL', 'insertLink', 'insertImage', 'insertVideo', 'insertmathtype', 
			// 	'underline', 'strikeThrough', 'subscript', 'superscript', 'sparklfontsize', 'textColor', 'backgroundColor', 'align', 'insertTable', 'specialCharacters' ], buttonsVisible: 8},	//  'quote', 'outdent', 'indent', 'convertmath',
			// moreMisc: {buttons: ['clearFormatting', 'html', 'fullscreen'], buttonsVisible: 0, align:'right'}	// , 'fullscreen', 'paragraphFormat'
			moreRich: {buttons: ['bold', 'italic', 'formatUL', 'formatOL', 'insertmathtype', 'insertTable', 'insertLink', 'insertImage', 'insertVideo', 'insertHR', 'emoticons', 'specialCharacters', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'textColor', 'backgroundColor', 'align', 'outdent', 'indent', 'paragraphFormat'], buttonsVisible: 10},	// , 'quote', 'outdent', 'indent'
			// moreRich: {buttons: ['bold', 'italic', 'formatUL', 'formatOL', 'insertTable', 'insertLink', 'insertImage', 'insertVideo', 'insertHR', 'emoticons', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'textColor', 'backgroundColor', 'align', 'outdent', 'indent'], buttonsVisible: 9},	// , 'quote', 'outdent', 'indent'
			moreMisc: {buttons: ['undo', 'selectAll', 'clearFormatting', 'html', 'fullscreen'], buttonsVisible: 5, align:'right'},
		},
		imageEditButtons: ['imageDisplay', 'imageAlign', 'imageStyle', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageAlt', 'imageSize', 'imageReplace', 'imageRemove'],	// , 'imageCaption' doesn't seem to work consistently
	}, params)

	// ... then we combine that with everything from get_froala_config_cglt_standard
	return U.get_froala_config_cglt_standard(config)
}
