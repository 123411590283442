<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="d-flex justify-center align-stretch">
	<div class="k-main-collection">
		<div class="d-flex">
			<h2 class="k-main-collection-header k-page-title d-flex mb-4"><v-icon large color="primary" class="mr-4" style="margin-top:4px">fas fa-cubes-stacked</v-icon><b>My Content Collections</b></h2>
			<v-spacer/>
			<v-btn v-if="signed_in && !reorder_on" color="secondary" class="k-tight-btn k-nocaps-btn" @click="go_to_favorites"><v-icon small class="mr-2" >fas fa-cubes-stacked</v-icon><span>Favorite Collections</span></v-btn>
			<v-btn v-if="signed_in" color="secondary" class="ml-2 k-tight-btn k-nocaps-btn" @click="reorder_on=!reorder_on"><v-icon small class="mr-2">fas fa-edit</v-icon>{{reorder_on ? 'Stop Editing Categories' : 'Edit Collection Categories'}}</v-btn>
		</div>
		<div v-if="initialized&&custom_tab_content_description" class="k-custom-tab-content-description" :style="site_config.customized_tabs.mycollections.description_style" v-html="custom_tab_content_description"></div>

		<div v-show="initialized">
			<div v-if="!new_lp">
				<!-- we're not currently allowing for studentish roles in cureum, but this is here for when we do... -->
				<div v-if="studentish_role&&my_collection_lps.length==0&&signed_in">You have not subscribed to any content collections.</div>
				<div v-if="!studentish_role&&my_collection_lps.length==0&&signed_in">You have not subscribed to or created any content collections.</div>
				<div v-if="my_collection_lps.length==0&&!signed_in&&!backdoor_login_only">Sign in to {{site_config.app_name}} to start collecting and creating lesson plans, student activities, and other resources.</div>

				<div class="d-flex" v-if="signed_in">
					<v-spacer/>
					<div v-if="my_collection_lps.length>0" style="width:260px"><v-text-field v-model="search_string" prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle" label="Search collection titles" single-line hide-details outlined dense background-color="#fff"></v-text-field></div>
					<v-btn outlined class="k-search-initialize-btn ml-2" style="width:260px" @click="initiate_search">Search my content<div class="k-search-initialize-btn-icon-div"><v-icon small>fas fa-search</v-icon></div></v-btn>
					<v-spacer/>
				</div>

				<div class="k-main-collection-body">
					<draggable v-bind="drag_options" v-model="category_order" draggable=".k-my-collection-category-draggable" @change="category_order_changed" class="d-flex flex-wrap justify-center">
						<div v-for="(name, i) in category_order" :key="i" class="k-my-collection-category" :class="!name.split('_')[1] || !reorder_on ? '' : 'k-my-collection-category-draggable'" :style="{ padding: name === '999_' ? '12px 12px 12px 12px' : '36px 12px 12px 12px' }">
							<div class="k-my-collection-category-label">
								<v-btn v-if="name in collection_categories && name.split('_')[1] && reorder_on" x-small icon style="margin-top:-4px; font-size:18px;" class="mr-2" @click="edit_lp_category_title(collection_categories[name].lps)"><v-icon>fas fa-edit</v-icon></v-btn>
								{{ name in collection_categories ? collection_categories[name].display_cat_name : '' }}
								<v-icon style="margin-top:-4px; font-size:18px; cursor:move" class="ml-2" v-if="name in collection_categories && name.split('_')[1] && reorder_on">fas fa-up-down-left-right</v-icon>
							</div>
							<draggable v-if="name in collection_categories" v-bind="drag_options" draggable=".k-repo-draggable" class="d-flex flex-wrap justify-center" group="categories" :data-list-name="name" :list="collection_categories[name].lps" @change="repo_order_changed(name, name in collection_categories ? collection_categories[name].lps : []), $event">
								<div v-for="lp in collection_categories[name].lps" class="k-elevated k-main-collection-item k-lp-tile" :class="[lp_css(lp), lp.lp_id == 1 || !reorder_on ? '' : 'k-repo-draggable']" :style="[lp_style(lp), {'color': U.get_contrast_color(lp.color)}]" @click="!reorder_on ? go_to_lp(lp) : ''">
									<span v-html="lp_title(lp)"></span>
									<div v-if="reorder_on && lp.lp_id != 1" style="position:absolute; right:12px; bottom:8px; font-size:14px;">
										<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" x-small text color="#fff" class="k-tight-btn k-nocaps-btn" @click="edit_lp_category_title([lp])"><v-icon>fas fa-edit</v-icon>New Cat.</v-btn></template><div class="text-center">Create a new category<br>for this content collection</div></v-tooltip>
										<v-icon style="cursor:move">fas fa-up-down-left-right</v-icon>
									</div>
								</div>
							</draggable>
						</div>
					</draggable>
				</div>

			</div>

			<div v-if="!new_lp&&!small_screen&&signed_in" class="mt-4">
				<v-btn large class="k-tight-btn k-nocaps-btn mx-1" @click="enter_subscription_code"><v-icon class="mr-3 ml-6">fas fa-cubes-stacked</v-icon><span class="mr-6">SUBSCRIBE to a Content Collection</span></v-btn>
				<v-btn v-if="!studentish_role" large class="k-tight-btn k-nocaps-btn mx-1" @click="create_new_collection"><v-icon class="mr-3 ml-6">fas fa-cubes-stacked</v-icon><span class="mr-6">CREATE a New Content Collection</span></v-btn>
			</div>

			<CollectionEdit v-if="new_lp" :learning_progression="new_lp" @editor_cancel="new_lp=null" />
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CollectionEdit from '../collections/CollectionEdit'
import MiniNav from '../main/MiniNav'
import draggable from 'vuedraggable';

export default {
	components: { draggable, CollectionEdit, MiniNav },
	data() { return {
		initialized: false,
		search_string:'',
		search_terms: [],
		new_lp: null,
		category_order: [],
		drag_options: {
			animation: 200,
		},
		reorder_on: false,
		temp_category_count: -1
	}},
	computed: {
		...mapState(['site_config', 'user_info', 'all_courses_loaded', 'all_courses']),
		...mapGetters(['small_screen', 'signed_in', 'my_default_collection', 'studentish_role', 'backdoor_login_only']),
		custom_tab_content_description() { return this.site_config.customized_tabs?.mycollections?.description },
		last_collections_viewed: {
			get() { return this.$store.state.lst.last_collections_viewed },
			set(val) { this.$store.commit('lst_set', ['last_collections_viewed', val]) }
		},
		my_collection_lps() {
			let arr = this.all_courses.filter(lp => {
				// when we first create a new LP to be entered, its title will be false
				if (lp.lp_id < 0) return true
				if (lp.title == '') return false

				// for inactive lp's, only show to people explicitly authorized as viewers (this includes admins)
				if (lp.active == 'no') {
					if (!lp.user_can_view_lp()) return false
				}

				// we always include the default collection here
				if (lp.lp_id == 1) return true

				// then we also include any collections that are in the 'my' category
				if (lp.collection_type == 'my') return true
				// if (!lp.agency_sanctioned) return true

				return false
			})
			return arr
		},
		my_collection_lps_to_show() {
			let arr = this.my_collection_lps.filter(lp => {
				// if all search_terms appear in the title, return true
				let tlc = lp.title.toLowerCase()
				for (let term of this.search_terms) {
					if (tlc.indexOf(term) == -1) return false
				}

				return true
			})

			arr.sort((a,b)=>{
				if (a.sequence != b.sequence) return a.sequence - b.sequence
				if (a.title < b.title) return -1
				if (b.title < a.title) return 1

				return 0
			})

			return arr
		},
		collection_categories() {
			const lpid_to_lp = this.my_collection_lps_to_show.reduce((acc, curr) => {
				acc[curr.lp_id] = curr; // Use 'lp' as the key, entire object as the value
				return acc;
			}, {});
			// MC: the categories are stored with the key={full_category_name}, full_category_name = 5_cat name,
			// 	   where the number represents the order of it and the values is lps, and the display name
			//     we do this so we can populate the category_order by simply sorting the keys
			let cats = {}
			for (let lp_id of Object.keys(this.user_info.my_lps)) {
				// this.user_info.my_lps is 
				// { repo name: [lp_id, lp_id, ...]}
				let lp = lpid_to_lp[lp_id]
				if (lp) {
					lp.sequence = this.user_info.my_lps[lp_id].sequence
					const category_name = this.user_info.my_lps[lp_id].category
					const display_cat_name = category_name.split('_').slice(1).join('_');

					if (cats && !(category_name in cats)) {
						cats[category_name] = {}
						cats[category_name].lps = []
						cats[category_name].display_cat_name = display_cat_name
					}
					if (!display_cat_name) cats[category_name].display_cat_name = ''
					cats[category_name].lps.push(lp)
				}
			}

			// sort each category
			for (let collection_arr of Object.values(cats)) {
				collection_arr.lps.sort((a,b)=>U.natural_sort(a.title, b.title))
				collection_arr.lps.sort((a,b)=>{
					if (a.sequence != b.sequence) return a.sequence - b.sequence
					return 0
				})
			}
			return cats
		},
	},
	watch: {
		search_string() {
			if (empty(this.search_string)) {
				this.search_terms = []
			} else {
				// convert string to lower case and split on spaces
				this.search_terms = $.trim(this.search_string).toLowerCase().split(/\s+/)
			}
		},
		// each time the my_lps updates, we want to reorder the categories/update the state
		'$store.state.user_info.my_lps'() {
			this.category_order = Object.keys(this.collection_categories).sort((a,b)=> U.natural_sort(a, b))
		},
		// MC: Using menu category
		// reorder_on() {
		// 	// MC: When reorder is turned off, we want to remove the temp lp
		// 	if (!this.reorder_on) {
		// 		const result = Object.fromEntries(
		// 			Object.entries(this.user_info.my_lps).filter(([key]) => Number(key) >= 0) // Keep only non-negative keys
		// 		);
		// 		// This triggers the other watch
		// 		this.$store.commit('set', [this.user_info, 'my_lps', result])
		// 	}
		// }
	},
	created() {
		// MC: If the user doesn't have a "1" in their my_lps, then we need to create it for the user
		//     IE: when the user has never loaded this page after this update
		if (this.signed_in && !("1" in this.user_info.my_lps)) {
			let lps = {}
			for (let i = 0; i < this.my_collection_lps_to_show.length; i++) {
				const collection = this.my_collection_lps_to_show[i]
				if (collection.lp_id in lps) console.log('duplicate my content collection... should not ever happen')
				lps[collection.lp_id] = {
					sequence: i,
					category: '999_'
				}
			}
			this.$store.commit('set', [this.user_info, 'my_lps', lps])
		}
		this.category_order = Object.keys(this.collection_categories).sort((a,b)=> U.natural_sort(a, b))
	},
	mounted() {
		if (!this.all_courses_loaded) {
			this.$store.dispatch('get_all_courses', 'initial').then(()=>{
				this.$nextTick(()=>{
					this.initialize()
				})
			}).catch(()=>{
				console.log('error in get_all_courses')
				this.back_to_classes()
			})
		} else {
			this.initialize()
		}
	},
	methods: {
		initialize() {
			// console.log('MyCollectionsIndex initialized', this.$router.history.current)
			// if the user only has a default collection, go to the default collection here -- if the user isn't studentish
			// NOT FOR NOW; we could bring this back later though
			// if (!this.studentish_role) {
			// 	if (['mycollections', 'welcome'].includes(this.$router.history.current.name) && this.my_collection_lps.length == 1) {
			// 		this.$router.push({ path: this.my_default_collection.vue_route() })
			// 	}
			// }
			// If the user first initializes the page, every collection is not categorized except for the default one

			this.initialized = true

			// if state.create_my_collection is true, it means that the user opened their window right to the default collection, then clicked to create a new collection (see Collection.vue)
			if (this.$store.state.create_my_collection == true) {
				this.$store.commit('set', ['create_my_collection', false])
				// so call create_new_collection here
				this.create_new_collection()
			}
		},

		go_to_favorites() {
			window.history.replaceState(null, '', '/mycollections')
			if (!this.signed_in) this.$store.commit('lst_set', ['unsigned_index_view_flavor', 'favorites'])
			else this.$store.commit('lst_set', ['my_index_view_flavor', 'favorites'])
		},

		lp_css(lp) {
			// let s = vapp.color_from_number(lp.course_code)
			// let s = U.subject_tile_css(lp)
			let s = ''
			if (lp.title.length > 50) {
				s += ' k-lp-tile-extra-long-title'
			} else if (lp.title.length > 30) {
				s += ' k-lp-tile-long-title'
			}
			if (lp.active == 'no') {
				s += ' k-lp-tile-inactive'
			}
			return s
		},

		lp_title(lp) {
			let s = ''
			s += '<div>'
			const contrast_color = U.get_contrast_color(lp.color)

			// start with icon indicating collection type
			if (lp.collection_type == 'course') s += `<i style="color: ${contrast_color}" class="fas fa-chalkboard"></i>`
			else if (lp.collection_type == 'repo') s += `<i style="color: ${contrast_color}" class="fas fa-diagram-project"></i>`
			else if (lp.collection_type == 'pd') s += `<i style="color: ${contrast_color}" class="fas fa-user-graduate"></i>`
			else s += `<i style="color: ${contrast_color}" class="fas fa-cubes-stacked"></i>`
			// add <wbr> tags after slashes
			s += lp.title.replace(/\//g, '/<wbr>')
				if (lp.active == 'no') {
					s += '<br><div class="red mt-1 mx-auto" style="padding:2px; display:inline-block; font-weight:normal">Inactive</div>'

					// s += ' <b class="red--text">[Inactive]</b>'
				}
			s += '</div>'

			return s
		},
		lp_style(lp) {
			// MC: This is for an invisible category
			// if (lp.lp_id < 0) return ({
			// 	visibility: 'hidden'
			// })
			return U.collection_color_style(lp)
		},

		go_to_lp(lp) {
			this.$store.commit('set', ['last_lp_list', 'index'])
			this.$router.push({ path: lp.vue_route() })
		},

		initiate_search() {
			// once we know standards-aligned assets are loaded, show search interface
			vapp.initiate_search({
				caller_component: this,
				dialog_title: 'Search My Content',
				// home_collection: this.collection,
				default_source: 'my',
			})
		},

		create_new_collection() {
			// lps created from here are definitionally NOT agency_sanctioned, and use the "tree" layout
			this.new_lp = new Learning_Progression({
				subject:'', 
				agency_sanctioned: false, 
				lp_layout: 'tree',
				collection_type: 'my',
				active: 'yes',
				use_unit_numbers: false,
				units: [new LP_Unit()],
			})
		},
		enter_subscription_code() {
			vapp.enter_subscription_code()
		},
		// MC: overwrite the current user lps with the passed in lps (payload.lps)
		save_user_my_lps(payload) {
			// note that we set category and/or sequence in the LP's $store representation before calling this; if the service fails (which it shouldn't), we'll alert an error
			// console.warn(payload); return
			for (const key in payload.lps) {
				// MC: used for temporary lp
				// if (parseInt(key, 10) < 0) {
				// 	delete payload.lps[key];
				// }
				// force default sandbox to end of untitled category
				if (key == '1') payload.lps[key].sequence = 999
			}
			U.ajax('save_user_my_lps', payload, result => {
				console.log('object_copy')
				if (result.status != 'ok') console.error('cannot save')
				else this.$store.commit('set', [this.user_info, 'my_lps', object_copy(payload.lps)])
				this.category_order = Object.keys(this.collection_categories).sort((a,b)=>U.natural_sort(a, b))
			})
		},
		category_order_changed() {
			// MC: grab a copy of the user_info.my_lps
			let lps = this.user_info.my_lps
			// for each category...	
			for (let i = 0; i < this.category_order.length; ++i) {
				// get old name and construct new name, which incorporates the sequence number at the start
				let old_repo_category = this.category_order[i]
				let new_repo_category = (100+i) + '_' + old_repo_category.replace(/^\d+_/, '')	// first category will be 100, second will be 101, etc.
				if (!old_repo_category.split('_')[1]) continue
		
				// if category name changes, we have to update all lps with the old category to have the new category
				for (const [key, value] of Object.entries(this.user_info.my_lps)) {
					if (old_repo_category == value.category) {
						lps[key].category = new_repo_category
					}
				}
			}
			const payload = {user_id: this.user_info.user_id, lps: lps}
			this.save_user_my_lps(payload)
		},
		repo_order_changed(category_name, new_order, $event) {
			// MC: get the lp_ids of the new_order, they are passde in in order
			const new_order_lps = new_order.map(({lp_id}) => lp_id)

			let lps = this.user_info.my_lps
			// assign the sequence of the lp to match the lp_id
			for (let i = 0; i < new_order_lps.length; ++i) {
				const lp_id = new_order_lps[i]
				lps[lp_id].sequence = i + 1
				lps[lp_id].category = category_name
			}
			const payload = {user_id: this.user_info.user_id, lps: lps}
			this.save_user_my_lps(payload)
		},

		edit_lp_category_title(lps_to_update) {
			let title = 'New Category'
			let text = 'Enter a new category title. This collection will be put in the new category:'
			let initial_value = this.user_info.my_lps[lps_to_update[0].lp_id]?.category || ''
			if (initial_value) initial_value = initial_value.replace(/^\d+_/, '')
			
			if (initial_value) {
				title = 'Edit Category Title'
				text = 'Enter a new category title:'
			}

			this.$prompt({
				title: title,
				text: text,
				initialValue: initial_value,
				disableForEmptyValue: true,
				acceptText: 'Save',
				acceptIcon: 'fas fa-save',
			}).then(title => {
				title = $.trim(title)
				if (empty(title)) return
				if (!(lps_to_update[0].lp_id in this.user_info.my_lps)) {
					console.log('should not happen, indicates user has access to unaccessible lp')
					return
				}
				// get the current sequence for the category the lp is in
				let category_sequence = this.user_info.my_lps[lps_to_update[0].lp_id].category.split('_')[0]
				// if we are editing a lp in the 999_ (untitled category), then we want the sequence of the category to be 998 instead
				if (category_sequence == '999') category_sequence = '998'
				let new_repo_category = category_sequence + '_' + title

				// if this category title already exists, use it (with its sequence number)
				for (let category of this.category_order) {
					let rc_title = category.replace(/^\d+_/, '')
					if (title == rc_title) {
						// this repo_category matches the entered one, so use it
						new_repo_category = category
						break
					}
				}

				let lps = this.user_info.my_lps
				// for each lp to update, set it to the new repo category
				for (const lp of lps_to_update) {
					if (lp.lp_id in lps) {
						lps[lp.lp_id].category = new_repo_category
					}
				}

				const payload = {user_id: this.user_info.user_id, lps: lps}
				this.save_user_my_lps(payload)
			}).catch(n=>{}).finally(f=>{})
		},
	},
}
</script>

<style lang="scss">
.k-my-collection-category {
	position:relative;
	border-radius: 40px;
	background-color:rgba(255, 255, 255, 0.5);
	margin:0 10px 20px 10px;
	min-width:240px;
	display:inline-flex;	// make it shrink to fit whatever is inside of it
	flex-wrap:wrap;
	flex-direction: row;
	align-items:center;
	justify-content: center;

	.k-main-collection-item {
		margin:8px;
	}
}
.k-my-collection-category-label {
	position:absolute;
	width:100%;
	text-align:center;
	top:12px;
	left:0;
	font-weight:bold;
	font-size:16px;
	line-height:20px;
	white-space:nowrap;
	// padding:0 4px;	// this is redundant if white-space is nowrap
}

</style>
