<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="900" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999">
				<h3>School Teacher Roll</h3>
				<v-spacer />
				<v-text-field
					v-model="search"
					prepend-inner-icon="fa fa-search" clearable rounded clear-icon="fa fa-times-circle"
					label="Search" single-line hide-details outlined dense background-color="#fff" style="flex:0 1 340px"
				></v-text-field>
				<v-spacer/>
				<v-btn color="primary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-check</v-icon>Done</v-btn>
			</v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">

				<v-data-table light dense
					:headers="headers"
					:items="rows"
					:search="search"
					:sort-by="['last_login_date']"
					:sort-desc="[true]"
					:footer-props="footer_options"
					:items-per-page="100"
					class="k-school-admin-report-table"
				>
					<template v-slot:item="{ item }"><tr>
						<td style="white-space:nowrap"><v-hover v-slot:default="{hover}"><div><span v-html="item.user_display"></span><v-tooltip bottom><template v-slot:activator="{on}"><v-icon v-visible="hover" v-on="on" small style="margin:-3px 0 0 10px" color="primary" @click="">fas fa-user-secret</v-icon></template>Mimic this teacher</v-tooltip></div></v-hover></td>
						<!-- <td><span v-if="item.lesson_id"><a @click="show_lesson(item.lesson_id)"><span v-html="item.lesson_title"></span><v-icon color="primary" x-small @click="" style="margin:-3px 6px 0 3px">fas fa-up-right-from-square</v-icon></a> ({{item.lesson_date_display}})</span></td>
						<td><span v-if="item.lesson_report_id"><v-icon color="green darken-2" class="mr-2">fas fa-square-check</v-icon><a @click="show_lesson_report(item.lesson_report_id)"><span v-html="item.lesson_report_title"></span><v-icon color="primary" x-small @click="" style="margin:-3px 6px 0 3px">fas fa-up-right-from-square</v-icon></a> ({{item.lesson_report_date_display}})</span></td> -->
						<!-- currently only showing active 'VIEW' button for ffenn -->
						<td class="text-center"><v-btn small color="primary" :disabled="item.user_id!=1089" text @click="show_lesson_reports(item.user_id)"><v-icon small class="mr-1">fas fa-eye</v-icon> View</v-btn></td>
						<td class="text-center"><nobr style="font-size:12px">{{item.last_login_date_display}}</nobr></td>
					</tr></template>
				</v-data-table>

			</v-card-text>
		</v-card>

		<v-dialog v-if="previewed_lesson" v-model="previewed_lesson" max-width="900" persistent scrollable content-class="k-resource-collection-item-lesson-card-dialog">
			<v-card class="k-resource-collection-item-lesson-card">
				<div class="d-flex align-center pb-2" style="border-bottom:1px solid #999;">
					<v-icon class="mr-2">fas fa-rectangle-list</v-icon>
					<div class="k-lesson-title" style="font-weight:bold" v-html="previewed_lesson.lesson_title"></div>
					<!-- TODO: show lesson author -->
					<v-spacer/>
					<v-btn small color="secondary" dark @click="previewed_lesson=false"><v-icon small class="mr-2">fas fa-check</v-icon>Done</v-btn>
				</div>
				<LessonView :lesson="previewed_lesson" />
			</v-card>
		</v-dialog>
		<LessonReport v-if="previewed_lesson_report_id" :lesson_report_id="previewed_lesson_report_id"  @dialog_cancel="previewed_lesson_report_id=0" />
		<LessonReportsForUser v-if="previewed_user_id!=0" :user_id_for_reports="previewed_user_id" @dialog_cancel="previewed_user_id=0" />
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LessonView from '../lessons/LessonView'
import LessonReport from '../lessons/LessonReport'
import LessonReportsForUser from '../lessons/LessonReportsForUser.vue'

export default {
	components: { LessonView, LessonReport, LessonReportsForUser },
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		headers: [
			{ text: 'Teacher', align: 'left', sortable: true, value: 'user' },
			// { text: 'Current Lesson Plan', align: 'left', sortable: true, value: 'lesson_date' },
			// { text: 'Current Weekly Plan', align: 'left', sortable: true, value: 'lesson_report_date' },
			{ text: 'Lesson Plans', align: 'center', sortable: false, value: '' },
			{ text: 'Last Login Date', align: 'center', sortable: true, value: 'user' },
		],
		footer_options: {
			itemsPerPageOptions: [10,50,100,-1],
		},
		search: '',
		previewed_lesson: null,
		previewed_lesson_report_id: 0,
		previewed_user_id: 0,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		rows() {
			let new_row = () => {
				let o = {}
				// o. 
			}
			let arr = []

			// arr.push({
			// 	user: 'cheneeka pye cheneeka.pye@cglt.school.edu',
			// 	user_display: `Cheneeka Pye <a href="mailto:cheneeka.pye@cglt.school.edu">cheneeka.pye@cglt.school.edu</a>`,
			// 	user_id: 34939,
			// 	lesson_date: '2024-01-09',
			// 	lesson_date_display: 'Jan 9',
			// 	lesson_title: `Erosion - Day 2`,
			// 	lesson_id: 20,
			// 	lesson_report_date: '2024-01-12',
			// 	lesson_report_date_display: 'Jan 8-12',
			// 	lesson_report_title: `Weekly Plan for 1/8/2024 - 1/12/2024`,
			// 	// lesson_report_title: `Unit 3 Week 1`,
			// 	lesson_report_id: 19,
			// 	last_login_date: '2024-01-09',
			// 	last_login_date_display: '01/09/2024, 3:14 PM',
			// 	// last_login_date_display: 'Jan 14 2024, 3:14 PM',
			// })
			arr.push({
				user: 'frank fenn ffenn@cglt.school.edu',
				user_display: `Fred Fisher <a href="mailto:ffenn@cglt.school.edu">ffenn@cglt.school.edu</a>`,
				user_id: 1089,
				lesson_date: '2024-01-09',
				lesson_date_display: 'Jan 9',
				lesson_title: `Erosion - Day 2`,
				lesson_id: 20,
				lesson_report_date: '2024-01-12',
				lesson_report_date_display: 'Jan 8-12',
				lesson_report_title: `Weekly Plan for 1/8/2024 - 1/12/2024`,
				// lesson_report_title: `Unit 3 Week 1`,
				lesson_report_id: 19,
				last_login_date: '2024-01-10',
				last_login_date_display: '01/10/2024, 1:14 PM',
				// last_login_date_display: 'Jan 14 2024, 3:14 PM',
			})
			let names = ['Sofia Johnson', 'Lucas Rodriguez', 'Harper Smith', 'Elijah Brown', 'Ava Williams', 'Mateo Martinez', 'Isabella Jones', 'Liam Davis', 'Mia Wilson', 'Noah Taylor']
			let days = [7, 5, 8, 6, 5, 8, 7, 6, 8, 5]
			let hours = [3, 6, 2, 4, 1, 5, 2, 6, 1, 4]
			let minutes = [26, 48, 35, 12, 57, 40, 18, 54, 31, 50]
			for (let i = 0; i < names.length; ++i) {
				let name = names[i]
				let lc = name.toLowerCase()
				let email = lc.replace(/ /,'.') + '@cglt.school.edu'
				arr.push({
					user: `${lc} ${email}`,
					user_display: `${name} <a href="mailto:${email}">${email}</a>`,
					lesson_date: '2024-01-09',
					lesson_date_display: '',
					lesson_title: '',
					lesson_id: 0,
					lesson_report_date: '',
					lesson_report_date_display: '',
					lesson_report_title: '',
					lesson_report_id: 0,
					last_login_date: `2024-01-0${days[i]} ${hours[i]}:${minutes[i]}`,
					last_login_date_display: `01/0${days[i]}/2024, ${hours[i]}:${minutes[i]} PM`,
				})
			}

			arr.sort((a,b)=>U.natural_sort(a.last_login_date, b.last_login_date))
			return arr
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		show_lesson(lesson_id) {
			// LessonView will load the full lesson as long as we provide the lesson_id
			this.previewed_lesson = new Lesson({lesson_id: lesson_id})
		},

		show_lesson_report(lesson_report_id) {
			this.previewed_lesson_report_id = lesson_report_id
		},

		show_lesson_reports(user_id) {
			this.previewed_user_id = user_id
		},
	}
}
</script>

<style lang="scss">
.k-school-admin-report-table {
	td {
		// font-size:14px!important;
		line-height: 18px;
	}
}
</style>